export const BirthdayCakeIcon = require('./birthday-cake.png');
export const EventCalendarIcon = require('./event-calendar.png');
export const NotificationImage = require('./notification.png');
export const EventCalendarImage = require('./event-calendar-big.png');
export const FileIcon = require('./fileicon.png');
export const FileIcon2 = require('./fileicon2.png');
export const GoalsIcon = require('./goalsicon.png');
export const ImagesIcon = require('./imagesicon.png');
export const VideoIcon = require('./videoicon.png');
export const CustomVideoIcon = require('./video-icon.png');
export const AttachFile = require('./attach-file.png');
export const SentimentSatisfied = require('./happy-face.png');
export const Comment = require('./comment-icon.png');
export const Doc = require('./doc.png');
export const Pdf = require('./pdf.png');
export const Xls = require('./xls.png');
export const Download = require('./download.png');
export const ChooseImage = require('./choose-image.png');
export const Camera = require('./camera.png');
export const Hide = require('./hide.png');
export const Bookmark = require('./bookmark.png');
export const LinkIcon = require('./link.png');
export const Edit = require('./edit.png');
export const ProfilePicture = require('./profilepicture.png');
export const HamburgerWhite = require('./hamburger-white.png');
export const HamburgerPurple = require('./hamburger-purple.png');
export const BoxViewWhite = require('./box-view-white.png');
export const BoxViewPurple = require('./box-view.png');
export const PortfolioDownload = require('./portfolio-download.png');
export const VerifiedIcon = require('./verified-icon.png');
export const WhiteSquare = require('./white-square.png');
export const HomeIcon = require('./home.svg');
export const CalendarIcon = require('./calendar.svg');
export const ChatIcon = require('./chat.png');
export const ClassIcon = require('./class.svg');
export const LibraryIcon = require('./library.svg');
export const MailIcon = require('./mail.svg');
export const ReportsIcon = require('./reports.svg');
export const SocialMediaIcon = require('./socialmedia.png');
export const Logo = require('./logo.png');
export const HeyCampusAiLogo = require('./heycampusai-logo.png');
export const BoxViewListIcon = require('./box-view-list-icon.svg');
export const CalendarIcon2 = require('./calendar.png');
export const HeaderCheckIcon = require('./header-check-icon.png');
export const HeaderEnglishLang = require('./header-english-lang.png');
export const HeaderFontIcon = require('./header-font-icon.png');
export const HeaderSupportIcon = require('./header-support-icon.png');
export const TrashIcon = require('./trashicon.png');
export const NewsImage = require('./news-image.png');
export const DataPlaceholderImage = require('./no-data-image.png');
export const NoMemberImage = require('./no-member-image.png');
export const ProfilePlaceholderImage = require('./profile-placeholder.png');
export const MenuTimelineIcon = require('./menu-timeline-icon.png');
export const MenuGoalsIcon = require('./menu-goals-icon.png');
export const MenuVideosIcon = require('./menu-videos-icon.png');
export const MenuFilesIcon = require('./menu-files-icon.png');
export const MenuBadgesIcon = require('./menu-badges-default-icon.png');
export const MenuBadgesWhiteIcon = require('./menu-badges-white-icon.png');
export const MenuDiscussionIcon = require('./menu-discussion-icon.png');
export const MenuMoreIcon = require('./menu-more-default.png');
export const MenuMoreColoredIcon = require('./menu-more-colored.png');
export const MenuPlusIcon = require('./menu-plus-icon.png');
export const MenuPostIcon = require('./menu-post-icon.png');
export const MenuCloseIcon = require('./menu-close-icon.png');
export const SettingsIcon = require('./settings-icon.png');


// Dummy images
export const ShareStoryImage = require('./share-story-image.png');
export const Banner01 = require('./banner01.png');
export const SearchIcon = require('./search-icon.png');
export const FilterIcon = require('./filter-icon.png');
export const ArrowDownIcon = require('./arrow-down.png');
export const ImageIcon = require('./image-icon.png');
