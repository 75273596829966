import React from 'react';

const LibraryIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 28, height = 28, color } = props;

    return(
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22 16.364c.301 0 .545.265.545.593v5.564l1.553-1.616a.565.565 0 0 1 .822 0 .624.624 0 0 1 0 .857l-2.327 2.424a.568.568 0 0 1-.411.178l-.09-.007a.681.681 0 0 1-.092.007c-.272 0-.498-.216-.539-.5l-2.018-2.102a.624.624 0 0 1 0-.857.566.566 0 0 1 .823 0l1.188 1.238v-5.186c0-.328.245-.593.546-.593zM20.678.11c.856.122 1.504.878 1.504 1.758l-.001.312h1.5c.978 0 1.774.78 1.774 1.736V14.33c0 .32-.265.58-.592.58a.585.585 0 0 1-.59-.58V3.917a.585.585 0 0 0-.591-.578l-1.501-.001v10.191c0 .325-.259.588-.579.588a.584.584 0 0 1-.58-.588V3.261a.576.576 0 0 1-.295-.5c0-.214.119-.401.295-.501v-.391a.6.6 0 0 0-.51-.594c-2.543-.381-4.856.35-7.058 2.236v14.704c1.643-1.214 3.396-1.883 5.229-1.99.316-.03.593.228.611.552a.585.585 0 0 1-.545.622c-1.24.073-2.416.427-3.554 1.072l1.678.002a.581.581 0 1 1 0 1.163h-3.33c-.084.071-.168.144-.252.218a.571.571 0 0 1-.763.001l-.255-.219H1.745A1.747 1.747 0 0 1 0 17.891V3.927C0 2.982.779 2.2 1.735 2.182l1.901-.001v-.312c0-.88.649-1.636 1.508-1.759 2.68-.402 5.202.316 7.519 2.135a.511.511 0 0 1 .493 0l-.247.197c2.385-1.967 5-2.754 7.77-2.331zM3.636 3.345h-1.89a.593.593 0 0 0-.582.582v13.964c0 .32.26.582.581.582h8.874c-1.588-.902-3.248-1.242-5.028-1.041-.5.06-1.007-.1-1.381-.44a1.752 1.752 0 0 1-.574-1.298V3.345zm1.673-2.071a.602.602 0 0 0-.513.595v13.825c0 .163.065.312.185.42a.591.591 0 0 0 .478.149c2.448-.276 4.766.376 6.905 1.954l-.001-14.705-.095-.082C10.095 1.602 7.817.896 5.309 1.274zM27.42 28H16.945c-.322 0-.582-.244-.582-.545 0-.302.26-.546.582-.546h10.472c.322 0 .582.244.582.546 0 .3-.26.545-.582.545z"
                fill={color}
                fill-rule="evenodd"
            />
        </svg>
    )
}

export default LibraryIcon;
