import React from 'react';

const GoalsIcon:React.FC<{color:string,height?:number,width?:number}> = (props) => {
    const { width = 24, height = 24, color } = props;
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} x="0px" y="0px"
            viewBox="0 0 500 500" xmlSpace="preserve">
            <path fill={color} d="M493.2,475.4L465.7,435c0,0,0,0,0,0L352.1,268.1c-2.3-3.4-6.2-5.5-10.3-5.5c-4.1,0-8,2-10.4,5.4L302,310.3
            l-79.1-116.7V138h61.7v29.8c0,0,0,0.1,0,0.1c0,0.2,0,0.5,0,0.7c0,0.2,0,0.3,0,0.5c0,0.3,0.1,0.6,0.1,0.8c0,0.1,0,0.2,0.1,0.3
            c0.1,0.4,0.2,0.7,0.3,1.1c0,0,0,0,0,0.1c0.3,1.1,0.8,2.2,1.5,3.2c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2,0.3,0.5,0.5,0.7
            c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.2,0.2,0.3,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
            c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.2,0.5,0.3,0.8,0.5
            c0.1,0,0.1,0.1,0.2,0.1c0.3,0.2,0.6,0.3,1,0.5c0,0,0.1,0,0.1,0.1c0.3,0.1,0.7,0.3,1,0.4c0.1,0,0.1,0,0.2,0.1
            c0.3,0.1,0.6,0.2,0.9,0.2c0.1,0,0.2,0,0.3,0.1c0.3,0.1,0.5,0.1,0.8,0.1c0.2,0,0.3,0,0.5,0.1c0.2,0,0.5,0,0.7,0c0.1,0,0.3,0,0.4,0
            h109c4.7,0,9-2.6,11.1-6.8c2.1-4.2,1.8-9.2-0.9-13l-28.5-40l28.4-38.1c2.8-3.8,3.3-8.9,1.1-13.1c-2.1-4.3-6.4-6.9-11.2-6.9H350V30.5
            c0-3.3-1.3-6.5-3.7-8.9c-2.3-2.3-5.6-3.7-8.9-3.7h-45.9h-4h-64.7v-0.4c0-6.9-5.6-12.5-12.5-12.5c-6.9,0-12.5,5.6-12.5,12.5v13v95
            v68.1L91.9,349.8L6.8,475.4c-2.6,3.8-2.9,8.8-0.7,12.9c2.2,4.1,6.4,6.7,11.1,6.7h369.4h17h79.2c4.6,0,8.9-2.6,11.1-6.7
            C496.1,484.2,495.8,479.2,493.2,475.4z M210.4,219.8l47.5,70.1L242.3,304l-24.8-21.2c-4.7-4-11.6-4-16.3,0l-24,20.6l-14.6-13.1
            L210.4,219.8z M381.2,87.5l-18.9,25.4c-3.3,4.4-3.3,10.3-0.2,14.8l19.7,27.7h-55.4l20.1-21.2c0.1-0.1,0.2-0.2,0.3-0.4
            c0.1-0.2,0.3-0.3,0.4-0.5c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.3,0.3-0.5,0.5-0.8c0-0.1,0.1-0.1,0.1-0.2
            c0.2-0.3,0.3-0.6,0.5-1c0,0,0-0.1,0.1-0.1c0.1-0.3,0.3-0.7,0.4-1c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.2-0.6,0.2-0.9c0-0.1,0-0.2,0.1-0.3
            c0.1-0.3,0.1-0.5,0.1-0.8c0-0.2,0-0.3,0.1-0.5c0-0.2,0-0.5,0-0.7c0-0.1,0-0.3,0-0.4v-38L381.2,87.5z M287.6,43.1
            C287.6,43.1,287.6,43.1,287.6,43.1h37.3l0,69.9h-102V43.1L287.6,43.1z M112.7,363.9C112.7,363.9,112.7,363.9,112.7,363.9l35.6-52.5
            l20.2,18.1c4.7,4.2,11.8,4.3,16.5,0.2l24.3-20.8l25.1,21.3c4.8,4.1,11.9,4,16.5-0.2l21.2-19.2L380,469.9H40.8L112.7,363.9z
             M410.3,469.9l-93.1-137.4l24.5-35.2L445,449.2c0,0,0,0,0,0v0l14.1,20.8H410.3z"/>
        </svg>
    );
}

export default GoalsIcon;
