import React from 'react';

const CalendarIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 29, height = 28, color } = props;

    return(
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.303 0c.333 0 .603.261.603.583l-.001 1.75h3.014c1.663 0 3.014 1.309 3.014 2.917v19.833c0 1.608-1.351 2.917-3.014 2.917H3.014C1.351 28 0 26.691 0 25.083V5.25c0-1.608 1.351-2.917 3.014-2.917h3.013V.583c0-.322.27-.583.604-.583.333 0 .602.261.602.583v1.75H21.7V.583c0-.322.27-.583.603-.583zm5.424 10.499H1.205v14.584c0 .965.812 1.75 1.809 1.75h22.905c.998 0 1.809-.785 1.809-1.75L27.727 10.5zM6.027 3.5H3.014c-.997 0-1.808.785-1.808 1.75l-.001 4.083h26.522V5.25c0-.965-.81-1.75-1.808-1.75h-3.014v1.75c0 .323-.27.583-.602.583a.593.593 0 0 1-.603-.583V3.5H7.233v1.75c0 .323-.27.583-.602.583a.593.593 0 0 1-.603-.583L6.027 3.5z"
                fill={color}
                fill-rule="evenodd"
            />
        </svg>
    )
}

export default CalendarIcon;
