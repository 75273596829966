Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FriendList";
exports.labelBodyText = "FriendList Body";

exports.btnExampleTitle = "CLICK ME";

exports.getDivisionsApi = {
  contentType: { "Content-Type": "application/json" },
  endpointMethod: "bx_block_scheduling/grades/get_grade_divisions",
  apiMethod: "GET",
  baseEndpoint: 'https://institute-management.b91002.dev.us-east-1.aws.svc.builder.cafe'
}

exports.getGradesApi = {
  contentType: { "Content-Type": "application/json" },
  endpointMethod: "bx_block_scheduling/grades",
  apiMethod: "GET",
  baseEndpoint: 'https://institute-management.b91002.dev.us-east-1.aws.svc.builder.cafe'
}

exports.getTopStudentsApi = {
  header: {
    "Content-Type": 'application/json',
    "Access-Control-Allow-Origin" : "*",
    "Access-Control-Allow-Credentials": true,
    'Accept': '*/*'
  },
  endpointMethod: "top_students",
  apiMethod: "GET",
  baseEndpoint: 'https://social-media-rails.b91002.dev.us-east-1.aws.svc.builder.cafe'
}

exports.getStudentsByDivisionApi = {
  header: {
    "Content-Type": 'application/json'
  },
  endpointMethod: "classdivision",
  apiMethod: "GET",
  baseEndpoint: 'https://social-media-rails.b91002.dev.us-east-1.aws.svc.builder.cafe'
}

exports.getStudentsBySearchApi = {
  header: {
    "Content-Type": 'application/json'
  },
  endpointMethod: "search",
  apiMethod: "GET",
  baseEndpoint: 'https://social-media-rails.b91002.dev.us-east-1.aws.svc.builder.cafe'
}


exports.getUserDataApi = {
  header: {
    "Content-Type": 'application/json'
  },
  endpointMethod: "profile",
  apiMethod: "POST",
  baseEndpoint: 'https://social-media-rails.b91002.dev.us-east-1.aws.svc.builder.cafe'
}

exports.getTeachersApi = {
  header: {
    "Content-Type": 'application/json'
  },
  endpointMethod: "teacher_profile",
  apiMethod: "GET",
  baseEndpoint: 'https://social-media-rails.b91002.dev.us-east-1.aws.svc.builder.cafe'
}

// Customizable Area End