import React,{Component} from "react"
import {Doc, Pdf, Xls} from "./assets/images";
import {IconButton} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { timeSince } from './utilities/Utils';

export interface IFile extends File {
    uploadedAt: number;
}

type MyProps = {
    handleDeleteFile?:(index:number)=>void,
    file: IFile,
    index:number,
    isDeletable?: boolean
}
type MyState = {

}
class FileListItem extends Component<MyProps,MyState>{
    constructor(props:any){
        super(props)
    }

    getTime(file: IFile) {
        const date = timeSince(new Date(file.uploadedAt).toString());

        if (date === '0 seconds') {
            return 'Uploaded just now';
        }

        return `Uploaded ${date} ago`;
    }

    render(){
        const {handleDeleteFile,file,index, isDeletable = true} = this.props

        return(
            <div className="file-list-item" key={file.name}>
                <div>
                    {
                        file.name.toLowerCase().includes("pdf") &&
                        <img className="file-icon" src={Pdf} alt=""/>
                    }
                    {
                        file.name.toLowerCase().includes("docx") &&
                        <img className="file-icon" src={Doc} alt=""/>
                    }
                    {
                        file.name.toLowerCase().includes("xls") &&
                        <img className="file-icon" src={Xls} alt=""/>
                    }
                    <span className="file-name">{file.name}</span>
                </div>
                <div>
                    <span className="file-upload-time">{this.getTime(file)}</span>

                    {isDeletable && (
                        <IconButton
                            onClick={()=> {
                                if (isDeletable && typeof handleDeleteFile === 'function') {
                                    handleDeleteFile(index)
                                }
                            }}
                            disableRipple
                            className="delete-file-button"
                        >
                            <Close width={20} htmlColor="rgba(0, 0, 0, 0.1)" />
                        </IconButton>
                    )}
                </div>
            </div>
        )
    }
}

export default FileListItem
