import React from 'react';

const TimelineIcon:React.FC<{color:string,height?:number,width?:number}> = (props) => {
    const { width = 24, height = 24, color } = props;
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} x="0px" y="0px"
            viewBox="0 0 500 500" xmlSpace="preserve">
            <g>
                <path fill={color} d="M489.8,45.6c-5.1-11.9-13.5-22.1-24.1-29.2C455.1,9.2,442.3,5,428.6,5H71.4c-9.1,0-17.9,1.9-25.9,5.2
                c-11.9,5.1-22.1,13.5-29.2,24.1C9.2,44.9,5,57.7,5,71.4v357.1c0,9.1,1.9,17.9,5.2,25.9c5.1,11.9,13.5,22.1,24.1,29.2
                c10.6,7.2,23.4,11.4,37.2,11.4h357.1c9.1,0,17.9-1.9,25.9-5.2c11.9-5.1,22.1-13.5,29.2-24.1c7.2-10.6,11.4-23.4,11.4-37.2V71.4
                C495,62.3,493.1,53.5,489.8,45.6z M466.4,428.6c0,5.3-1.1,10.2-3,14.7c-2.9,6.8-7.7,12.6-13.7,16.7c-6.1,4.1-13.3,6.4-21.1,6.5
                H71.4c-5.3,0-10.2-1.1-14.7-3c-6.8-2.9-12.6-7.7-16.7-13.7c-4.1-6.1-6.4-13.3-6.5-21.1V71.4c0-5.3,1.1-10.2,3-14.7
                c2.9-6.8,7.7-12.6,13.7-16.7c6.1-4.1,13.3-6.4,21.1-6.5h357.1c5.3,0,10.2,1.1,14.7,3c6.8,2.9,12.6,7.7,16.7,13.7
                c4.1,6.1,6.4,13.3,6.5,21.1V428.6z"/>
                <path fill={color} d="M297.3,135c0-7.9-6.4-14.3-14.3-14.3H105.4c-7.9,0-14.3,6.4-14.3,14.3c0,7.9,6.4,14.3,14.3,14.3H283
                C290.9,149.3,297.3,142.9,297.3,135z"/>
                <path fill={color} d="M366.2,250c0-7.9-6.4-14.3-14.3-14.3H174.3c-7.9,0-14.3,6.4-14.3,14.3c0,7.9,6.4,14.3,14.3,14.3h177.6
                C359.8,264.3,366.2,257.9,366.2,250z"/>
                <path fill={color} d="M397.8,351.6H220.2c-7.9,0-14.3,6.4-14.3,14.3c0,7.9,6.4,14.3,14.3,14.3h177.6c7.9,0,14.3-6.4,14.3-14.3
                C412.1,358,405.7,351.6,397.8,351.6z"/>
            </g>
        </svg>
    );
}

export default TimelineIcon;
