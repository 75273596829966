import React,{Component} from "react";
import DialogWrapper from "./DialogWrapper";
import {
    GoalsIcon,
    FileIcon,
    ImagesIcon,
    VerifiedIcon,
    VideoIcon, WhiteSquare
} from "./assets/images";
import {
    BadgeFour,
    BadgeOne, BadgeThree,
    BadgeTwo,
    PortfolioImage
} from "../../blocks/landingpage/src/assets";
import {IPost} from "./SinglePost";
import "./assets/styles/portfolioDialog.css"
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { configJSON } from "../../blocks/landingpage/src/LandingPageController";

type MyProps = {
    isOpen:boolean,
    handleCloseModal:()=>void
}

type MyState = {}

class PortfolioDialog extends Component<MyProps,MyState>{
    downloadPdfDocument = () => {
        const input = document.getElementById("pdfView")!;

        html2canvas(input)
            .then((canvas) => {
                const componentWidth = input.offsetWidth
                const componentHeight = input.offsetHeight

                const orientation = componentWidth >= componentHeight ? 'l' : 'p'

                const imgData = canvas.toDataURL('image/png')
                const pdf = new jsPDF({
                    orientation,
                    unit: 'px'
                })

                pdf.internal.pageSize.width = componentWidth
                pdf.internal.pageSize.height = componentHeight

                pdf.addImage(imgData, 'JPEG', 0, 0, componentWidth, componentHeight)
                pdf.save('download.pdf')
            })
    }
    render(){
        const {isOpen,handleCloseModal} = this.props
        return(
            <DialogWrapper downloadPdfDocument={this.downloadPdfDocument} isOpen={isOpen} handleCloseModal={handleCloseModal} >
                <div data-test-id="pdfView" id="pdfView" className="portfolio-body">
                    <div className="portfolio-header">
                        <img className="portfolio-image" src={PortfolioImage} alt=""/>
                        <div>
                            <h3>Andrew Smith</h3>
                            <div className="portfolio-information">
                                <div><span>Class 12th A</span><img className="verified-icon" src={VerifiedIcon} alt=""/></div>
                                <span>+91 98765 43211  |  andrew.smith@gmail.com</span>
                            </div>
                        </div>
                    </div>
                    <h4>About Me</h4>
                    <p>
                        It is a long established fact that a reader will be distracted by the readable content of a page
                        when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
                        normal distribution of letters.
                    </p>
                    <div className="user-summary-items">
                        <div className="summary-item">
                            <img src={ImagesIcon} alt=""/>
                            <div className="summary-item-texts">
                                <span className="summary-item-total">154</span>
                                <span className="summary-item-type"> Images</span>
                            </div>
                        </div>
                        <div className="summary-item">
                            <img src={FileIcon} alt=""/>
                            <div className="summary-item-texts">
                                <span className="summary-item-total">38</span>
                                <span className="summary-item-type"> Files</span>
                            </div>
                        </div>
                        <div className="summary-item">
                            <img src={VideoIcon} alt=""/>
                            <div className="summary-item-texts">
                                <span className="summary-item-total">50</span>
                                <span className="summary-item-type"> Videos</span>
                            </div>
                        </div>
                        <div className="summary-item">
                            <img src={GoalsIcon} alt=""/>
                            <div className="summary-item-texts">
                                <span className="summary-item-total">89</span>
                                <span className="summary-item-type"> Goals</span>
                            </div>
                        </div>
                    </div>
                    <h4>My Achievements</h4>
                    <div className="achievements-wrapper">
                        <div className="achievement">
                            <span className="achievement-text">Top in Science</span>
                            <img src={BadgeOne} alt="" className="achievement-image"/>
                        </div>
                        <div className="achievement">
                            <span className="achievement-text">Master in Chess</span>
                            <img src={BadgeTwo} alt="" className="achievement-image"/>
                        </div>
                        <div className="achievement">
                            <span className="achievement-text">Won Cricket Match</span>
                            <img src={BadgeThree} alt="" className="achievement-image"/>
                        </div>
                        <div className="achievement">
                            <span className="achievement-text">Top in Science</span>
                            <img src={BadgeFour} alt="" className="achievement-image"/>
                        </div>
                    </div>
                    <h4>My Goals</h4>
                    <div className="goal-items">
                        {
                            configJSON.studentGoals.map((item:IPost)=>(
                                <div key={item.id} className="goal-wrapper">
                                    <div className="goal-item">
                                        <div className="goal-text">
                                            <img src={WhiteSquare} alt=""/>
                                            <span>{item.goalText}</span>
                                        </div>
                                        <div className={`goal-date ${!item.dueDate && "goal-completed-container"}`}>
                                            {
                                                !item.dueDate ?
                                                <span className="goal-completed">
                                                    Completed
                                                </span> :
                                                <div className="due-date">
                                                    <span>Due Date: </span><span>{item.dueDate}</span>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    {
                                        item.goalPicture &&
                                        <div className="goal-picture-container">
                                            <img src={item?.goalPicture} alt=""/>
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </DialogWrapper>
        )
    }


}

export default PortfolioDialog
