import React from "react";
import { Text, StyleSheet, View, TouchableOpacity } from "react-native";

import Loader from "../../../components/src/Loader";
import SocialMediaAccountWebController, {
  Props,
  configJSON
} from "../../social-media-account/src/SocialMediaAccountWebController";

import CustomFacebookLogInButton from "../../social-media-account/src/CustomFacebookLogInButton";
import CustomGoogleLogInButton from "../../social-media-account/src/CustomGoogleLogInButton";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import SignContainer from "../../../components/src/containers/SignContainer.web";
import "./main.css";
import SocialMediaAccountLoginLeftScreen from "./SocialMediaAccountLoginLeftScreen.web";
import SocialMediaAccountLoginRightScreen from "./SocialMediaAccountLoginRightScreen.web";
export interface IUser {
  email:string,
  password:string
}
// Customizable Area End

class SocialMediaAccountLoginScreen extends SocialMediaAccountWebController {
  static SocialMediaAccountLoginScreen: SocialMediaAccountLoginScreen;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      isRegistration: false
      // Customizable Area Start
      ,userInformation:{email:"",password:""}
      // Customizable Area End
    };
  }

  render() {
    const { loading } = this.state;
    return (
      <View style={styles.container}>
        <Loader loading={loading} />
        {/* Customizable Area Start */}

        <SignContainer>
          <Grid container id="login-container">
            <SocialMediaAccountLoginLeftScreen />
            <SocialMediaAccountLoginRightScreen
              view={this.state.view}
              loading={this.state.viewLoading}
              changeViewToForm={() => this.changeView('FORM')}
              submitEmailForm={this.submitEmailForm}
              submitOtpForm={this.submitOtpForm}
            />
          </Grid>
        </SignContainer>
        {/* Customizable Area Start */}
      </View>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5FCFF"
  }
});
// Customizable Area End

export default SocialMediaAccountLoginScreen;
