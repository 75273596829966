import React from 'react';

const StoriesIcon:React.FC<{color:string,height?:number,width?:number}> = (props) => {
    const { width = 24, height = 24, color } = props;
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} x="0px" y="0px"
            viewBox="0 0 500 500" xmlSpace="preserve">
            <g>
                <path fill={color} d="M462.9,160.1c-17.5-41.5-46.8-76.7-83.7-101.6C342.3,33.5,297.8,19,250,19c-31.9,0-62.3,6.5-89.9,18.2
                C118.6,54.7,83.3,84,58.4,120.8C33.5,157.7,19,202.2,19,250c0,31.9,6.5,62.3,18.2,89.9c17.5,41.5,46.8,76.7,83.7,101.6
                C157.7,466.5,202.2,481,250,481c31.9,0,62.3-6.5,89.9-18.2c41.5-17.5,76.7-46.8,101.6-83.7C466.5,342.3,481,297.8,481,250
                C481,218.1,474.6,187.7,462.9,160.1z M438.9,329.8c-15.6,36.8-41.6,68.1-74.3,90.2c-32.7,22.1-72.1,35-114.6,35
                c-28.3,0-55.3-5.7-79.8-16.1c-36.8-15.6-68.1-41.6-90.2-74.3C57.9,331.9,45,292.5,45,250c0-28.3,5.7-55.3,16.1-79.8
                c15.6-36.8,41.6-68.1,74.3-90.2c32.7-22.1,72.1-35,114.6-35c28.3,0,55.3,5.7,79.8,16.1c36.8,15.6,68.1,41.6,90.2,74.3
                c22.1,32.7,35,72.1,35,114.6C455,278.3,449.3,305.3,438.9,329.8z"/>
                <path fill={color} d="M369.4,237H263V130.6c0-7.2-5.8-13-13-13c-7.2,0-13,5.8-13,13V237H130.6c-7.2,0-13,5.8-13,13
                c0,7.2,5.8,13,13,13H237v106.4c0,7.2,5.8,13,13,13c7.2,0,13-5.8,13-13V263h106.4c7.2,0,13-5.8,13-13
                C382.4,242.8,376.6,237,369.4,237z"/>
            </g>
        </svg>
    );
}

export default StoriesIcon;
