import { IUser, IProfileWidgetData, TProfileType } from '../types';
import { setStorageData, getStorageData } from '../../../framework/src/Utilities';
import { safeJSONparse } from './Utils';

export const getUserInformation = (): (IUser | null) => {
    if (typeof localStorage === 'undefined') { return null; }

    const storageData = localStorage.getItem('userInformation');
    if (!storageData) { return null }

    let data = null;

    try {
        data = safeJSONparse<IUser>(storageData);
    } catch (err) {}

    return data;
}

export const setUserInformation = (data: IUser) => {
    if (typeof localStorage === 'undefined') { return null; }
    localStorage.setItem('userInformation', JSON.stringify(data));
}

export const getToken = async(): Promise<string> => {
    const token = await getStorageData('token');
    return token || '';
}

export const setToken = async(token: string) => {
    await setStorageData('token', token);
}

export const getProfileWidgetData = (): (IProfileWidgetData | null) => {
    if (typeof localStorage === 'undefined') { return null; }

    const storageData = localStorage.getItem('profileWidgetData');
    if (!storageData) { return null }

    let data = null;

    try {
        data = safeJSONparse<IProfileWidgetData>(storageData);
    } catch (err) {}

    return data;
}

export const getProfileType = (): TProfileType => {
    if (typeof localStorage === 'undefined') { return 'unset'; }

    const storageData = localStorage.getItem('profileType') as TProfileType | undefined;
    return storageData || 'unset';
}

type TListType = 'students' | 'teachers';
export const getFriendListType = (): TListType  => {
    if (typeof localStorage === 'undefined') { return 'students'; }

    const storageData = localStorage.getItem('listType') as TListType;
    return storageData || 'students';
}