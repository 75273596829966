import React from 'react';

const HeyCampusAiLogo: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 140, height = 55, color } = props;
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 909 362.6"
            xmlSpace="preserve"
            style={{ fill: color }}
        >
            <g>
                <g>
                    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="296.4543" x2="897.5815" y2="296.4543">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <path  d="M94.3,258.7c13.7,0,24.7,6.4,32.9,19.3l17.7-12.5c-10.3-17.9-28.9-28.5-50.6-28.5
			c-33.4-0.7-61.8,26.6-61.2,59.5c-0.7,32.9,27.5,60.2,61.1,59.5c22.1,0,40.9-10.6,50.8-28.5l-17.7-12.5
			c-8.2,12.9-19.1,19.3-32.9,19.3c-21.4,0-37.1-15.7-37.1-37.6C57.3,274.7,72.9,258.7,94.3,258.7z"/>
                    <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="295.4142" x2="897.5815" y2="295.4142">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <path  d="M200.1,237l-51.3,116.9H174l6.4-15h47l6.4,15h25.2L208,237H200.1z M188.6,319.6l15.3-38.8l15.3,38.8H188.6z"
                    />
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="295.4107" x2="897.5815" y2="295.4107">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <polygon  points="327.5,299.1 278.9,237 271.3,237 271.3,353.9 294.8,353.9 294.8,292.6 323.7,328.8 331.3,328.8 
			360.2,292.6 360.2,353.9 383.7,353.9 383.7,237 376,237 		"/>
                    <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="296.4556" x2="897.5815" y2="296.4556">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <path  d="M449.8,239h-43.2v114.8h23.5v-40.5h19.7c24,0,41.4-15.3,41.4-37.2C491.2,254,473.8,239,449.8,239z
			 M449.8,292.8h-19.7v-33.1h19.7c10.6,0,17.4,6.6,17.4,16.4C467.2,286,460.8,292.8,449.8,292.8z"/>
                    <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="297.4982" x2="897.5815" y2="297.4982">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <path  d="M579.4,308.1c0,16.7-10.1,26.1-25.2,26.1c-14.8,0-25.2-9.4-25.2-26.1V239h-23.5v67.3
			c0,30.4,20.5,49.6,48.7,49.6c27.8,0,48.7-19.1,48.7-49.6V239h-23.5V308.1z"/>
                    <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="296.4543" x2="897.5815" y2="296.4543">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <path  d="M672.1,287.9l-13-5.7c-9.7-4.4-13.4-7.1-13.4-13.2c0-7.1,6.1-11.1,14.3-11.1c8.7,0,16,4.3,21.9,13.2l16-12.4
			c-7.1-13.2-20.4-21.7-37.9-21.7c-21.7,0-38.1,12.9-38.1,32.5c0,15.3,10.3,25.2,27.8,33.1l12.4,5.6c11,5,14.6,7.5,14.6,14.3
			c0,7.8-6.1,12.7-16.4,12.7c-12.5,0-20.7-6.4-28.2-16.7l-16.2,12.2c8.9,15.5,24.9,25.4,44.4,25.4c23,0,40.2-13.4,40.2-34.4
			C700.5,306,691.5,296.5,672.1,287.9z"/>
                    <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="339.775" x2="897.5815" y2="339.775">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <path  d="M729,323.6c-9.2,0-16.4,7.1-16.4,16.2c0,9.2,7.1,16.2,16.4,16.2c9.2,0,16-7,16-16.2
			C745,330.7,738.1,323.6,729,323.6z"/>
                    <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="295.4142" x2="897.5815" y2="295.4142">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <path  d="M803.3,237L752,353.9h25.2l6.4-15h47l6.4,15h25.2L811.1,237H803.3z M791.8,319.6l15.3-38.8l15.3,38.8H791.8z"
                    />
                    <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="296.4546" x2="897.5815" y2="296.4546">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <rect x="874.5" y="239"  width="23.5" height="114.8" />
                    <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="104.2573" x2="897.5815" y2="104.2573">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <polygon  points="373.1,165.8 278.1,165.8 278.1,116.4 345.3,116.4 345.3,82.4 278.1,82.4 278.1,42.7 369.5,42.7 
			369.5,6.6 238.2,6.6 238.2,201.9 373.1,201.9 		"/>
                    <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="104.2573" x2="897.5815" y2="104.2573">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <polygon  points="514.8,6.6 471.9,84.1 428.7,6.6 384.3,6.6 451.8,125.3 451.8,201.9 491.7,201.9 491.7,125 
			558.9,6.6 		"/>
                    <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="104.2511" x2="897.5815" y2="104.2511">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <path  d="M159.4,201.9h39.9V6.6h-39.9v55.7H73V6.6h-40v63.3l26.6,34.3l-26.6,34.3v63.3h40v-15.1
			c0-23.9,19.3-43.2,43.2-43.2c11.9,0,22.7,4.8,30.6,12.7c7.8,7.8,12.6,18.6,12.6,30.5V201.9z M116.2,141
			c-12.5,0-22.6-10.1-22.6-22.6c0-2.8,0.5-5.5,1.4-7.9c3.2-8.6,11.5-14.7,21.2-14.7c9.7,0,18,6.1,21.2,14.7c0.9,2.5,1.4,5.1,1.4,7.9
			C138.8,130.8,128.7,141,116.2,141z M150.1,112.2h-7.7l2.8-3.1V96.5l-6.7,3v6.7c-4.3-7.9-12.7-13.2-22.3-13.2
			c-9.6,0-17.9,5.4-22.3,13.2v-6.7l-15.2-6.8l37.4-16.1l37.4,16.1l-6.4,2.8v13.6L150.1,112.2z"/>
                    <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="104.2551" x2="897.5815" y2="104.2551">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <polygon points="19.3,58 19.3,150.5 55.2,104.3" />
                    <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="-3.3115" y1="104.2551" x2="897.5815" y2="104.2551">
                        <stop offset="0" />
                        <stop offset="1" />
                    </linearGradient>
                    <rect x="11" y="58" width="4.1" height="92.4" />
                </g>
            </g>
        </svg>
    )
}

export default HeyCampusAiLogo;