import React from 'react';
import { Avatar, Box, Paper, Typography } from '@material-ui/core';
import GenericCard from './GenericCard.web';
import './assets/styles/OtherStudentGoalsWidget.css';

const COLORS = ['#eff9ff', '#fff0ef', '#fff7ea', '#e9eeff'];

class OtherStudentGoalsWidget extends React.Component<IProps, IState> {
  render() {
    const { className = '', studentGoals } = this.props;

    return (
      <GenericCard
        className={className}
        id="other-students-goal-widget"
        title="Other Student Goals"
      >
        {studentGoals.map((item, idx) => (
          <Paper
            key={item.id}
            square
            style={{ backgroundColor: COLORS[idx % 4] }}
            className="widget-item"
          >
            <div className="widget-item-head">
              <Avatar className="student-avatar" src={item.profileImage} />
              <Typography className="student-title">
                {item.fullname}
              </Typography>
            </div>

            <Typography className="student-goal" component="p">
              {item.goal}
            </Typography>

            <Box className="due-date-wrapper">
              <Typography component="strong">Due Date:</Typography>
              <Typography component="span">{item.dueDate}</Typography>
            </Box>
          </Paper>
        ))}
      </GenericCard>
    );
  }
}

export interface IStudentGoalItem {
  id: string | number;
  fullname: string;
  profileImage: string;
  goal: string;
  dueDate: string;
}

interface IProps {
  className?: string;
  studentGoals: IStudentGoalItem[];
}

interface IState {}

export default OtherStudentGoalsWidget;
