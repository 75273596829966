import React from 'react';

const VideosIcon:React.FC<{color:string,height?:number,width?:number}> = (props) => {
    const { width = 24, height = 24, color } = props;
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} x="0px" y="0px"
            viewBox="0 0 500 500" xmlSpace="preserve">
            <g>
                <path fill={color} d="M491.1,72.8c-3.8-9-10.1-16.6-18.1-21.9c-8-5.4-17.6-8.5-27.9-8.5H54.9c-6.9,0-13.5,1.4-19.4,3.9
                c-9,3.8-16.6,10.1-21.9,18.1C8.2,72.3,5,81.9,5,92.2v315.6c0,6.9,1.4,13.5,3.9,19.4c3.8,9,10.1,16.6,18.1,21.9
                c8,5.4,17.6,8.5,27.9,8.5h390.2c6.9,0,13.5-1.4,19.4-3.9c9-3.8,16.6-10.1,21.9-18.1c5.4-8,8.5-17.6,8.5-27.9V92.2
                C495,85.4,493.6,78.8,491.1,72.8z M32,82.6c1.9-4.4,5-8.3,9-10.9c4-2.7,8.7-4.2,13.9-4.2h390.2c3.5,0,6.7,0.7,9.7,1.9
                c4.4,1.9,8.3,5,10.9,9c2.7,4,4.2,8.7,4.2,13.9v224.2H30.1V92.2C30.1,88.8,30.8,85.5,32,82.6z M468,417.4c-1.9,4.4-5,8.3-9,10.9
                c-4,2.7-8.7,4.2-13.9,4.2H54.9c-3.5,0-6.7-0.7-9.7-1.9c-4.4-1.9-8.3-5-10.9-9c-2.7-4-4.2-8.7-4.2-13.9v-66.3h439.8v66.3
                C469.9,411.2,469.2,414.5,468,417.4z"/>
                <path fill={color} d="M413.2,375.1H185c-6.9,0-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5h228.1c6.9,0,12.5-5.6,12.5-12.5
                C425.7,380.8,420.1,375.1,413.2,375.1z"/>
                <path fill={color} d="M120.4,361.5c-5-3.4-11.2-5.4-17.7-5.4c-4.3,0-8.5,0.9-12.3,2.5C84.7,361,79.9,365,76.5,370
                c-3.4,5-5.4,11.2-5.4,17.7c0,4.3,0.9,8.5,2.5,12.3c2.4,5.7,6.4,10.5,11.4,13.9c5,3.4,11.2,5.4,17.7,5.4c4.3,0,8.5-0.9,12.3-2.5
                c5.7-2.4,10.5-6.4,13.9-11.4c3.4-5,5.4-11.2,5.4-17.7c0-4.3-0.9-8.5-2.5-12.3C129.5,369.7,125.5,364.9,120.4,361.5z M108.8,390.2
                c-0.5,1.2-1.3,2.2-2.4,2.9c-1.1,0.7-2.3,1.1-3.6,1.1c-0.9,0-1.8-0.2-2.5-0.5c-1.2-0.5-2.2-1.3-2.9-2.4c-0.7-1.1-1.1-2.3-1.1-3.6
                c0-0.9,0.2-1.8,0.5-2.5c0.5-1.2,1.3-2.2,2.4-2.9c1.1-0.7,2.3-1.1,3.6-1.1c0.9,0,1.8,0.2,2.5,0.5c1.2,0.5,2.2,1.3,2.9,2.4
                c0.7,1.1,1.1,2.3,1.1,3.6C109.3,388.6,109.1,389.5,108.8,390.2z"/>
                <path fill={color} d="M213.2,244.7c5.2,3.4,11.3,5.3,17.8,5.3c5.3,0,10.9-1.4,15.9-4.2l43-24.2c5.4-3,9.7-7.4,12.4-12.4
                c2.8-5,4.2-10.5,4.2-16c0-5.5-1.4-11-4.2-16c-2.8-5-7-9.4-12.4-12.4l-43-24.2c-5-2.8-10.6-4.2-15.9-4.2c-4.3,0-8.5,0.9-12.3,2.4
                c-5.7,2.3-10.8,6.3-14.4,11.5c-1.8,2.6-3.3,5.5-4.3,8.7c-1,3.1-1.6,6.5-1.6,10v48.3c0,4.6,1,9.1,2.7,13.1
                C203.8,236.4,208,241.3,213.2,244.7z M224.1,166.1c0.6-1.3,1.5-2.4,2.8-3.2c1.2-0.8,2.7-1.2,4.1-1.2c1.2,0,2.3,0.3,3.6,1l43,24.2
                c1.4,0.8,2.2,1.7,2.9,2.8c0.6,1.1,0.9,2.4,0.9,3.7c0,1.3-0.3,2.6-0.9,3.7c-0.6,1.1-1.5,2-2.9,2.8l-43,24.2c-1.3,0.7-2.4,1-3.6,1
                c-0.9,0-1.9-0.2-2.8-0.6c-1.4-0.6-2.6-1.5-3.4-2.7c-0.4-0.6-0.7-1.2-0.9-1.9c-0.2-0.7-0.4-1.5-0.4-2.3v-48.3
                C223.5,167.9,223.7,166.9,224.1,166.1z"/>
            </g>
        </svg>
    );
}

export default VideosIcon;
