import React from 'react';
import './assets/styles/OtpInputs.css';

class OtpInputs extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { value: '', otp1: "", otp2: "", otp3: "", otp4: "", disable: true };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value1: TValue, event: any) {

    // @ts-ignore
    this.setState({ [value1]: event.target.value }, () => {
      const { otp1, otp2, otp3, otp4 } = this.state;
      this.props.onChange(`${otp1}${otp2}${otp3}${otp4}`)
    });
  }

  inputfocus = (elmnt: any) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next]?.focus()
      }
    }
    else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target.form.elements[next]?.focus()
      }
    }
  }

  render() {


    return (
      <form id="otp-form">
        <input
          name="otp1"
          type="text"
          autoComplete="off"
          className="otpInput"
          value={this.state.otp1}
          onChange={e => this.handleChange("otp1", e)}
          tabIndex={1}
          maxLength={1}
          onKeyUp={e => this.inputfocus(e)}

        />
        <input
          name="otp2"
          type="text"
          autoComplete="off"
          className="otpInput"
          value={this.state.otp2}
          onChange={e => this.handleChange("otp2", e)}
          tabIndex={2}
          maxLength={1}
          onKeyUp={e => this.inputfocus(e)}

        />
        <input
          name="otp3"
          type="text"
          autoComplete="off"
          className="otpInput"
          value={this.state.otp3}
          onChange={e => this.handleChange("otp3", e)}
          tabIndex={3}
          maxLength={1}
          onKeyUp={e => this.inputfocus(e)}

        />
        <input
          name="otp4"
          type="text"
          autoComplete="off"
          className="otpInput"
          value={this.state.otp4}
          onChange={e => this.handleChange("otp4", e)}
          tabIndex={4}
          maxLength={1}
          onKeyUp={e => this.inputfocus(e)}
        />
      </form>
    )
  }
}

type TValue = 'otp1' | 'otp2' | 'otp3' | 'otp4' | 'otp5'

interface IProps {
  onChange: (otpCode: string) => void
}

interface IState {
  value: string,
  otp1: string,
  otp2: string,
  otp3: string,
  otp4: string,
  disable: boolean
}

export default OtpInputs;