import React from 'react';
import { HomeIcon, ClassIcon, ChatIcon, CalendarIcon, MailIcon, SocialMediaIcon, ReportsIcon, LibraryIcon } from './assets/svgs';
import { Button, isWidthDown, withWidth } from '@material-ui/core';
import { withTheme, Theme } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import './assets/styles/Sidebar.css';
import MobileSidebar, { TMobileDrawerRef } from './MobileSidebar.web';
import DesktopSidebar from './DesktopSidebar.web';

const LIST_ITEMS = [
  { name: 'Home', icon: HomeIcon },
  { name: 'Class', icon: ClassIcon },
  { name: 'Calendar', icon: CalendarIcon },
  { name: 'Mail', icon: MailIcon },
  { name: 'Chat', icon: ChatIcon },
  { name: 'Library', icon: LibraryIcon },
  { name: 'Reports', icon: ReportsIcon },
  { name: 'Social Media', icon: SocialMediaIcon }
];

const SidebarWrapper: React.FC<IProps> = ({
  theme,
  width,
  isCollapsed,
  openSidebar,
  closeSidebar,
  mobileDrawerRef
}) => {
  const isMobileView = isWidthDown('sm', width, true)

  const MenuWrapper = (
    <div className="nav-menu-wrapper">
      {LIST_ITEMS.map(item => (
        <Button className="nav-menu-item" key={item.name}>
          {item.icon({ color: isMobileView ? theme.palette.primary.main : '#fff' })}
          <span className="button-text">{item.name}</span>

          {isMobileView && (
            <ChevronRight className="item-right-button" style={{ color: theme.palette.primary.main }} />
          )}
        </Button>
      ))}
    </div>
  )

  if (isMobileView) {
    return (
      <MobileSidebar
        mobileDrawerRef={mobileDrawerRef}
        closeSidebar={closeSidebar}
      >
        {MenuWrapper}
      </MobileSidebar>
    )
  }

  return (
    <DesktopSidebar
      isCollapsed={isCollapsed}
      openSidebar={openSidebar}
      closeSidebar={closeSidebar}
    >
      {MenuWrapper}
    </DesktopSidebar>
  )
};

interface IProps {
  theme: Theme,
  width: Parameters<Theme['breakpoints']['only']>[0],
  isCollapsed: boolean,
  openSidebar: () => void,
  closeSidebar: () => void,
  mobileDrawerRef: TMobileDrawerRef
}

export default withTheme(withWidth()(SidebarWrapper));