import {Button, IconButton, Typography} from '@material-ui/core';
import React from 'react';
import GenericCard from './GenericCard.web';
import { CloseOutlined } from '@material-ui/icons';
import {
  BirthdayCakeIcon,
  EventCalendarIcon,
  EventCalendarImage
} from './assets/images';
import './assets/styles/EventsWidget.css';

const EVENTS_ICONS: Record<string, string> = {
  BIRTHDAY: BirthdayCakeIcon,
  REMINDER: EventCalendarIcon
};

class EventsWidget extends React.Component<IProps, IState> {
  render() {
    const { events, onEventItemRemove } = this.props;

    return (
      <GenericCard id="events-widget" title="Events">
        {events.length === 0 && (
          <div className="empty-events-wrapper">
            <img
              src={EventCalendarImage}
              width={100}
              height={100}
              alt="empty-events"
            />
            <Typography component="span">No Event Scheduled!</Typography>
          </div>
        )}

        {events.length > 0 &&
          events.map(item => {
            const icon = EVENTS_ICONS[item.type];

            return (
              <div key={item.id} className="event-item-wrapper">
                <div className="event-item">
                  <img src={icon} alt="" className="event-avatar" />

                  <Typography className="event-title">{item.event}</Typography>

                  <IconButton
                      size="small"
                      className="event-close-button"
                      data-test-id="event-close-button"
                      onClick={() => onEventItemRemove(item)}
                  >
                    <CloseOutlined />
                  </IconButton>
                </div>
                <div className="event-item-bottom">
                  <Button className="cancel-file-button" color="primary" variant="outlined">View on Website</Button>
                  <Typography className="event-date">{item.date}</Typography>
                </div>
                <hr className="event-item-divider" />
              </div>
            );
          })}
      </GenericCard>
    );
  }
}

export interface IEventWidgetItem {
  id: string | number;
  type: string;
  event: string;
  date:string;
}

interface IProps {
  events: IEventWidgetItem[];
  onEventItemRemove: (event: IEventWidgetItem) => void
}

interface IState {}

export default EventsWidget;
