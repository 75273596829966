import React from 'react';

const FilesIcon:React.FC<{color:string,height?:number,width?:number}> = (props) => {
    const { width = 24, height = 24, color } = props;
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} x="0px" y="0px"
            viewBox="0 0 500 500" xmlSpace="preserve">
            <g>
                <path fill={color} d="M433.6,31.4c-3.3-7.8-8.7-14.3-15.6-19C411.1,7.7,402.7,5,393.8,5H193.7c-3.5,0-7,1.4-9.5,3.9l0,0L67,125.4
                c-2.5,2.4-4,5.8-4,9.6v316.8c0,5.9,1.2,11.6,3.4,16.8c3.3,7.8,8.7,14.3,15.6,19c6.9,4.7,15.3,7.4,24.2,7.4h287.6
                c5.9,0,11.6-1.2,16.8-3.4c7.8-3.3,14.3-8.7,19-15.6c4.7-6.9,7.4-15.3,7.4-24.2V48.2C437,42.3,435.8,36.6,433.6,31.4z M180.3,50.7
                v50.7c0,2.8-0.6,5.4-1.6,7.7c-1.5,3.5-4,6.6-7.2,8.8c-3.2,2.1-6.9,3.4-11.1,3.4l-51.4,0.1L180.3,50.7z M410.2,451.8
                c0,2.3-0.5,4.4-1.3,6.4c-1.2,2.9-3.3,5.4-5.9,7.2c-2.6,1.8-5.7,2.8-9.1,2.8H106.2c-2.3,0-4.4-0.5-6.4-1.3c-2.9-1.2-5.4-3.3-7.2-5.9
                c-1.8-2.6-2.8-5.7-2.8-9.1V148.4l70.7-0.2c6.4,0,12.6-1.3,18.2-3.7c8.4-3.6,15.5-9.5,20.5-16.9c5-7.4,8-16.5,8-26.1V31.9l186.6,0
                c2.3,0,4.4,0.5,6.4,1.3c2.9,1.2,5.4,3.3,7.2,5.9c1.8,2.6,2.8,5.7,2.8,9.1V451.8z"/>
                <path fill={color} d="M359.7,199H140.2c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4h219.5c7.4,0,13.4-6,13.4-13.4
                C373.1,205,367.1,199,359.7,199z"/>
                <path fill={color} d="M359.7,263.8H140.2c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4h219.5c7.4,0,13.4-6,13.4-13.4
                C373.1,269.9,367.1,263.8,359.7,263.8z"/>
                <path fill={color} d="M359.7,328.7H140.2c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4h219.5c7.4,0,13.4-6,13.4-13.4
                C373.1,334.7,367.1,328.7,359.7,328.7z"/>
                <path fill={color} d="M359.7,393.6H140.2c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4h219.5c7.4,0,13.4-6,13.4-13.4
                C373.1,399.6,367.1,393.6,359.7,393.6z"/>
            </g>
        </svg>
    );
}

export default FilesIcon;
