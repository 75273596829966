import React, {Component} from 'react';
import "./assets/styles/addAnswerDialog.css"
import DialogWrapper from "./DialogWrapper";
import {AttachFile, ImageIcon, ProfilePicture} from "./assets/images";
import {Button, IconButton} from "@material-ui/core";
import AddFileDialog from "./AddFileDialog";
import AddToDialogContent from './AddToDialogContent.web';
import FileListItem from "./FileListItem";
import PostImages from "./PostImages";

type MyProps = {
    isOpen:boolean,
    question:string | undefined,
    handleCloseModal: () => void
}
type MyState = {
    isAddFileDialogOpen:boolean,
    isAddPhotoDialogOpen:boolean,
    files: File[],
    images:string[],
    showFiles:boolean,
}

class AddAnswerDialog extends Component<MyProps, MyState> {
    private addFileDialogRef = React.createRef<AddFileDialog>();
    constructor(props:any) {
        super(props);

        this.handleUploadFiles = this.handleUploadFiles.bind(this);

        this.state = {
            isAddFileDialogOpen:false,
            isAddPhotoDialogOpen:false,
            files:[],
            images:[],
            showFiles:false,
        }
    }
    handleAddFileDialogOpen = () => {
        this.setState({isAddFileDialogOpen:true})
    }
    handleAddFileDialogClose = () => {
        this.setState({isAddFileDialogOpen:false,showFiles:false})
    }
    handleAddPhotoDialogOpen = () => {
        this.setState({isAddPhotoDialogOpen:true})
    }

    handleAddPhotoDialogClose = () => {
        this.setState({isAddPhotoDialogOpen:false})
    }

    handleUploadImages = (images: string[]) => {
        this.setState({images:images});
        this.handleAddPhotoDialogClose()
    }

    handleUploadFiles(files: File[]) {
        this.setState({ files })
        this.handleAddFileDialogClose()
    };

    handleDeleteFile = (index:number) => {
        this.addFileDialogRef.current?.handleDeleteFile(index, this.addFileDialogRef.current.handleUpload);
    }

    render() {
        const {isOpen,question,handleCloseModal} = this.props
        const {isAddFileDialogOpen,isAddPhotoDialogOpen,files,images} = this.state
        return (
            <DialogWrapper isOpen={isOpen} handleCloseModal={handleCloseModal}>
                <div className="add-answer-dialog">
                    <div className="add-answer-dialog-body">
                        <div className="add-answer-dialog-header">
                            <img src={ProfilePicture} alt=""/>
                            <h3>Teacher Name</h3>
                        </div>
                        <p className="question-text">{question}</p>
                        <textarea placeholder="Write your answer" name="answer" id="answer" cols={30} rows={5}></textarea>
                        {images.length > 0 && (
                            <PostImages
                                images={images}
                            />
                        )}
                        {files.length > 0 && files.map((file:any,index:number) => (
                            <FileListItem
                                handleDeleteFile={this.handleDeleteFile}
                                file={file}
                                index={index}
                                isDeletable={true}
                            />
                        ))}
                    </div>
                    <div className="add-answer-actions">
                        <IconButton onClick={this.handleAddPhotoDialogOpen} aria-label="photo"><img src={ImageIcon} alt=""/></IconButton>
                        <div className="vertical-line"></div>
                        <IconButton onClick={this.handleAddFileDialogOpen} aria-label="file"><img src={AttachFile} alt=""/></IconButton>
                        <Button className="add-answer-button" variant="contained" color="primary">Post</Button>
                    </div>
                </div>

                <AddFileDialog
                    ref={this.addFileDialogRef}
                    handleUploadFiles={this.handleUploadFiles}
                    isOpen={isAddFileDialogOpen}
                    handleCloseModal={this.handleAddFileDialogClose}
                />

                <DialogWrapper isOpen={isAddPhotoDialogOpen} handleCloseModal={this.handleAddPhotoDialogClose} >
                    <AddToDialogContent
                        title="Add Photo"
                        onImagesSelected={this.handleUploadImages}
                        isMultipleImageSelection={false}
                    />
                </DialogWrapper>
            </DialogWrapper>
        )
    }
}

export default AddAnswerDialog;
