import React from 'react';

const ClassIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 28, height = 28, color } = props;

    return(
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.667 5.333a.583.583 0 1 1 0 1.167H1.75a.584.584 0 0 0-.583.583V22.25c0 .322.261.584.583.584L12 22.833V22a.666.666 0 1 1 1.333 0v.666h1.334V22A.666.666 0 1 1 16 22l-.001.833H26.25a.584.584 0 0 0 .583-.583V7.083a.584.584 0 0 0-.583-.583h-2.917a.583.583 0 1 1 0-1.167h2.917c.965 0 1.75.785 1.75 1.75V22.25c0 .965-.785 1.75-1.75 1.75H15.999v2.666H19.4c.332 0 .6.299.6.668 0 .368-.268.666-.6.666H8.6c-.332 0-.6-.298-.6-.666 0-.369.268-.667.6-.667l3.4-.001V24H1.75C.785 24 0 23.215 0 22.25V7.083c0-.965.785-1.75 1.75-1.75h2.917zM19.235.943c.48.322.765.876.765 1.484v9.168a1.208 1.208 0 0 1-1.805 1.046c-1.497-.841-2.962-.658-4.483.56a.609.609 0 0 1-.757 0c-1.52-1.217-2.986-1.401-4.483-.56a1.203 1.203 0 0 1-1.212-.009 1.19 1.19 0 0 1-.593-1.037V2.427c0-.608.286-1.162.764-1.483 1.936-1.295 3.967-1.256 5.902.11C15.267-.312 17.3-.353 19.235.943zM8.105 1.953c-.139.093-.226.275-.226.475v9.168c.81-.46 1.625-.69 2.438-.69 1.01 0 2.018.355 3.016 1.063l.198-.134a.54.54 0 0 1-.198-.41V2.418a.606.606 0 0 1-.378-.132C11.315.974 9.73.865 8.105 1.952zm6.585-.31-.076.042a.51.51 0 0 1 .053.226v9.324c1.368-.537 2.747-.422 4.121.35V2.426c0-.2-.087-.382-.227-.475-1.298-.87-2.573-.974-3.87-.31z"
                fill={color}
                fill-rule="evenodd"
            />
        </svg>
    )
}

export default ClassIcon;
