import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import Slider from 'react-slick';

interface IStoryItem {
  id: number;
  source: string;
}
interface IUserOfStories {
  id: number;
  profilePicture: string;
  fullname: string;
  createdAt: string;
  stories: IStoryItem[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  userStatus: {
    account_id: number;
    status: string;
  }[];
  usersOfStories: IUserOfStories[];
  activeUser?: IUserOfStories;
  activeUserIndex: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserStatusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserStatusCallId: string = "";
  sliderRef = React.createRef<Slider>();
  storiesSiderRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      userStatus: [],
      usersOfStories: configJSON.usersOfStoriesDummyData,
      activeUserIndex: -1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.setActiveUser = this.setActiveUser.bind(this);
    this.nextStory = this.nextStory.bind(this);
    this.prevStory = this.prevStory.bind(this);
    this.nextUser = this.nextUser.bind(this);
    this.prevUser = this.prevUser.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.scrollToUserSiderPosition = this.scrollToUserSiderPosition.bind(this);
    this.closeStoryViewer = this.closeStoryViewer.bind(this);
    this.handleAddComment = this.handleAddComment.bind(this);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    document.addEventListener("keydown", this.handleKeyDown);

    if (document.body.clientWidth > 1024 && this.state.activeUserIndex === -1) {
      this.setState({
        activeUserIndex: 0,
        activeUser: this.state.usersOfStories[0]
      })
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getUserStatus(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse?.data?.account_status) {
        this.setState({
          userStatus: apiResponse.data?.account_status,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getUserStatus(token: string) {
    const headers = {
      "Content-Type": configJSON.userStatusApiContentType,
      token: token,
    };

    const getUserStatusMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserStatusCallId = getUserStatusMsg.messageId;

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatusApiEndpont
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserStatusMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userStatusApiMethod
    );

    runEngine.sendMessage(getUserStatusMsg.id, getUserStatusMsg);
  }

  async componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(event: KeyboardEvent) {
    let isPressedAllowedKeys = false;

    switch (event.keyCode) {
      case 39: // Right
        this.nextStory();
        isPressedAllowedKeys = true;
        break;
      case 37: // Left
        this.prevStory();
        isPressedAllowedKeys = true;
        break;
      case 38: // Up
        this.prevUser();
        isPressedAllowedKeys = true;
        break;
      case 40: // Down
        this.nextUser();
        isPressedAllowedKeys = true;
        break;
    }

    if (isPressedAllowedKeys) {
      event.preventDefault();
    }
  }

  setActiveUser(user: IUserOfStories, index: number) {
    this.setState({
      activeUser: user,
      activeUserIndex: index,
    });
  }

  nextUser() {
    this.setState((prevState) => {
      const activeUser =
        prevState.usersOfStories[prevState.activeUserIndex + 1];
      return activeUser
        ? {
            ...prevState,
            activeUser,
            activeUserIndex: prevState.activeUserIndex + 1,
          }
        : prevState;
    }, this.scrollToUserSiderPosition);
  }

  prevUser() {
    this.setState((prevState) => {
      const activeUser =
        prevState.usersOfStories[prevState.activeUserIndex - 1];
      return activeUser
        ? {
            ...prevState,
            activeUser,
            activeUserIndex: prevState.activeUserIndex - 1,
          }
        : prevState;
    }, this.scrollToUserSiderPosition);
  }

  closeStoryViewer() {
    this.setState({
      activeUser: undefined,
      activeUserIndex: -1,
    });
  }

  scrollToUserSiderPosition() {
    if (!this.storiesSiderRef.current) { return; }

    const sider = this.storiesSiderRef.current?.children[0]?.children[2] as HTMLElement;
    if (!sider) { return; }

    const elements = sider.children;
    const el = elements[this.state.activeUserIndex] as HTMLButtonElement;
    if (!el) { return; }

    sider.scrollTo({ top: el.offsetTop - sider.offsetTop, behavior: "smooth" });
    this.sliderRef.current?.slickGoTo(0);
  }

  nextStory() {
    this.sliderRef.current?.slickNext()
  }

  prevStory() {
    this.sliderRef.current?.slickPrev();
  }

  handleAddComment(values: { comment: string }) {
    console.log('Values:', values)
  }
  // Customizable Area End
}
