import React from 'react';
import {
    Button,
    Menu,
    Typography,
    FormControl,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    isWidthDown, withWidth
} from '@material-ui/core'
import { withTheme, Theme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { FilterIcon } from "./assets/svgs"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox"

class DiscussionFilterBy extends React.Component<IProps, IState> {
    render() {
        const isMobileView = isWidthDown('sm', this.props.width, true)
        const { theme, display } = this.props;
        if(((isMobileView && display === 'HEADER') || (!isMobileView && display === 'TAB')) || (display === 'TEST')){
            return (
                <>
                    <div
                        style={{border:isMobileView ? `0.5px solid ${theme.palette.secondary.main}` : 'none'}}
                        className="filter-button"
                    >
                        <Button data-test-id="filter-button" onClick={this.props.handleOpenFilter} variant="text">
                            <FilterIcon color={theme.palette.primary.main} />
                        </Button>
                    </div>

                    <Menu
                        data-test-id="filter-menu"
                        id="filter"
                        anchorEl={this.props.anchorElFilter}
                        keepMounted
                        open={Boolean(this.props.anchorElFilter)}
                        onClose={this.props.handleCloseFilter}
                        className="filter-menu"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: -180,
                            horizontal: 215
                        }}
                    >
                        <div className="filter-container">
                            <div className="filter-menu-header">
                                <Typography component="h3" color="primary">Filter by</Typography>
                                <span
                                    data-test-id="clear-filter"
                                    onClick={this.props.handleClearFilter}
                                >
                                    Clear
                                </span>
                            </div>
                            <label>Select Subject</label>
                            <FormControl
                                variant="outlined"
                                className="filter-multiple-select-box"
                            >
                                <Autocomplete
                                    multiple
                                    options={this.props.dummyData}
                                    disableCloseOnSelect
                                    getOptionLabel={(option: string) => option}
                                    renderOption={(option: string, { selected }: any) => (
                                        <React.Fragment>
                                            <Checkbox
                                                color="primary"
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option}
                                        </React.Fragment>
                                    )}
                                    // style={{ width: 500 }}
                                    renderInput={(params: any) => (
                                        <TextField
                                            className="filter-multiple-select-search"
                                            {...params}
                                            variant="outlined"
                                            placeholder="Search"
                                        />
                                    )}
                                />
                            </FormControl>
                            <label>Sorted by</label>
                            <FormControl
                                variant="outlined"
                                className="filter-select-box"
                            >
                                <InputLabel>Choose</InputLabel>
                                <Select
                                    value={this.props.sortedBy}
                                    onChange={(e)=>this.props.handleSortedByChange(e.target.value)}
                                    name="sortedBy"
                                    inputProps={{ "aria-label": "sortedBy" }}
                                >
                                    <MenuItem value="Recently Posted">
                                        Recently Posted
                                    </MenuItem>
                                    <MenuItem value="Last Week">Last week</MenuItem>
                                </Select>
                            </FormControl>
                            <div className="filter-menu-buttons">
                                <Button
                                    className="filter-button"
                                    variant="contained"
                                    color="primary"
                                >
                                    Apply
                                </Button>
                                <Button
                                    onClick={this.props.handleCancelFilter}
                                    className="filter-button"
                                    style={{
                                        borderColor: theme.palette.primary.main
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Menu>
                </>
            )
        }
        else return <></>
    }
}

export interface IProps {
    handleOpenFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleCancelFilter: () => void;
    handleSortedByChange: (val: unknown) => void;
    handleClearFilter: () => void;
    handleCloseFilter: () => void;
    anchorElFilter: null | HTMLElement;
    sortedBy: null | string;
    dummyData: any;
    theme: Theme;
    display: 'HEADER' | 'TAB' | 'TEST';
    width: Parameters<Theme['breakpoints']['only']>[0];
}

interface IState {
    
}

export default withTheme(withWidth()(DiscussionFilterBy));