import React from 'react';

const ChatIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 28, height = 28, color } = props;

    return(
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 500"
            width={width}
            height={height}
            xmlSpace="preserve"
        >
        <g>
            <path style={{ fill: color }} d="M446.62,424.08C477.51,387.47,495,340.4,495,292.85c0-77.9-44.5-145.62-109.44-179.14
                C351.77,49.51,284.33,5.63,206.81,5.63C95.53,5.63,5,96.03,5,207.15c0,47.55,17.49,94.62,48.38,131.24L9.17,383.73
                c-4.13,4.23-5.32,10.52-3.02,15.97s7.63,8.99,13.54,8.99h108.45c36.56,51.79,96.91,85.69,165.05,85.69h187.11
                c5.91,0,11.24-3.54,13.54-8.99c2.3-5.45,1.11-11.74-3.02-15.97L446.62,424.08z M54.55,379.29l29.52-30.27
                c5.56-5.7,5.57-14.8,0.01-20.51c-31.57-32.42-49.68-76.65-49.68-121.36c0-94.92,77.35-172.14,172.42-172.14
                s172.42,77.22,172.42,172.14c0,94.92-77.35,172.14-172.42,172.14H54.55z M293.19,464.98c-50.43,0-95.88-21.73-127.43-56.31h41.05
                c111.28,0,201.81-90.4,201.81-201.53c0-16.12-1.92-31.8-5.51-46.84c38.16,31.6,62.51,79.28,62.51,132.53
                c0,44.7-18.11,88.94-49.68,121.36c-5.56,5.71-5.56,14.81,0.01,20.51l29.52,30.27H293.19z"/>
            <path style={{ fill: color }} d="M120.4,163.84h172.81c7.91,0,14.32-6.41,14.32-14.32s-6.41-14.32-14.32-14.32H120.4
                c-7.91,0-14.32,6.41-14.32,14.32S112.49,163.84,120.4,163.84z"/>
            <path style={{ fill: color }} d="M120.4,221.16h172.81c7.91,0,14.32-6.41,14.32-14.32s-6.41-14.32-14.32-14.32H120.4
                c-7.91,0-14.32,6.41-14.32,14.32S112.49,221.16,120.4,221.16z"/>
            <path style={{ fill: color }} d="M307.53,264.53L307.53,264.53c0-7.91-6.41-14.32-14.32-14.32H120.4c-7.91,0-14.32,6.41-14.32,14.32v0
                c0,7.91,6.41,14.32,14.32,14.32h172.81C301.12,278.85,307.53,272.44,307.53,264.53z"/>
        </g>
        </svg>
    )
}

export default ChatIcon;
