import { Avatar, InputAdornment, TextField, Typography } from '@material-ui/core';
import React from 'react';
import GenericCard from './GenericCard.web';
import CreateContentFooterSection from './CreateContentFooterSection.web';
import { ProfilePicture } from './assets/images';
import DialogWrapper from './DialogWrapper';
import AddToDialogContent from './AddToDialogContent.web';
import AddFileDialog from './AddFileDialog';
import PostContent from './CreateContentPostSection';
import './assets/styles/CreateContentWidget.css';

class CreateContentWidget extends React.Component<IProps, IState> {
  private addFileDialogRef = React.createRef<AddFileDialog>();

  constructor(props: IProps) {
    super(props);

    this.onImagesSelected = this.onImagesSelected.bind(this);
    this.handleFilesUpload = this.handleFilesUpload.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.onFileButtonClicked = this.onFileButtonClicked.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.state = {
      isDialogOpen: false,
      isFileUploadDialogOpen: false,
      files: [],
      showFiles: false,
      images: [],
      isInnerDialog: false,
      postDescription: ""
    }
  }

  openDialog(view?: IState['view'], isInnerDialog?: boolean) {
    this.setState({
      view,
      isDialogOpen: true,
      isInnerDialog
    });
  }

  closeDialog() {
    this.setState({
      isDialogOpen: false,
      isInnerDialog: false
    })
  }

  onImagesSelected(images: string[]) {
    this.setState((prevState) => ({
      ...prevState,
      view: 'CREATE_CONTENT',
      images: [...prevState.images, ...images]
    }));
  }

  handleFilesUpload(files: File[]) {
    this.setState({
      view: 'CREATE_CONTENT',
      showFiles: true,
      files
    });
  }

  onFileButtonClicked() {
    this.setState({
      isFileUploadDialogOpen: true,
      view: 'UPLOAD_FILE'
    });
  }

  handleDeleteFile(index: number) {
    this.addFileDialogRef.current?.handleDeleteFile(index, this.addFileDialogRef.current.handleUpload);
  }

  handleCloseModal() {
    this.setState((prevState) => {
      if (prevState.isInnerDialog) {
        return { ...prevState, view: 'CREATE_CONTENT' }
      }

      return { ...prevState, isDialogOpen: false, isInnerDialog: false }
    })
  }

  handleCloseImageCarouselDialog() {
    this.setState({

    })
  }

  render() {
    const { children, title, actionButtonTitle, dialogTitle, useAvatar = true, id = '', inputPlaceholder, displayPostSection = true, sharedPostData, classes } = this.props;
    const { images,postDescription, files } = this.state;

    const AvatarDOM = (
      <InputAdornment position="start">
        <Avatar src={ProfilePicture} className="avatar" />
      </InputAdornment>
    );

    return (
      <>
        {displayPostSection && (
          <GenericCard id="create-content-widget">
            <Typography className="widget-title" component="h6">{title}</Typography>

            <div className="widget-content" id={id}>
              <TextField
                value={postDescription}
                onChange={(e)=>this.setState({postDescription: e.target.value})}
                className="textarea"
                placeholder={inputPlaceholder}
                variant="filled"
                fullWidth
                multiline
                InputProps={{
                  classes: {
                    root: 'mui-textarea-wrapper'
                  },
                  disableUnderline: true,
                  startAdornment: useAvatar ? AvatarDOM : null
                }}
              />

              {children && (
                <div className="widget-children-wrapper">
                  {children}
                </div>
              )}

              <CreateContentFooterSection
                onPhotoOrVideoButtonClicked={() => this.openDialog('IMAGE_OPTIONS')}
                onFileButtonClicked={() => this.openDialog('UPLOAD_FILE')}
                onActionButtonClicked={() => { }}
                actionButtonText={actionButtonTitle}
              />
            </div>
          </GenericCard>
        )}

        <DialogWrapper
          isOpen={this.state.isDialogOpen}
          handleCloseModal={this.closeDialog}
        >
          {this.state.view === 'IMAGE_OPTIONS' && (
            <AddToDialogContent
              title={dialogTitle}
              onImagesSelected={this.onImagesSelected}
              isMultipleImageSelection={this.props.isMultipleImageSelection}
            />
          )}

          {this.state.view === 'CREATE_CONTENT' && (
            <div className={`create-content-dialog-wrapper ${classes ?? ""}`}>
              <Typography className="dialog-title" component="h6">{title}</Typography>

              <div className="dialog-content">
                <div className="personal-info">
                  <Avatar src={ProfilePicture} className="avatar" />
                  <Typography component="strong">Albert Smith</Typography>
                </div>

                <TextField
                  value={postDescription}
                  onChange={(e)=>this.setState({postDescription: e.target.value})}
                  className="textarea"
                  placeholder="What's new, Albert?"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true
                  }}
                  fullWidth
                  multiline
                />

                <PostContent
                  id="postType"
                  images={images}
                  files={files}
                  handleDeleteFile={this.handleDeleteFile}
                  isHighlighted={!sharedPostData}
                  isDeletable
                />

                {(sharedPostData && sharedPostData.description) && (
                  <div className="share-to-feed-content">
                    <PostContent
                      id="repostType"
                      images={sharedPostData.images}
                      files={sharedPostData.files}
                      description={sharedPostData.description}
                      isHighlighted
                      isDeletable={false}
                    />
                  </div>
                )}

                <CreateContentFooterSection
                  onPhotoOrVideoButtonClicked={() => this.openDialog('IMAGE_OPTIONS', true)}
                  onFileButtonClicked={() => this.openDialog('UPLOAD_FILE', true)}
                  onActionButtonClicked={() => this.props.handleCreatePost!(files,images,postDescription)}
                  actionButtonText={actionButtonTitle}
                />
              </div>
            </div>
          )}

          <div className="upload-dialog-wrapper" hidden={this.state.view !== 'UPLOAD_FILE'}>
            <AddFileDialog
              ref={this.addFileDialogRef}
              handleUploadFiles={this.handleFilesUpload}
              useWrapper={false}
              handleCloseModal={this.handleCloseModal}
            />
          </div>
      </DialogWrapper>
      </>
    )
  }
}

export interface ISharedPostData {
  files: IState['files'];
  images: IState['images'];
  description: string
}
interface IProps {
  children?: React.ReactNode
  title: string;
  actionButtonTitle: string;
  dialogTitle: string;
  inputPlaceholder: string;
  useAvatar?: boolean;
  id?: string;
  isMultipleImageSelection?: boolean;
  handleCreatePost?:(files:any,images:string[],text:string)=>void;
  sharedPostData?: ISharedPostData
  displayPostSection?: boolean;
  classes?:string[];
}
interface IState {
  isDialogOpen: boolean;
  isInnerDialog?: boolean;
  view?: 'CREATE_CONTENT' | 'IMAGE_OPTIONS' | 'UPLOAD_FILE';
  isFileUploadDialogOpen: boolean;
  files: File[],
  showFiles: boolean;
  images: string[];
  postDescription:string;
}

export default CreateContentWidget
