import React from 'react';
import SidebarWrapper from './SidebarWrapper';
import { DrawerWidth } from './MobileSidebar.web';
import { adjustBodyScrollable } from './utilities/Utils';
import './assets/styles/Sidebar.css';

class Sidebar extends React.Component<IProps, IState> {
  private mobileDrawerRef = React.createRef<HTMLDivElement>();

  constructor(props: IProps) {
    super(props);

    this.openSidebar = this.openSidebar.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);

    this.state = { isCollapsed: true }
  }

  openSidebar() {
    this.setState({ isCollapsed: false }, () => {
      // For mobile
      const drawer = this.mobileDrawerRef.current;
      if (!drawer) { return; }
      drawer.style.transform = `translateX(-${0}px)`;
      adjustBodyScrollable('NOT_SCROLLABLE');
    });
  }

  closeSidebar() {
    this.setState({ isCollapsed: true }, () => {
      // For mobile
      const drawer = this.mobileDrawerRef.current;
      if (!drawer) { return; }
      drawer.style.transform = `translateX(-${DrawerWidth}px)`;
      adjustBodyScrollable('SCROLLABLE');
    });
  }

  render() {
    return (
      <SidebarWrapper
        isCollapsed={this.state.isCollapsed}
        openSidebar={this.openSidebar}
        closeSidebar={this.closeSidebar}
        mobileDrawerRef={this.mobileDrawerRef}
      />
    )
  }
}

interface IProps {}

interface IState {
  isCollapsed: boolean
}

export default Sidebar;
