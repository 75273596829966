import React, { Component } from 'react';
import { withTheme, Theme } from "@material-ui/core/styles";
import {IStory} from "./Stories";

type MyProps = {
    item:IStory;
    theme: Theme;
}
type MyState = {
}

class StoryItem extends Component<MyProps, MyState> {

    render() {
        const {item} = this.props
        return (
            <div key={item.id} className="story-item">
                <img className="main-image" src={item.mainImage} alt="" />
                <img className="mini-image" src={item.miniImage} style={{ borderColor: this.props.theme.palette.primary.main }} alt="" />
            </div>
        );
    }
}

export default withTheme(StoryItem);
