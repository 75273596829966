import React from 'react';
import { Button, Divider, Tab, Tabs } from '@material-ui/core';
import { CheckRounded } from '@material-ui/icons';
import './assets/styles/GalleryView.css';

const HEIGHTS = [280, 200, 280, 250, 280, 250, 200, 280, 200];

class GalleryView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.onTabChange = this.onTabChange.bind(this);
    this.selectImage = this.selectImage.bind(this);
    this.getSelectedImages = this.getSelectedImages.bind(this);

    this.state = { selectedTab: 'PICTURES', selectedImagesIndexes: [] }
  }

  calculateMasonryContainerHeight(): number {
    let total = 0;
    const columnCount = Math.ceil(this.props.images.length / 3);
    Array.from(Array(columnCount)).forEach((item, idx) => {
      total += (HEIGHTS[(idx % 3) * 3] + 8);
    });

    return total;
  }

  calculateMasonryItemTopDistance(idx: number): string {
    if (idx < 3) { return '0'; }
    let distance = 0;

    // Left Column
    if (idx % 3 === 1 && idx > 4 && (idx + 2) % 3 === 0) {
      distance = ((Math.round(idx / 9) || 1) * 30) + (Math.floor(idx / 9) * 80);
    }

    // Right Column
    if (idx % 3 === 0 && idx > 6) {
      distance = ((Math.floor(idx / 9) || 1) * 30) + (Math.floor((idx - 1)/ 9) * 80);
    }

    // Middle column
    if (idx % 3 === 2) {
      distance = Math.round(idx / 9) * 80;
    }

    return `-${distance}px`;
  }

  selectImage(idx: number) {
    let selectedImagesIndexes = [...this.state.selectedImagesIndexes];
    let foundImageIdx = selectedImagesIndexes.indexOf(`idx_${idx}`);
    if (foundImageIdx > -1) {
      selectedImagesIndexes.splice(foundImageIdx, 1);
    } else if (this.props.isMultipleSelection) {
      selectedImagesIndexes.push(`idx_${idx}`)
    } else {
      selectedImagesIndexes = [`idx_${idx}`]
    }

    this.setState({ selectedImagesIndexes });
  }

  onTabChange(event:any, value: IState['selectedTab']) {
    this.setState({ selectedTab: value });
  }

  getSelectedImages():string[] {
    return this.props.images.filter((image, idx) => {
      return this.state.selectedImagesIndexes.includes(`idx_${idx}`)
    })
  }

  render() {
    return (
      <div id="gallery-view">
        <div className="top-section">
          <Tabs
            value={this.state.selectedTab}
            onChange={this.onTabChange}
            indicatorColor="primary"
            aria-label="Gallery Tabs"
          >
            <Tab label="Picture's Gallery" value="PICTURES" />
            <Tab label="Uploaded By Me" value="UPLOADED_BY_ME" />
          </Tabs>
          
          <Button
            className="done-button"
            variant="contained"
            color="primary"
            disabled={this.state.selectedImagesIndexes.length === 0}
            onClick={() => this.props.onImagesSelected(this.getSelectedImages())}
          >
            <CheckRounded />
          </Button>
        </div>

        <Divider className="gallery-divider" />

        <div className="images-wrapper" style={{ height: this.calculateMasonryContainerHeight() }}>
          {this.props.images.map((item, idx) => {
            const isSelected = this.state.selectedImagesIndexes.includes(`idx_${idx}`);
            const topDistance = this.calculateMasonryItemTopDistance(idx + 1);

            return (
              <Button
                key={idx}
                color="primary"
                variant="text"
                className="gallery-view-item"
                style={{ height: HEIGHTS[idx % 9], top: topDistance }}
                onClick={() => this.selectImage(idx)}
              >
                <img src={item} />

                {isSelected && (
                  <div className="overlay">
                    <CheckRounded className="overlay-icon" />
                  </div>
                )}
              </Button>
            )
          })}
        </div>
      </div>
    )
  }
}

interface IProps {
  images: string[],
  onImagesSelected: (imageURL: string[]) => void,
  isMultipleSelection?: boolean;
}

interface IState {
  selectedTab: 'PICTURES' | 'UPLOADED_BY_ME';
  selectedImagesIndexes: string[];
}

export default GalleryView;