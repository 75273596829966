import React from 'react';
import { Logo } from './assets/images';
import { Box, Button } from '@material-ui/core';
import { withTheme, Theme } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import './assets/styles/Sidebar.css';


const DesktopSidebar: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  theme,
  isCollapsed = true,
  openSidebar,
  closeSidebar
}) => {
  return (
    <Box
      id="sidebar"
      component="aside"
      style={{ backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.light})` }}
      className={isCollapsed ? `__collapsed` : ''}
    >
      <img src={Logo} width={47} height={48} alt="Logo" className="desktop-logo" />

      <Button className="sidebar-collapser" color="primary" data-test-id="sidebar-collapser" onClick={isCollapsed ? openSidebar : closeSidebar}>
        <ChevronRight className="chevron-icon" />
      </Button>

      {children}
    </Box>
  )
}

interface IProps {
  theme: Theme,
  isCollapsed: boolean,
  openSidebar: () => void
  closeSidebar: () => void
}

export default withTheme(DesktopSidebar);
