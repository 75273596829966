import React from 'react';

const ShortCutsIcon:React.FC<{color:string,height?:number,width?:number}> = (props) => {
    const { width = 24, height = 24, color } = props;
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} x="0px" y="0px"
            viewBox="0 0 500 500" xmlSpace="preserve">
            <path fill={color} d="M486.8,206.6L281.1,39.9c-2.6-2.1-5.4-3.7-8.4-4.7c-3-1-6-1.5-9-1.5c-3.6,0-7.1,0.7-10.4,2
            c-4.9,2-9.2,5.2-12.4,9.7c-1.6,2.2-2.9,4.7-3.8,7.5c-0.9,2.7-1.3,5.6-1.3,8.6V137c-25.3,3.7-53.4,10.3-81.1,22
            c-16,6.8-31.9,15.2-46.9,25.8c-15.1,10.6-29.3,23.3-42.1,38.5c-15.6,18.6-30.9,39.9-42.2,66c-5.7,13-10.3,27.3-13.5,42.9
            c-3.2,15.6-5,32.6-5,51.1c0,21.8,2.5,45.8,8,72.4c1.2,5.8,6.1,10.1,12,10.5c5.9,0.4,11.3-3.2,13.3-8.7l0,0l0,0l0-0.1
            c0.4-1.1,4.1-10.9,11.9-25c5.8-10.6,13.9-23.6,24.6-37.2c16.1-20.4,37.9-42.1,66.5-59.2c14.3-8.6,30.4-16,48.3-21.6
            c14.1-4.4,29.4-7.6,46-9.4v84.4c0,4,0.8,7.9,2.4,11.3c1.1,2.6,2.7,4.9,4.4,7c2.7,3.1,5.9,5.4,9.5,7.1c3.6,1.6,7.5,2.5,11.6,2.5
            c3,0,6.1-0.5,9.2-1.6c3-1.1,5.9-2.7,8.5-4.8L487,240.9c2.6-2.2,4.7-4.9,6-7.8c1.3-2.9,2-6.1,2-9.2c0-3.2-0.7-6.4-2.1-9.3
            C491.6,211.5,489.5,208.8,486.8,206.6 M264.5,390.6c-0.2,0.2-0.4,0.2-0.5,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0-0.4,0-0.6-0.1
            c-0.3-0.1-0.6-0.3-0.7-0.5c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.5v-98.7c0-3.6-1.5-7.1-4.1-9.6c-2.6-2.5-6.1-3.8-9.8-3.6
            c-44,2.2-81,14.1-111.4,30.6c-22.8,12.4-41.9,27.3-57.7,42.7c-20.9,20.4-36.1,41.7-46.5,59.1c-0.9-9.4-1.3-18.3-1.3-26.7
            c0-16.8,1.6-32,4.5-45.7c4.3-20.7,11.3-38.4,20-54.2c8.7-15.9,19.1-29.9,30.1-43.1c11.2-13.3,23.7-24.5,37-33.9
            c20-14.1,42-24.1,63.9-31.2c21.9-7.1,43.8-11.2,63.6-13.5c6.7-0.8,11.7-6.4,11.7-13.1V61.5c0-0.4,0.1-0.5,0.1-0.6
            c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.2,0.6-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.5,0.3l201.6,163.4
            L264.5,390.6z"/>
        </svg>
    );
}

export default ShortCutsIcon;
