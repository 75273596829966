import React from 'react';
import { Box } from '@material-ui/core';
import { createTheme, ThemeProvider, Theme } from '@material-ui/core/styles';
import Sidebar from '../Sidebar.web';
import Header from '../Header.web';
import { COLORS } from '../utilities/Colors';
import { classNames } from '../utilities/Utils';

import '../assets/styles/MainContainer.css';
import BottomMenu from "../BottomMenu";
import {ACTIVE_LANDING_PAGE_TAB} from "../types";
import {INotificationMenuListItem} from "../NotificationMenuList";

class MainContainer extends React.Component<React.PropsWithChildren<IProps>, IState> {
  private sidebarRef = React.createRef<Sidebar>();

  constructor(props: IProps) {
    super(props);

    this.openSidebar = this.openSidebar.bind(this);

    const theme = createTheme({
      palette: {
        primary: {
          light: COLORS.TEACHER.primary.light,
          main: COLORS.TEACHER.primary.main,
        },
        secondary:{
          main: COLORS.TEACHER.secondary.main
        },
        divider: COLORS.TEACHER.divider
      },
      typography: {
        allVariants: {
          fontFamily: "'Open Sans', sans-serif"
        }
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 769,
          md: 1025,
          lg: 1280,
          xl: 1920
        }
      }
    });

    this.state = { theme }
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && typeof window.addEventListener === 'function') {
      this.setWindowHeight();
      window.addEventListener('resize', this.setWindowHeight);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined' && typeof window.removeEventListener === 'function') {
      window.removeEventListener('resize', this.setWindowHeight);
    }
  }

  setWindowHeight() {
    if (typeof window === 'undefined' || typeof document === 'undefined') { return; }
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  openSidebar() {
    this.sidebarRef.current?.openSidebar();
  }

  render() {
    const { isFullWidth = false, className = '', pageHeader, navigation, showMenu, activeTab, notificationMenuList, handleTabChange, handleChangeShortcutsView } = this.props;

    return (
      <ThemeProvider theme={this.state.theme}>
        <Box
          id="main-container"
          className={`${className} ${classNames({
            '__fullwidth': isFullWidth,
            '__has-page-header': !!pageHeader,
            'shortcuts-container' : activeTab === "SHORTCUTS"
          })}`}
        >
          <Sidebar ref={this.sidebarRef} />

          <Box className="content-area">
            <Box className="radius-background" bgcolor="primary.main" />
            <Box className="radius-background-2" bgcolor="primary.light" />
            <div className="content-area-wrapper">
              <Header
                handleChangeShortcutsView={handleTabChange}
                notificationMenuList={notificationMenuList}
                pageHeader={pageHeader}
                openSidebar={this.openSidebar}
                navigation={navigation}
                profilePicture={this.props.profilePicture}
              />

              <div className="content-section">
                {this.props.children}
              </div>
            </div>
          </Box>
          {
            showMenu &&
              <BottomMenu
                handleChangeShortcutsView={handleChangeShortcutsView}
                navigation={navigation}
                activeTab={activeTab}
                handleTabChange={handleTabChange}
              />
          }
        </Box>
      </ThemeProvider>
    )
  }
}

interface IProps {
  isFullWidth?: boolean;
  className?: string;
  pageHeader?: React.ReactNode
  pageTitle?:string;
  navigation?:any;
  showMenu?: boolean;
  activeTab?: ACTIVE_LANDING_PAGE_TAB;
  handleTabChange?:(newValue:ACTIVE_LANDING_PAGE_TAB)=>void;
  notificationMenuList?:INotificationMenuListItem[];
  handleChangeShortcutsView?: ()=> void;
  profilePicture?: string; 
}
interface IState {
  theme: Theme
}

export default MainContainer;
