import React, {Component} from "react";
import {Button, IconButton, Menu} from "@material-ui/core";
import './assets/styles/notificationMenuList.css'
import Close from "@material-ui/icons/Close";

export interface INotificationMenuListItem {
    id:number,
    image:string,
    name:string,
    text:string,
    createdAt:string
}

type MyProps = {
    anchorElNotifications: HTMLElement | null;
    handleCloseNotificationsMenu: () => void;
    notificationMenuList?:INotificationMenuListItem[];
    navigation:any;
}
type MyState = {}
class NotificationMenuList extends Component<MyProps, MyState>{
    render(){
        const {anchorElNotifications,handleCloseNotificationsMenu,notificationMenuList,navigation} = this.props
        return(
            <div>
                <Menu
                    id="more-options"
                    anchorEl={anchorElNotifications}
                    keepMounted
                    open={Boolean(anchorElNotifications)}
                    onClose={handleCloseNotificationsMenu}
                    className="notification-menu-list"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: -38,
                        horizontal: 30,
                    }}
                >
                    <div>
                        <div className="notification-menu-list-header">
                            <h3>Notifications</h3>
                            <IconButton onClick={handleCloseNotificationsMenu} disableRipple className="close-dialog-button">
                                <Close htmlColor="rgba(0, 0, 0, 0.1)" />
                            </IconButton>
                        </div>
                        <hr className="notification-menu-list-header-separator" />
                        <div className="notification-menu-list-body">
                            {
                                notificationMenuList?.map((item,index)=>(
                                    <>
                                        {
                                            (index === 0 || index === 3) &&
                                            <div className="notification-menu-list-date">{index === 0 ? "Today" : "Yesterday"}</div>
                                        }
                                        <div className="notification-menu-list-item">
                                            <img src={item.image} alt=""/>
                                            <div className="notification-menu-list-item-info">
                                                <div>
                                                    <span className="notification-menu-list-username">{item.name}</span>
                                                    <span className="notification-menu-list-text"> {item.text.substring(20) + "..."}</span>
                                                </div>
                                                <span className="notification-menu-list-item-date">{item.createdAt}</span>
                                            </div>
                                        </div>
                                        <hr/>
                                    </>
                                ))
                            }
                        </div>
                        <div className="notification-view-all-button">
                            <Button onClick={()=>navigation.navigate("Notifications")} color="primary" className="view-all-button" variant="contained" >View All</Button>
                        </div>
                    </div>
                </Menu>
            </div>
        )
    }
}

export default NotificationMenuList
