import React, {Component} from "react";
import "./assets/styles/bottomMenu.css"
import {
    ProfilePicture
} from "./assets/images";
import {Avatar, Box, Button, IconButton, TextField, Typography} from "@material-ui/core";
import {ArrowBackIosOutlined, CloseOutlined} from "@material-ui/icons";
import AddToDialogContent from "./AddToDialogContent.web";
import PostContent from "./CreateContentPostSection";
import CreateContentFooterSection from "./CreateContentFooterSection.web";
import AddFileDialog from "./AddFileDialog";
import DialogWrapper from "./DialogWrapper";
import {ISharedPostData} from "./CreateContentWidget.web";
import TargetGroupSelectbox from "./TargetGroupSelectbox.web";
import Slider from "react-slick";

type IView = 'CREATE_CONTENT' | 'IMAGE_OPTIONS' | 'UPLOAD_FILE' | 'ADD_STORY'

type MyProps = {
    handleCreatePost?:(files:any,images:string[],text:string)=>void;
    sharedPostData?: ISharedPostData;
    actionButtonTitle?: string;
    isDialogOpen: boolean;
    view?: IView;
    postFiles: File[],
    badgeFiles: File[],
    postImages: string[];
    badgeImages: string[];
    postDescription:string;
    title: string;
    isStoryImageSelected: boolean;
    storyImages: string[];
    onImagesSelected:(images: string[])=>void;
    handleFilesUpload:(files: File[])=>void;
    handleDeleteFile:(index: number)=>void;
    openDialog:(view?: IView, isInnerDialog?: boolean,title?:string)=>void;
    closeDialog:()=>void;
    handleCloseModal:()=>void;
    onRemoveImage:(index: number)=>void;
}
type MyState = {}
class BottomMenuDialogs extends Component<MyProps, MyState>{
    private addFileDialogRef = React.createRef<AddFileDialog>();
    render(){
        const {
            sharedPostData,
            actionButtonTitle,
            postDescription,
            postImages,
            badgeImages,
            postFiles,
            badgeFiles,
            title,
            storyImages,
            isStoryImageSelected,
            isDialogOpen,
            view,
            onImagesSelected,
            handleFilesUpload,
            handleDeleteFile,
            openDialog,
            closeDialog,
            handleCloseModal,
            handleCreatePost,
            onRemoveImage
        } = this.props
        return(
            <DialogWrapper
                isOpen={isDialogOpen}
                handleCloseModal={closeDialog}
                id={`${view === "ADD_STORY" && "story-viewer-dialog"}`}
            >
                {view === "ADD_STORY" && !isStoryImageSelected && (
                    <AddToDialogContent
                        title="Add To Story"
                        onImagesSelected={onImagesSelected}
                        isMultipleImageSelection
                    />
                )}

                {view === "ADD_STORY" && isStoryImageSelected && (
                    <div className="share-story-wrapper">
                        <div className="target-group">
                            <TargetGroupSelectbox />
                        </div>

                        {storyImages.length > 0 && (
                            <div className="story-images-wrapper">
                                <Slider
                                    dots={false}
                                    infinite={false}
                                    slidesToShow={Math.min(3, storyImages.length)}
                                    slidesToScroll={1}
                                    nextArrow={(
                                        <IconButton className="arrow-button">
                                            <ArrowBackIosOutlined className="next-arrow-icon" />
                                        </IconButton>
                                    )}
                                    prevArrow={(
                                        <IconButton className="arrow-button">
                                            <ArrowBackIosOutlined />
                                        </IconButton>
                                    )}
                                    className="slider-wrapper"
                                >
                                    {
                                        storyImages.map((item, idx) => (
                                            <div key={idx}>
                                                <Box
                                                    className="carousel-item"
                                                    style={{ backgroundImage: `url(${item})` }}
                                                >
                                                    <IconButton
                                                        className="remove-button"
                                                        onClick={() => onRemoveImage(idx)}
                                                        disabled={storyImages.length === 1}
                                                    >
                                                        <CloseOutlined />
                                                    </IconButton>
                                                </Box>
                                            </div>
                                        ))}
                                </Slider>
                            </div>
                        )}

                        <div className="actions">
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={closeDialog}
                            >
                                Discard
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={closeDialog}
                            >
                                Share to Story
                            </Button>
                        </div>
                    </div>
                )}

                {view === 'IMAGE_OPTIONS' && (
                    <AddToDialogContent
                        title={title}
                        onImagesSelected={onImagesSelected}
                        isMultipleImageSelection={true}
                    />
                )}

                {view === 'CREATE_CONTENT' && (
                    <div className="create-content-dialog-wrapper">
                        <Typography className="dialog-title" component="h6">{title}</Typography>

                        <div className="dialog-content">
                            <div className="personal-info">
                                <Avatar src={ProfilePicture} className="avatar" />
                                <Typography component="strong">Albert Smith</Typography>
                            </div>

                            <TextField
                                value={postDescription}
                                onChange={(e)=>this.setState({postDescription: e.target.value})}
                                className="textarea"
                                placeholder="What's new, Albert?"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true
                                }}
                                fullWidth
                                multiline
                            />

                            <PostContent
                                id="postType"
                                images={title === "Create Post" ? postImages : badgeImages}
                                files={title === "Create Post" ? postFiles : badgeFiles}
                                handleDeleteFile={handleDeleteFile}
                                isHighlighted={!sharedPostData}
                                isDeletable
                            />

                            {(sharedPostData && sharedPostData.description) && (
                                <PostContent
                                    id="repostType"
                                    images={sharedPostData.images}
                                    files={sharedPostData.files}
                                    description={sharedPostData.description}
                                    isHighlighted
                                    isDeletable={false}
                                />
                            )}

                            <CreateContentFooterSection
                                onPhotoOrVideoButtonClicked={() => openDialog('IMAGE_OPTIONS', true)}
                                onFileButtonClicked={() => openDialog('UPLOAD_FILE', true)}
                                onActionButtonClicked={() => handleCreatePost && handleCreatePost(title === "Create Post" ? postFiles : badgeFiles,title === "Create Post" ? postImages : badgeImages,postDescription)}
                                actionButtonText={actionButtonTitle}
                            />
                        </div>
                    </div>
                )}

                <div className="upload-dialog-wrapper" hidden={view !== 'UPLOAD_FILE'}>
                    <AddFileDialog
                        ref={this.addFileDialogRef}
                        handleUploadFiles={handleFilesUpload}
                        useWrapper={false}
                        handleCloseModal={handleCloseModal}
                    />
                </div>
            </DialogWrapper>
        )
    }
}

export default BottomMenuDialogs;
