import React from 'react';
import { Container } from '@material-ui/core';
import { createTheme, ThemeProvider, Theme } from '@material-ui/core/styles';
import { COLORS } from '../utilities/Colors';

class SignContainer extends React.Component<React.PropsWithChildren<IProps>, IState> {
    constructor(props: IProps) {
        super(props);

        const theme = createTheme({
            palette: {
                primary: {
                    light: COLORS.TEACHER.primary.light,
                    main: COLORS.TEACHER.primary.main,
                },
                divider: COLORS.TEACHER.divider
            },
            typography: {
                allVariants: {
                    fontFamily: "'Open Sans', sans-serif"
                }
            },
            breakpoints: {
                values: {
                  xs: 0,
                  sm: 600,
                  md: 1025,
                  lg: 1280,
                  xl: 1920
                }
              }
        });

        this.state = { theme }
    }

    componentDidMount() {
        if (typeof window !== 'undefined' && typeof window.addEventListener === 'function') {
            this.setWindowHeight();
            window.addEventListener('resize', this.setWindowHeight);
        }
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined' && typeof window.removeEventListener === 'function') {
            window.removeEventListener('resize', this.setWindowHeight);
        }
    }

    setWindowHeight() {
        if (typeof window === 'undefined' || typeof document === 'undefined') { return; }
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    render() {
        return (
            <ThemeProvider theme={this.state.theme}>
                {this.props.children}
            </ThemeProvider>
        )
    }
}

interface IProps { }

interface IState {
    theme: Theme
}

export default SignContainer;