import React from 'react';
import { HeyCampusAiLogo, LogoutIcon } from './assets/svgs';
import { Button, IconButton } from '@material-ui/core';
import { ArrowBack } from "@material-ui/icons";
import { withTheme, Theme } from '@material-ui/core/styles';
import { adjustBodyScrollable } from './utilities/Utils';
import './assets/styles/Sidebar.css';

export const DrawerWidth = 300;

class MobileSidebar extends React.Component<React.PropsWithChildren<IProps>, IState>{
  private touchStart: number = 0;
  private drawerStatus: 'OPENED' | 'CLOSED' = 'CLOSED';

  constructor(props: IProps) {
    super(props);

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.getDirection = this.getDirection.bind(this);
    this.changeDrawerLocation = this.changeDrawerLocation.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('touchstart', this.onTouchStart, { passive: true });
    document.addEventListener('touchmove', this.onTouchMove, { passive: true });
    document.addEventListener('touchend', this.onTouchEnd, { passive: true });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.onTouchStart);
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
  }

  onTouchStart(event: TouchEvent) {
    this.touchStart = event.changedTouches[0].screenX;
    const drawer = this.props.mobileDrawerRef.current;
    if (!drawer) { return; }

    const translateX = parseInt(drawer.style.transform.replace(/[^\d.]/g, ''));
    this.drawerStatus = (Number.isNaN(translateX) || translateX === DrawerWidth) ? 'CLOSED' : 'OPENED';
  }

  onTouchEnd(event: TouchEvent) {
    const touchEnd = event.changedTouches[0].screenX;

    const isSidebarOpenable = this.isSidebarOpenable(event.target as HTMLElement);
    if (isSidebarOpenable && this.drawerStatus === 'CLOSED') {
      this.changeDrawerLocation(touchEnd, 'END');
    }
  }

  onTouchMove(event: TouchEvent) {
    const isSidebarOpenable = this.isSidebarOpenable(event.target as HTMLElement);
    if (isSidebarOpenable && this.drawerStatus === 'CLOSED') {
      this.changeDrawerLocation(event.changedTouches[0].screenX, 'MOVE');
    }
  }

  getDirection(start: number, end: number) {
    if (end < start) return 'left';
    if (end > start) return 'right';
  }

  changeDrawerLocation(endPosition: number, action: 'MOVE' | 'END') {
    const drawer = this.props.mobileDrawerRef.current;
    if (!drawer) return;
    const direction = this.getDirection(this.touchStart, endPosition);
    const gap = Math.abs(endPosition - this.touchStart);

    const translateX = parseInt(drawer.style.transform.replace(/[^\d.]/g, ''));
    let transformValue = translateX;

    switch(action) {
      case 'END':
        if (this.drawerStatus === 'OPENED' && direction === 'left' && gap > (DrawerWidth / 2)) {
          transformValue = DrawerWidth;
          adjustBodyScrollable('SCROLLABLE');
        } else if (this.drawerStatus === 'CLOSED' && direction === 'right' && gap > (DrawerWidth / 2)) {
          transformValue = 0
          adjustBodyScrollable('NOT_SCROLLABLE');
        } else if (this.drawerStatus === 'OPENED') {
          transformValue = 0;
          adjustBodyScrollable('NOT_SCROLLABLE');
        } else {
          adjustBodyScrollable('SCROLLABLE');
          transformValue = DrawerWidth;
        }
        break;
      case 'MOVE':
        if (direction === 'right' && this.drawerStatus !== 'OPENED') {
          transformValue = DrawerWidth - Math.min(gap, DrawerWidth);
        } else if (direction === 'left' && this.drawerStatus !== 'CLOSED') {
          transformValue = Math.min(gap, DrawerWidth);
        }
        break;
    }

    drawer.style.transform = `translateX(-${transformValue}px)`;
  }

  handleClickOutside(event: MouseEvent) {
    const drawer = this.props.mobileDrawerRef.current;

    if (
      drawer &&
      event?.target &&
      event.target instanceof HTMLElement &&
      !drawer.contains(event.target)
    ) {
      drawer.style.transform = `translateX(-${DrawerWidth}px)`;
      adjustBodyScrollable('SCROLLABLE');
    }
  }

  isSidebarOpenable(el: HTMLElement): boolean {
    let ancestor = el as HTMLElement | null;
    let answer = false;

    if (!ancestor) { return false; }

    do {
      if (!ancestor) {
        ancestor = null;
        return true;
      }
 
      if (ancestor.matches('html')) {
        return true;
      }

      answer = (
        getComputedStyle(ancestor).overflowX !== 'hidden'
        && (
            (ancestor.scrollWidth > ancestor.clientWidth && ancestor.scrollWidth - ancestor.clientWidth > 50)
            || (ancestor.scrollWidth === ancestor.clientWidth && ancestor.scrollWidth > window.innerWidth)
        )
      );
      ancestor = ancestor.parentElement;
    } while(ancestor != null && answer !== true);

    return !answer;
  }

  render() {
    const { children, theme, mobileDrawerRef, closeSidebar } = this.props;

    return (
      <aside id="sidebar" ref={mobileDrawerRef}>
        <div className="logo-area">
          <HeyCampusAiLogo width={140} height={55} color={theme.palette.primary.main} />
  
          <IconButton onClick={closeSidebar}>
            <ArrowBack />
          </IconButton>
        </div>
  
        {children}
  
        <Button className="logout-button" variant="text">
          <LogoutIcon color={theme.palette.primary.main} />
          Sign Out
        </Button>
      </aside>
    )
  }
}

export type TMobileDrawerRef = React.RefObject<HTMLDivElement>;

interface IProps {
  theme: Theme,
  mobileDrawerRef: TMobileDrawerRef,
  closeSidebar: () => void
}

interface IState {}

export default withTheme(MobileSidebar);
