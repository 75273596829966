import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getFriendListType, getToken } from "../../../components/src/utilities/StorageHelper";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { FormikHelpers } from "formik";
import { IProps as IProfileWidgetData } from '../../../components/src/ProfileSummaryView.web';
import React from "react";
import { ITopStudent } from '../../../components/src/TopStudentsMenu';
import { IUser } from "../../../components/src/types";

interface IGrade {
  name: string;
  id: string;
}

interface IDivision {
  name: string;
  id: string;
}

export const configLandingJSON = require("../../landingpage/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  topStudents: ITopStudent[];
  users: IProfileWidgetData[];
  user: IUser;
  listType:string;
  grade_id: string;
  division_id: string;
  grades: IGrade[];
  divisions: IDivision[];
  school_id?: number;
  token: string;
  loading: '' | 'GRADES' | 'DIVISIONS' | 'STUDENTS';
  totalPagesOfUserList: number;
  activePageNumber: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FriendListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  fetchStudentsBySearchAPICallId: any;
  fetchStudentsByDivisionAPICallId: any;
  fetchTeachersAPICallId: any;
  fetchGradesAPICallId: any;
  fetchDivisionsApiCallId: any;
  fetchTopStudentsApiCallId: any;
  fetchUserDataApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleNavigate = this.handleNavigate.bind(this);
    this.fetchStudentsByDivision = this.fetchStudentsByDivision.bind(this);
    this.handleFetchGradesApiResponse = this.handleFetchGradesApiResponse.bind(this);
    this.handleFetchStudentsByDvisionApiResponse = this.handleFetchStudentsByDvisionApiResponse.bind(this);
    this.handleFetchDivisionsApiResponse = this.handleFetchDivisionsApiResponse.bind(this);
    this.handleFetchUserDataApiResponse = this.handleFetchUserDataApiResponse.bind(this);
    this.handleFetchTeachersApiResponse = this.handleFetchTeachersApiResponse.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.fetchGrades = this.fetchGrades.bind(this);
    this.fetchTeachers = this.fetchTeachers.bind(this);
    this.fetchDivisions = this.fetchDivisions.bind(this);
    this.onGradeSelected = this.onGradeSelected.bind(this);
    this.onDivisionSelect = this.onDivisionSelect.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.ProfileWidgetData),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.UserData)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      users: [],
      topStudents: [],
      user: {} as IUser,
      listType: getFriendListType(),
      grade_id: '',
      division_id: '',
      grades: [],
      divisions: [],
      token: '',
      loading: '',
      totalPagesOfUserList: 1,
      activePageNumber: 1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const responseName = getName(MessageEnum.RestAPIResponceMessage)
    const responseDataMessage = getName(MessageEnum.RestAPIResponceDataMessage);
    const isRestApiCallback = responseName === message.id;
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const isFetchStudentsByDivisionResponse = (
      isRestApiCallback
      && this.fetchStudentsByDivisionAPICallId != null
      && this.fetchStudentsByDivisionAPICallId === message.getData(responseDataMessage)
    );

    const isFetchStudentsBySearchResponse = (
      isRestApiCallback
      && this.fetchStudentsBySearchAPICallId != null
      && this.fetchStudentsBySearchAPICallId === message.getData(responseDataMessage)
    );

    const isGetGradesResponse = (
      isRestApiCallback
      && this.fetchGradesAPICallId != null
      && this.fetchGradesAPICallId === message.getData(responseDataMessage)
    );

    const isFetchDivisionsResponse = (
      isRestApiCallback
      && this.fetchDivisionsApiCallId != null
      && this.fetchDivisionsApiCallId === message.getData(responseDataMessage)
    );

    const isFetchTopStudentsResponse = (
      isRestApiCallback
      && this.fetchTopStudentsApiCallId != null
      && this.fetchTopStudentsApiCallId === message.getData(responseDataMessage)
    );

    const isFetchUserResponse = (
      isRestApiCallback
      && this.fetchUserDataApiCallId != null
      && this.fetchUserDataApiCallId === message.getData(responseDataMessage)
    );

    const isFetchTeachersResponse = (
      isRestApiCallback
      && this.fetchTeachersAPICallId != null
      && this.fetchTeachersAPICallId === message.getData(responseDataMessage)
    );

    if (
      isFetchStudentsByDivisionResponse
      || isFetchStudentsBySearchResponse
      || isFetchTeachersResponse
      || isGetGradesResponse
      || isFetchUserResponse
      || isFetchDivisionsResponse
      || isFetchTopStudentsResponse
    ) {
      this.setState({
        loading: '',
      }, () => {
        try {
          if (isFetchStudentsByDivisionResponse) {
            this.handleFetchStudentsByDvisionApiResponse(message, responseJson);
          } else if (isFetchStudentsBySearchResponse) {
            this.handleFetchStudentsBySearchApiResponse(message, responseJson);
          } else if (isGetGradesResponse) {
            this.handleFetchGradesApiResponse(message, responseJson);
          } else if (isFetchDivisionsResponse) {
            this.handleFetchDivisionsApiResponse(message, responseJson);
          } else if (isFetchTopStudentsResponse) {
            this.handleFetchTopStudentsApiResponse(message, responseJson);
          } else if (isFetchUserResponse) {
            this.handleFetchUserDataApiResponse(message, responseJson);
          } else if (isFetchTeachersResponse) {
            this.handleFetchTeachersApiResponse(message, responseJson);
          }
        } catch(err) {
          console.error(err);
        }
      });
    } else if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(getName(MessageEnum.UserData));
      this.setState({ user: data || {} }, () => {
        if (this.state.listType === 'teachers') {
          this.fetchTeachers();
        } else {
          this.fetchGrades();
        }
      });
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentWillMount() {

  }

  async componentDidMount(): Promise<void> {
    const token = await getToken();
    this.setState({ token });

    setTimeout(async () => {
      if (!this.state.user.schoolId) {
        await this.fetchUser();
      }
    }, 0)
  }

  handleFetchGradesApiResponse(message:Message, responseJson: any) {
    if (Array.isArray(responseJson?.data)) {
      this.setState({
        grades: responseJson.data.map((item: any) => {
          return {
            id: item.id,
            name: item.attributes.name
          }
        })
      });
    } else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleFetchStudentsByDvisionApiResponse(message:Message, responseJson: any) {
    if (Array.isArray(responseJson?.data)) {
      this.setState({
        users: (responseJson.data as IProfileWidgetData[]).map((item) => ({
          id: item.id,
          profilePicture: item.profilePicture,
          fullName: item.fullName,
          postCount: item.postCount,
          filesCount: item.filesCount,
          goalsCount: item.goalsCount,
          imageCount: item.imageCount,
          videosCount: 0,
        })),
        totalPagesOfUserList: Math.ceil(responseJson.meta.pagination.totalRecords / responseJson.meta.pagination.perPage)
      });
    } else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleFetchStudentsBySearchApiResponse(message:Message, responseJson: any) {
    if (Array.isArray(responseJson)) {
      this.setState({
        users: (responseJson as any[]).map((item) => ({
          id: item.id,
          profilePicture: item.profilePicture,
          fullName: item.fullName,
          postCount: item.post_count,
          filesCount: item.files_count,
          goalsCount: item.goals_count,
          imageCount: item.image_count,
          videosCount: 0,
        })),
        totalPagesOfUserList: 0
      });
    } else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleFetchDivisionsApiResponse(message:Message, responseJson: any) {
    if (Array.isArray(responseJson?.data)) {
      this.setState({
        divisions: responseJson.data.map((item: any) => ({
          name: item.name,
          id: item.id.toString()
        }))
      });
    } else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleFetchTopStudentsApiResponse(message:Message, responseJson: any) {
    if (Array.isArray(responseJson)) {
      this.setState({
        topStudents: (responseJson as ITopStudent[]).map((item) => ({
          Id: item.Id,
          fullName: item.fullName,
          profilePicture: item.profilePicture,
          std: item.std
        }))
      });
    } else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleFetchUserDataApiResponse(message: Message, responseJson: any) {
    if (responseJson && responseJson?.data) {
      this.setState({ user: responseJson.data }, () => {
        if (this.state.listType === 'teachers') {
          this.fetchTeachers();
        } else {
          this.fetchGrades();
        }
      });
    } else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleFetchTeachersApiResponse(message:Message, responseJson: any) {
    if (Array.isArray(responseJson?.data)) {
      this.setState({
        users: (responseJson.data as IProfileWidgetData[]).map((item) => ({
          id: item.id,
          profilePicture: item.profilePicture,
          fullName: item.fullName,
          postCount: item.postCount,
          filesCount: item.filesCount,
          goalsCount: item.goalsCount,
          imageCount: item.imageCount,
          videosCount: 0,
        })),
        totalPagesOfUserList: Math.ceil(responseJson.meta.pagination.totalRecords / responseJson.meta.pagination.perPage)
      });
    } else if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    } else {
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleNavigate(item?: IProfileWidgetData, isMyProfile?: boolean) {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");

    const receivedData: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));

    if (isMyProfile) {
      localStorage.setItem('profileType',"myProfile");
      localStorage.removeItem('profileWidgetData');
    } else {
      localStorage.setItem('profileType',"visitingStudent")
      localStorage.setItem('profileWidgetData', JSON.stringify(item))
      receivedData.addData(getName(MessageEnum.ProfileWidgetData),item)
    }
    
    receivedData.addData(getName(MessageEnum.UserData), this.state.user)
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), receivedData);

    this.send(msg);
    //runEngine.sendMessage(item,msg)
  }

  fetchGrades() {
    if (this.state.listType !== 'students') { return; }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchGradesAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.getGradesApi.baseEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGradesApi.baseEndpoint}/${configJSON.getGradesApi.endpointMethod}?school_id=${this.state.user.schoolId}&token=${this.state.token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(configJSON.getGradesApi.contentType)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getGradesApi.apiMethod
    );

    this.setState({
      loading: 'GRADES'
    }, () => {
      runEngine.sendMessage(requestMessage.id, requestMessage);      
    });
  }

  fetchDivisions() {
    const { grade_id, user } = this.state;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchDivisionsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDivisionsApi.baseEndpoint}/${configJSON.getDivisionsApi.endpointMethod}?grade_ids[]=${grade_id}&school_id=${user.schoolId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(configJSON.getDivisionsApi.contentType)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDivisionsApi.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchStudentsByDivision() {
    const data = {
      grade_id: parseInt(this.state.grade_id),
      division_id: parseInt(this.state.division_id),
      activePage: this.state.activePageNumber
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchStudentsByDivisionAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentsByDivisionApi.baseEndpoint}/${configJSON.getStudentsByDivisionApi.endpointMethod}?grade_id=${data.grade_id}&division_ids[]=${data.division_id}&page=${data.activePage}&token=${this.state.token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(configJSON.getStudentsByDivisionApi.header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getStudentsByDivisionApi.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchTeachers() {
    const data = {
      grade_id: parseInt(this.state.grade_id),
      division_id: parseInt(this.state.division_id),
      activePage: this.state.activePageNumber,
      school_id: this.state.user.schoolId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchStudentsByDivisionAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeachersApi.baseEndpoint}/${configJSON.getTeachersApi.endpointMethod}?school_id=${data.school_id}&type=TeacherAccount&page=${data.activePage}&token=${this.state.token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(configJSON.getTeachersApi.header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTeachersApi.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchStudentsBySearch(query: string) {
    // If search not includes any information, use regular student fetch action
    if (query.length === 0) { return this.fetchStudentsByDivision(); }

    const data = {
      grade_id: parseInt(this.state.grade_id),
      division_id: parseInt(this.state.division_id),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchStudentsBySearchAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentsBySearchApi.baseEndpoint}/${configJSON.getStudentsBySearchApi.endpointMethod}?grade_id=${data.grade_id}&division_ids[]=${data.division_id}&query=${query}&token=${this.state.token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(configJSON.getStudentsBySearchApi.header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getStudentsBySearchApi.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async fetchUser() {
    const { user } = this.state;
    const token = await getToken();

    // If tokens exist and firstname not exist
    if (token && !user.fullName) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.fetchUserDataApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getUserDataApi.baseEndpoint}/${configJSON.getUserDataApi.endpointMethod}?token=${token}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(configJSON.getUserDataApi.header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getUserDataApi.apiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  fetchTopStudents() {
    const data = {
      grade_id: parseInt(this.state.grade_id),
      division_id: parseInt(this.state.division_id),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchTopStudentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTopStudentsApi.baseEndpoint}/${configJSON.getTopStudentsApi.endpointMethod}?grade_id=${data.grade_id}&division_ids[]=${data.division_id}&token=${this.state.token}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(configJSON.getTopStudentsApi.header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTopStudentsApi.apiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onGradeSelected(event: React.ChangeEvent<{ value: any }>) {
    const grade_id = event.target.value;

    this.setState({
      grade_id,
      division_id: '',
      users: [],
      loading: 'DIVISIONS'
    }, this.fetchDivisions)
  }

  onDivisionSelect(event: React.ChangeEvent<{ value: any }>) {
    const division_id = event.target.value;

    this.setState({
      division_id,
      loading: 'STUDENTS'
    }, () => {
      this.fetchStudentsByDivision();
      this.fetchTopStudents();
    })
  }

  onPageChanged(event: React.ChangeEvent<unknown>, page: number) {
    if (this.state.activePageNumber !== page) {
      this.setState({
        activePageNumber: page,
        loading: 'STUDENTS'
      }, this.fetchStudentsByDivision)
    }
  }

  // Customizable Area End
}
