import React from 'react';

const MailIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 28, height = 28, color } = props;

    return(
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.816 8C26.573 8 28 9.45 28 11.235v10c0 1.785-1.427 3.236-3.184 3.236H14.78l-2.925 3.243a.863.863 0 0 1-.956.226.883.883 0 0 1-.553-.822V24.47H8.898c-1.756 0-3.184-1.451-3.184-3.236v-2.94h1.737v2.94c0 .81.65 1.47 1.447 1.47h2.315c.48 0 .869.396.869.883v1.261l1.675-1.857a.862.862 0 0 1 .64-.286h10.42c.797 0 1.446-.66 1.446-1.47v-10c0-.811-.649-1.471-1.447-1.471h-.868V8h.868zM19.06 0c1.78 0 3.226 1.46 3.226 3.255v10.061c0 1.796-1.447 3.255-3.226 3.255H3.226C1.446 16.571 0 15.112 0 13.316V3.256C0 1.458 1.446 0 3.226 0H19.06zm1.466 5.102-8.377 4.91a1.997 1.997 0 0 1-2.012.001L1.759 5.102v8.214c0 .816.658 1.48 1.467 1.48H19.06c.808 0 1.466-.664 1.466-1.48V5.102zM19.06 1.776H3.226c-.742 0-1.357.559-1.454 1.281L11 8.463c.088.053.2.053.288 0l9.226-5.406a1.473 1.473 0 0 0-1.453-1.281z"
                fill={color}
                fill-rule="nonzero"
            />
        </svg>
    )
}

export default MailIcon;
