import React from 'react';
import { Avatar, Button, Box, IconButton, Badge, withWidth, isWidthDown } from '@material-ui/core';
import { HeaderCheckIcon, HeaderEnglishLang, HeaderSupportIcon, HeaderFontIcon, Logo, ProfilePlaceholderImage } from './assets/images';
import { ChevronLeftOutlined, Notifications, SearchOutlined } from '@material-ui/icons';
import { withTheme, Theme } from '@material-ui/core/styles';
import { classNames } from './utilities/Utils'
import './assets/styles/Header.css';
import NotificationMenuList, {INotificationMenuListItem} from "./NotificationMenuList";
import {ACTIVE_LANDING_PAGE_TAB} from "./types";

class Header extends React.Component<IProps, IState>{
  constructor(props: IProps) {
    super(props);

    this.toggleActionsVisibility = this.toggleActionsVisibility.bind(this);
    this.state = { showActions: false, anchorElNotifications:null }
  }

  toggleActionsVisibility() {
    this.setState((prevState) => ({
      ...prevState,
      showActions: !prevState.showActions
    }))
  }

  handleOpenNotificationsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorElNotifications:event.currentTarget})
  }

  handleCloseNotificationsMenu = () => {
    this.setState({anchorElNotifications:null})
  }

  render() {
    const isMobileView = isWidthDown('sm', this.props.width, true)
    const { theme, openSidebar, notificationMenuList, navigation, profilePicture, handleChangeShortcutsView } = this.props;
    const { showActions, anchorElNotifications } = this.state;
 

    return (
      <Box
        component="header"
        id="app-header"
        className={classNames({
          '__mobile-view': isMobileView,
          '__has-page-header': typeof this.props.pageHeader !== 'undefined'
        })}
      >
        <Box className="header-content" bgcolor="primary.light">
          <div className="header-logo">
            <img src={Logo} width={33} height={34} alt="Logo" onClick={openSidebar} />
          </div>

          <div className="header-actions">
            <div className={`action-icons ${showActions ? '__show' : ''}`}>
              <div className="search-action action-item-wrapper">
                <IconButton>
                  <SearchOutlined style={{ color: isMobileView ? '#fff' : theme.palette.primary.main, fontSize: 28 }} />
                </IconButton>
              </div>

              <div className="done-action action-item-wrapper">
                <IconButton>
                  <img src={HeaderCheckIcon} alt="" width={24} height={24} />
                </IconButton>
              </div>

              <div className="notifications-action action-item-wrapper">
                <IconButton onClick={this.handleOpenNotificationsMenu}>
                  <Badge color="error" badgeContent={0} showZero>
                    <Notifications />
                  </Badge>
                </IconButton>
              </div>

              <div className="support-action action-item-wrapper">
                <IconButton>
                  <img src={HeaderSupportIcon} alt="" width={24} height={24} />
                </IconButton>
              </div>

              <div className="font-action action-item-wrapper">
                <IconButton>
                  <img src={HeaderFontIcon} alt="" width={28} height={28} />
                </IconButton>
              </div>

              <div className="language-action action-item-wrapper">
                <IconButton>
                  <img src={HeaderEnglishLang} alt="" width={24} height={24} />
                </IconButton>
              </div>
            </div>

            <Button
              variant="contained"
              color="primary"
              className="profile-button"
              data-test-id="profile-button"
              disableElevation
              style={{ backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.light})` }}
              onClick={this.toggleActionsVisibility}
            >
              {isMobileView ? (
                <ChevronLeftOutlined style={{ color: theme.palette.primary.main }} />
              ) : (
                <ChevronLeftOutlined />
              )}
            </Button>

            <Avatar
              onClick={()=>isMobileView && handleChangeShortcutsView && handleChangeShortcutsView('SHORTCUTS')}
              src={profilePicture || ProfilePlaceholderImage}
              alt="profile picture"
              className="profile-picture"
            />
          </div>
        </Box>
        <NotificationMenuList navigation={navigation} notificationMenuList={notificationMenuList} anchorElNotifications={anchorElNotifications} handleCloseNotificationsMenu={this.handleCloseNotificationsMenu}/>

        {this.props.pageHeader && (
          <div className="page-header-wrapper">
            {this.props.pageHeader}
          </div>
        )}
      </Box>
    );
  }
};

interface IProps {
  theme: Theme;
  width: Parameters<Theme['breakpoints']['only']>[0],
  openSidebar: () => void;
  pageHeader?: React.ReactNode;
  notificationMenuList?:INotificationMenuListItem[];
  navigation:any;
  handleChangeShortcutsView?: (newValue:ACTIVE_LANDING_PAGE_TAB)=>void;
  profilePicture?: string;
}

interface IState {
  showActions: boolean;
  anchorElNotifications: HTMLElement | null;
}

export default withTheme(withWidth()(Header));
