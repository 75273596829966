import React, {Component} from 'react';
import {
    Button,isWidthDown,withWidth
} from "@material-ui/core";
import './assets/styles/singlePost.css'
import {ArrowDownIcon} from './assets/images';
import CreatePostBox from "./CreatePostBox";
import PostInteractions from "./PostInteractions";
import PostImages from './PostImages';
import {IPost} from "./SinglePost";
import CommentBox from "./CommentBox";
import {IComment} from "./CommentsDialog";
import { configJSON } from '../../blocks/landingpage/src/LandingPageController';
import { ACTIVE_LANDING_PAGE_TAB, ACTIVE_LANDING_PAGE_BOXED_VIEW } from "./types"
import { withTheme, Theme } from '@material-ui/core/styles';
import SinglePostContentHeader from './SinglePostContentHeader';
import SinglePostContentDescription from './SinglePostContentDescription';
import SinglePostContentVideo from './SinglePostContentVideo';
import SinglePostContentFiles from './SinglePostContentFiles';
import SinglePostContentComment from './SinglePostContentComment';

type MyProps = {
    item:IPost,
    createPost?:boolean,
    boxedView?: ACTIVE_LANDING_PAGE_BOXED_VIEW,
    answerAction?: "expand" | "dialog",
    isPost?: boolean | undefined,
    isUserInformationActive?: boolean | undefined,
    activeTab: ACTIVE_LANDING_PAGE_TAB,
    handleOpenAnswerView?: (question:IPost)=> void,
    isAnswerView?:boolean,
    theme:Theme,
    displayAllowInfo?: boolean,
    handleHidePost:(index:number)=>void,
    width: Parameters<Theme['breakpoints']['only']>[0]
}
type MyState = {
    isFavorite:boolean,
    isCommentsOpen:boolean,
    comments: IComment[] | undefined,
    replies: IComment[][] | undefined,
    displayedComments: number,
    displayPlayIcon:boolean,
    isRepliesOpen:boolean
}

class SinglePostContents extends Component<MyProps,MyState> {
    constructor(props:any){
        super(props);

        this.state = {
            isFavorite: props.item.isFavorite,
            isCommentsOpen:false,
            isRepliesOpen:false,
            replies:configJSON.replies,
            comments:this?.props?.item?.interactions?.comments,
            displayedComments:5,
            displayPlayIcon:true
        }
    }

    handleToggleShowComments = () => {
        this.setState((state)=>({isCommentsOpen:!state.isCommentsOpen}))
    }

    handleToggleShowReplies = () => {
        this.setState((state)=>({isRepliesOpen:!state.isRepliesOpen}))
    }

    handleAddComments = (comment:IComment) => {
        this.setState((state)=>({comments:state.comments && [comment,...state?.comments]}))
    }

    handleLoadMoreComments = () => {
        this.setState((state)=>({displayedComments:state.displayedComments + 5}))
    }

    handleStartVideo = () => {
        let vid = document.querySelector('video');
        vid?.play()
        console.log(vid)
    }

    componentDidMount(){
        if(typeof window !== 'undefined' && window.document){
            let vid = document?.querySelector('video');
            vid?.addEventListener('play', (event) => {
                this.setState({displayPlayIcon:false})
            });
            vid?.addEventListener('pause', (event) => {
                this.setState({displayPlayIcon:true})
            });
        }
    }

    render(){
        const isMobileView = isWidthDown('sm', this.props.width, true)
        const { item, createPost, boxedView, answerAction, isPost, isUserInformationActive, activeTab, handleOpenAnswerView, displayAllowInfo, handleHidePost } = this.props
        const { isCommentsOpen, isRepliesOpen, comments, displayedComments, displayPlayIcon } = this.state

        return (
            <div className="single-post-container">
                <SinglePostContentHeader
                    fullName={item.name}
                    picture={item.picture}
                    postedTime={item.postedTime}
                    allowedTo={item.allowedTo}
                    isUserInformationActive={isUserInformationActive}
                    displayAllowInfo={displayAllowInfo}
                />
                <SinglePostContentDescription
                    item={item}
                    handleOpenAnswerView={handleOpenAnswerView}
                    handleHidePost={handleHidePost}
                    activeTab={activeTab}
                    isRepliesOpen={isRepliesOpen}
                    boxedView={boxedView}
                    color={this.props.theme.palette.primary.main}
                />
                {
                    item.dueDate &&
                    <div className="due-date">
                        <span>Due Date: </span><span>{item.dueDate}</span>
                    </div>
                }
                <SinglePostContentVideo
                    video={item.video}
                    displayPlayIcon={displayPlayIcon}
                    handleStartVideo={this.handleStartVideo}
                />
                {
                    item.goalPicture &&
                    <img className="goal-picture" src={item.goalPicture} alt=""/>
                }
                {item.images && item.images.length > 0 && !item.answers && (
                    <PostImages
                        images={item.images.map(item=>item.image)}
                    />
                )}
                <SinglePostContentFiles
                    file={item.file}
                    answers={item.answers}
                    boxedView={boxedView}
                />
                {
                    (!boxedView || boxedView === 'NONE' || boxedView === 'BADGE' || boxedView === 'QUESTION') && isPost &&
                    <hr className="first-line" />
                }
                {
                    isPost &&
                    <PostInteractions handleToggleShowReplies={this.handleToggleShowReplies} handleToggleShowComments={this.handleToggleShowComments} activeTab={activeTab} item={item} question={item?.question?.question} answerAction={answerAction} boxedView={boxedView} interactions={item.interactions} isFavorite={item.isFavorite} />
                }
                {
                    (!boxedView || boxedView === 'NONE' || boxedView === 'BADGE' || boxedView === 'QUESTION') && isPost &&
                    <hr className="second-line" />
                }
                {
                    (createPost || (isRepliesOpen && isMobileView)) &&
                    <CreatePostBox handleAddComment={this.handleAddComments} />
                }
                <SinglePostContentComment
                    comments={comments}
                    isCommentsOpen={isCommentsOpen}
                    displayedComments={displayedComments}
                    handleLoadMoreComments={this.handleLoadMoreComments}
                />
                {
                    isRepliesOpen &&
                        <div className="comments replies">
                            {
                                // replies?.filter((item, index) => this.props.item.id === index + 1)?.map((item: any, index: number) => {
                                configJSON.replies[0].map((item:any,index:number)=>{
                                    if (index < displayedComments) {
                                        return (
                                            <CommentBox item={item}/>
                                        )
                                    }
                            })}
                        </div>
                }
                {
                    configJSON.replies && configJSON.replies?.length > displayedComments && isRepliesOpen &&
                    <Button onClick={this.handleLoadMoreComments} startIcon={<img src={ArrowDownIcon} alt=""/>} className="load-more-button" variant="text" >Load more comments</Button>
                }
                {
                    (isRepliesOpen && isMobileView) &&
                    <hr className="answers-dialog-separator" />
                }
            </div>
        );
    }
}

export default withTheme(withWidth()(SinglePostContents));
