
export const hexToRgba = (hex: string): number[] => {
    let num = parseInt(hex.slice(1), 16); // Convert to a number
    return [num >> 16 & 255, num >> 8 & 255, num & 255, num >> 24 & 255];
}

export const getHexAsRgba = (hex: string, alpha: number): string => {
    const rgba = hexToRgba(hex);
    return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${alpha})`;              
}

export const classNames = (classes: { [key in string]: boolean }) => {
    let className = '';
    
    Object.entries(classes).forEach(([key, value]) => {
        if (value === true) {
            className += `${key} `;
        }
    });

    return className;
}

export const safeJSONparse = <T>(str: string, defValue: any = {}): T => {
    let result = null;

    try {
        result = JSON.parse(str);
    } catch(err) {}

    return result || defValue;
}

export const timeSince = (date: string) => {
    const seconds = Math.floor((new Date().valueOf() - new Date(date).valueOf()) / 1000);
    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }

    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }

    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }

    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }

    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }

    return Math.floor(seconds) + " seconds";
}

export const adjustBodyScrollable = (operation: 'SCROLLABLE' | 'NOT_SCROLLABLE') => {
    if (!document.body) { return; }

    if (operation === 'SCROLLABLE') {
      document.body.classList.remove('not_scrollable');
    } else {
      document.body.classList.add('not_scrollable');
    }
}