import React, {Component} from 'react';
import {
    withStyles, Paper, IconButton, Link, Menu, MenuItem,
} from "@material-ui/core";
import './assets/styles/singlePost.css'
import MoreVert from '@material-ui/icons/MoreVert';
import {Hide, LinkIcon, Bookmark} from './assets/images';
import SinglePostContents from "./SinglePostContents";
import {IComment} from "./CommentsDialog";
import { ACTIVE_LANDING_PAGE_TAB, ACTIVE_LANDING_PAGE_BOXED_VIEW } from "./types";

const classes = {
    root: {
        borderRadius:'8px',
        boxShadow:'0 0 15px 0 rgba(0, 0, 0, 0.1)',
    },
};

export interface IPost {
    id:number,
    name:string,
    picture: string,
    postedTime: string,
    allowedTo: string,
    description?: string,
    interactions: IInteractions,
    isFavorite:boolean,
    link?:IText,
    file?:IFile,
    video?:IVideo,
    images?: IImage[],
    goalPicture?: any,
    goalText?: string,
    percentage?:string,
    dueDate?: string,
    answers?:number,
    question?:{
        title:string,
        subject:string,
        question:string
    }
}

export interface IVideo{
    link?:string,
    image:string,
    source?:string,
    embed?:boolean,
}

export interface IInteractions {
    likes: string,
    comments?: IComment[],
    answers?: IAnswer[],
    shares: string,
}

export interface IAnswer {
    id:number,
    name:string,
    picture:string,
    description:string,
    interactions:IInteractions,
    postedTime:string,
    allowedTo:string
}

export interface IFile {
    image:string,
    text:string
}

export interface IText {
    text:string
}

export interface IImage {
    id:number,
    image:any
}

type MyProps = {
    classes:any,
    item:IPost,
    createPost?:boolean,
    boxedView?: ACTIVE_LANDING_PAGE_BOXED_VIEW,
    answerAction?: "expand" | "dialog",
    hidePost:(postId:number)=>void,
    activeTab: ACTIVE_LANDING_PAGE_TAB,
    handleOpenAnswerView?: (question:IPost)=> void,
    isAnswerView?:boolean,
}
type MyState = {
    isFavorite:boolean,
    anchorElMore: null | HTMLElement,
}

class SinglePost extends Component<MyProps,MyState> {
    constructor(props:any){
        super(props);

        this.state = { isFavorite: props.item.isFavorite, anchorElMore: null }
    }

    handleOpenMore = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState(({anchorElMore:event.currentTarget}))
    };

    handleCloseMore = (actionType:number) => {
        this.setState(({anchorElMore:null}))
        if(actionType===2){
            this.props.hidePost(this.props.item.id)
        }
    };

    render(){
        const { anchorElMore } = this.state;
        const { classes, item, createPost, boxedView, answerAction, activeTab, handleOpenAnswerView, isAnswerView, hidePost } = this.props

        return (
            <div className={`${boxedView === 'BOX' && "boxed-view-container"} ${(boxedView && ['LIST', 'QUESTION'].includes(boxedView)) && "list-view-container"} ${boxedView === 'BADGE' && "badges-container"} ${boxedView === 'QUESTION' && "questions-container"} single-post`}>
                <div className={`${boxedView === 'BOX' && "boxed-view"} ${boxedView === 'LIST' && "list-view"}`}>
                    <Paper className={classes.root}>
                        <div className="single-post-wrapper">
                            <SinglePostContents
                                handleHidePost={hidePost}
                                isAnswerView={isAnswerView}
                                handleOpenAnswerView={handleOpenAnswerView}
                                activeTab={activeTab}
                                isUserInformationActive
                                item={item}
                                isPost
                                createPost={createPost}
                                boxedView={boxedView}
                                answerAction={answerAction}
                            />
                            <IconButton onClick={this.handleOpenMore} className="more-vertical">
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="more-options"
                                anchorEl={anchorElMore}
                                keepMounted
                                open={Boolean(anchorElMore)}
                                onClose={this.handleCloseMore}
                                className="menu-list"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: -38,
                                    horizontal: 130,
                                }}
                            >
                                {
                                    item.video &&
                                    <MenuItem onClick={()=>this.handleCloseMore(0)}>
                                        <img src={Bookmark} alt=""/> Save Video
                                    </MenuItem>
                                }
                                <MenuItem onClick={()=>this.handleCloseMore(1)}>
                                    <img src={LinkIcon} alt=""/> Copy Link
                                </MenuItem>
                                <MenuItem onClick={()=>this.handleCloseMore(2)}>
                                    <img src={Hide} alt=""/> Hide Post
                                </MenuItem>
                            </Menu>
                        </div>
                    </Paper>
                </div>
            </div>
        );
    }
}

export default withStyles(classes)(SinglePost);
