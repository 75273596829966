import React, { Component } from "react";
import { Box, Typography } from '@material-ui/core';
import { classNames } from "./utilities/Utils";
import PostImages from "./PostImages";
import FileListItem from "./FileListItem";


class PostContent extends Component<IProps,IState>{

    constructor(props: any) {
        super(props);
    }

    render(){
        const { id = '', images = [], files = [], isDeletable = true, isHighlighted = true, description = '' } = this.props;

        return(
            <Box
                id={id}
                className={classNames({
                    'post-content': true,
                    '__highlighted': isHighlighted,
                    '__has-images': images.length > 0,
                    '__has-description': !!description,
                    '__has-files': files.length > 0,
                })}
                borderColor={isHighlighted ? "primary.main" : 'none'}
            >
                {images.length > 0 && (
                    <PostImages
                        deletable={isDeletable}
                        images={images}
                        /* onImageRemove={this.onImageRemove}
                        onImageClicked={this.onImageClicked} */
                    />
                )}

                <Typography component="p" className="description">
                    {description}
                </Typography>

                {files.length > 0 && files.map((file:any,index:number) => (
                    <FileListItem
                        handleDeleteFile={this.props.handleDeleteFile}
                        file={file}
                        index={index}
                        isDeletable={isDeletable}
                    />
                ))}
            </Box>  
        )
    }
}

interface IProps {
    id?: string,
    images: string[],
    files: File[],
    description?: string,
    handleDeleteFile?: (index:number) => void,
    isDeletable?: boolean,
    isHighlighted?: boolean
}

interface IState {

}

export default PostContent;