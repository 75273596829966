import React from 'react';

const BadgesIcon:React.FC<{color:string,height?:number,width?:number}> = (props) => {
    const { width = 24, height = 24, color } = props;
    return (
        <svg
            version="1.1" id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={width}
            height={height}
            x="0px"
            y="0px"
            viewBox="0 0 500 500"
            xmlSpace="preserve"
        >
            <g>
                <path fill={color} d="M493.1,342.1c-1.3-3.1-3.2-6.1-5.8-8.6l-79.7-79.2c1.8-3.1,3.9-6,6.1-8.9c9.6-12.3,14.4-27.1,14.4-41.9
                c0-15.1-5-30.3-15-42.7l-4.3-5.3c-6-7.5-9.5-16.7-10-26.4c-0.4-9.4-2.7-18.3-6.4-26.4c-5.6-12.1-14.5-22.3-25.5-29.4
                c-11-7.2-24.2-11.3-38.2-11.3c-0.7,0-1.3,0-2,0l0,0c-0.4,0-0.7,0-1.1,0c-11,0-21.5-4.6-29-12.8c-6.2-6.8-13.6-11.9-21.5-15.3
                c-7.9-3.4-16.5-5.1-25-5.1c-7.6,0-15.1,1.4-22.4,4.1c-7.2,2.7-14,6.9-20.1,12.4l-3.2,3c-6.9,6.3-15.8,9.9-25.1,10.2l-3.5,0.1
                c-9.1,0.3-17.9,2.4-25.8,5.9c-11.9,5.2-22.1,13.7-29.4,24.2c-7.4,10.6-11.9,23.3-12.5,37l-0.2,3.5c-0.4,9.6-3.9,18.9-10,26.4
                l-4.3,5.3c-10,12.5-15.1,27.6-15,42.7c0,14.8,4.8,29.6,14.4,41.9c1.2,1.5,2.3,3.1,3.4,4.7l-83.9,83.3c-2.5,2.5-4.5,5.5-5.8,8.6
                c-1.3,3.1-1.9,6.4-1.9,9.6c0,3.3,0.6,6.6,1.8,9.6c1.8,4.5,4.8,8.6,9,11.6c2.1,1.5,4.4,2.7,7,3.5c2.5,0.8,5.3,1.3,8.1,1.3h56.3v66.5
                c0,3.9,0.8,7.7,2.3,11.1c2.2,5.1,5.9,9.1,10.3,11.8c4.3,2.7,9.4,4.2,14.6,4.2c3.3,0,6.8-0.6,10-2c3.3-1.3,6.4-3.4,9-6l0.1-0.1
                l90.3-91.9c9.5,3.6,19.4,5.4,29.4,5.4c7.9,0,15.9-1.1,23.6-3.4l90,90c2.6,2.6,5.7,4.7,9,6c3.3,1.3,6.7,2,10,2c3.5,0,6.9-0.7,10-1.9
                c4.7-1.9,9-5.1,12.1-9.4c1.6-2.2,2.8-4.6,3.7-7.3c0.9-2.7,1.3-5.5,1.3-8.5v-66.5h56.3c3.7,0,7.3-0.8,10.6-2.2
                c4.8-2.1,8.7-5.7,11.3-9.8c2.6-4.1,4-8.9,4-13.9C495,348.5,494.4,345.2,493.1,342.1z M116.4,446.4c-0.4,0.4-0.8,0.6-1.1,0.8
                c-0.3,0.1-0.6,0.2-1,0.2c-0.4,0-0.8-0.1-1.3-0.2c-0.6-0.3-1.2-0.7-1.5-1.1c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.3-0.2-0.6-0.2-1.1
                v-78.5c0-3.1-1.3-6.2-3.5-8.4c-2.2-2.2-5.3-3.5-8.4-3.5H30.9c-0.5,0-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.8-0.7
                c-0.2-0.4-0.4-0.8-0.4-1.2c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.4,0.5-0.7l78.2-77.7c1.8,5.3,3.1,10.8,3.9,16.5l0,0l0.1,0.4
                c2.2,15.9,10,30,21.2,40.3c11.2,10.3,25.9,16.8,41.9,17.6l0.8,0l0,0c8.5,0.5,16.6,3.6,23.3,8.9l2.1,1.7l7.5-9.2l-7.5,9.2
                c0.4,0.3,0.8,0.6,1.2,0.9L116.4,446.4z M215.8,339.8L215.8,339.8l-2.1-1.7c-10.5-8.5-23.4-13.5-36.9-14.2l-0.7,0l0,0
                c-10.4-0.6-19.9-4.8-27.2-11.4c-7.3-6.7-12.2-15.7-13.7-26.1l0,0l-0.1-0.4c-2.8-20-10.8-39-23.2-55c-6.3-8.1-9.4-17.7-9.4-27.3
                c0-9.9,3.3-19.7,9.8-27.9l4.3-5.3c9.2-11.4,14.5-25.5,15.2-40.2l0.2-3.5c0.3-6.1,1.7-11.8,4.1-17.1c3.5-7.8,9.2-14.6,16.3-19.4
                c7.1-4.8,15.4-7.7,24.6-8l3.5-0.1c14.9-0.5,29.2-6.3,40.2-16.4l3.2-3c3.8-3.5,8-6,12.5-7.7c4.5-1.7,9.2-2.5,13.9-2.5
                c5.3,0,10.6,1.1,15.6,3.2c4.9,2.1,9.5,5.3,13.4,9.6c11.9,13.1,28.8,20.5,46.5,20.5c0.6,0,1.2,0,1.7,0l0,0c0.4,0,0.9,0,1.3,0
                c6.2,0,12.1,1.2,17.6,3.4c8.1,3.3,15.2,8.9,20.3,16c5.1,7.1,8.2,15.6,8.7,25c0.7,14.7,6,28.7,15.2,40.2l4.3,5.3
                c6.5,8.2,9.8,18,9.8,27.9c0,9.7-3.1,19.3-9.4,27.3c-4.7,6-8.7,12.4-12.1,19.2c-0.5,0.8-0.9,1.6-1.2,2.5
                c-4.9,10.5-8.3,21.7-9.9,33.3c0,0,0,0,0,0c-1.5,10.4-6.5,19.5-13.9,26.2c-7.4,6.7-16.9,10.8-27.4,11.3
                c-13.9,0.6-27.2,5.7-37.9,14.5l-1.9,1.6c-4.4,3.6-9.2,6.5-14.2,8.6c-0.7,0.2-1.5,0.5-2.2,0.9c-0.5,0.2-1,0.4-1.5,0.6
                c-6.4,2.2-13,3.4-19.7,3.4C239.9,352.9,226.7,348.6,215.8,339.8z M471,352.5c-0.2,0.4-0.4,0.8-0.7,0.9c-0.1,0.1-0.2,0.2-0.4,0.2
                c-0.2,0.1-0.4,0.1-0.7,0.1h-68.2c-3.1,0-6.2,1.3-8.4,3.5s-3.5,5.3-3.5,8.4v78.5c0,0.6-0.1,1-0.3,1.4c-0.2,0.5-0.6,0.9-1.2,1.3
                c-0.6,0.4-1.3,0.6-1.9,0.6c-0.4,0-0.7-0.1-1-0.2c-0.3-0.1-0.7-0.3-1.1-0.8l-84-84c2.1-1.4,4.2-3,6.1-4.6l1.9-1.6
                c6.8-5.6,15.2-8.8,23.9-9.2c16.2-0.7,31-7.2,42.3-17.4c11.3-10.3,19.1-24.4,21.4-40.5c0,0,0,0,0,0c0.5-3.8,1.3-7.5,2.3-11.2
                l73,72.5c0.3,0.3,0.4,0.5,0.5,0.7c0.1,0.2,0.1,0.4,0.1,0.6C471.1,351.9,471.1,352.2,471,352.5z"/>
                <path fill={color} d="M293.7,276.9c6.2-2.6,11.5-6.8,15.4-12.3c1.9-2.7,3.5-5.8,4.6-9.1c1.1-3.3,1.7-6.9,1.7-10.6
                c0-3.6-0.6-7.3-1.8-11c0,0,0,0,0-0.1c-0.6-1.8-0.8-3.5-0.8-5.3c0-2.7,0.6-5.4,1.8-7.7c1.2-2.4,3-4.5,5.2-6.2
                c4.8-3.5,8.5-7.9,10.9-12.8c2.4-4.9,3.5-10.1,3.5-15.2c0-4.4-0.8-8.8-2.4-12.9c-2.4-6.1-6.4-11.6-12-15.7c-2.8-2-6-3.7-9.4-4.8
                c-3.5-1.1-7.2-1.7-11-1.7c-3.7,0-7.2-1.2-10.1-3.3c-2.9-2.1-5.1-5-6.2-8.6l-11.4,3.7l11.4-3.7c-1.2-3.8-3.1-7.4-5.4-10.4
                c-3.4-4.6-7.9-8-12.6-10.3c-4.8-2.3-10-3.4-15.1-3.4c-6.9,0-13.8,2-19.8,6c-3,2-5.7,4.6-8,7.6c-2.3,3-4.1,6.6-5.4,10.4
                c-1.2,3.5-3.4,6.5-6.2,8.6c-2.9,2.1-6.4,3.3-10.1,3.3c-5.1,0-10.1,1.1-14.4,3c-3.3,1.5-6.2,3.4-8.7,5.7c-3.8,3.4-6.7,7.5-8.7,12
                c-2,4.5-3,9.4-3,14.4c0,5.1,1.1,10.3,3.5,15.2c2.4,4.9,6.1,9.3,10.9,12.8c2.3,1.7,4,3.8,5.2,6.2c1.2,2.4,1.8,5,1.8,7.7
                c0,1.8-0.3,3.5-0.8,5.3c0,0,0,0,0,0.1c-1.2,3.6-1.8,7.4-1.8,11c0,4.9,1.1,9.6,2.9,13.8c2.8,6.3,7.4,11.5,12.9,15.1
                c5.5,3.6,12.1,5.8,19.1,5.8c3.5,0,7-0.5,10.5-1.7c3.5-1.1,6.8-2.8,9.9-5.1c3-2.2,6.5-3.3,10.1-3.3c3.6,0,7.1,1.1,10.1,3.3
                c3.1,2.3,6.5,4,9.9,5.1c3.5,1.1,7,1.7,10.5,1.7C285,279.5,289.5,278.6,293.7,276.9z M274,253.5c-7.2-5.2-15.7-7.8-24.1-7.8
                c-8.5,0-16.9,2.6-24.1,7.8c-1.2,0.8-2.2,1.4-3.3,1.7c-1,0.3-2.1,0.5-3.1,0.5c-1.4,0-2.8-0.3-4.1-0.8c-2-0.8-3.9-2.3-5.1-4.1
                c-0.6-0.9-1.1-1.8-1.4-2.8c-0.3-1-0.5-2-0.5-3.1c0-1.1,0.2-2.3,0.6-3.6c0,0,0-0.1,0-0.1c1.3-4.1,2-8.4,2-12.6
                c0-6.5-1.5-12.8-4.4-18.5c-2.9-5.7-7.1-10.8-12.5-14.7c-1.8-1.3-2.8-2.6-3.5-4c-0.7-1.4-1-3-1-4.6c0-1.4,0.3-2.9,0.8-4.2
                c0.8-2.1,2.1-3.8,3.8-5c0.8-0.6,1.7-1.1,2.8-1.4c1-0.3,2.2-0.5,3.7-0.5c8.9,0,17.3-2.9,24.1-7.8c6.8-5,12.2-12.1,14.9-20.5
                c0.5-1.4,1.1-2.5,1.7-3.4c1-1.4,2.3-2.3,3.8-3.1c1.5-0.7,3.2-1.1,4.9-1.1c2.3,0,4.6,0.7,6.3,1.9c0.9,0.6,1.7,1.3,2.4,2.2
                c0.7,0.9,1.3,2,1.7,3.4l0,0c2.7,8.4,8.1,15.6,14.9,20.5c6.8,5,15.3,7.8,24.1,7.8c1.9,0,3.4,0.4,4.7,0.9c1,0.4,1.8,1,2.6,1.7
                c1.1,1,2.1,2.3,2.8,3.8c0.7,1.5,1,3.2,1,4.7c0,1.6-0.3,3.2-1,4.6c-0.7,1.4-1.7,2.7-3.5,4c-5.4,3.9-9.7,9-12.5,14.7
                c-2.9,5.7-4.4,12-4.4,18.5c0,4.2,0.6,8.4,2,12.6c0,0,0,0.1,0,0.1c0.4,1.4,0.6,2.6,0.6,3.6c0,1.5-0.3,2.8-0.9,4.1
                c-0.8,1.9-2.3,3.6-4.2,4.9c-1.9,1.2-4,1.9-6,1.9c-1,0-2-0.2-3.1-0.5C276.3,254.8,275.2,254.3,274,253.5z"/>
                <polygon fill={color} points="111.7,289.6 111.7,289.6 111.7,289.6 	"/>
                <polygon fill={color} points="174.7,347.5 174.7,347.5 174.7,347.5 	"/>
                <polygon fill={color} points="135.1,285.9 135.1,285.9 135.1,285.9 	"/>
                <polygon fill={color} points="176.8,323.9 176.8,323.9 176.8,323.9 	"/>
            </g>
        </svg>
    );
}

export default BadgesIcon;
