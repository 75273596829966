import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import './assets/styles/PageHeader.css';
import {ACTIVE_LANDING_PAGE_TAB} from "./types";

class PageHeader extends React.Component<React.PropsWithChildren<IProps>, IState> {
  render() {
    const { pageTitle, isGoBack = true, children, navigation, handleChangeShortcutsView } = this.props;

    return (
      <Box id="page-header">
        <div className="left-side">
          <Button
            onClick={()=>pageTitle === "Shortcuts" ? handleChangeShortcutsView && handleChangeShortcutsView("TIMELINE") : navigation.goBack()}
            className="page-header-button"
            variant="text"
            startIcon={isGoBack && <KeyboardBackspace className="go-back-icon" />}
          >
            <Typography className="page-header-button-text" component="span">{pageTitle}</Typography>
          </Button>
        </div>

        {children && (
          <div className="right-side">
            {children}
          </div>
        )}
      </Box>
    )
  }
}

interface IProps {
  pageTitle: string;
  isGoBack?: boolean;
  navigation?: any;
  handleChangeShortcutsView?: (newValue:ACTIVE_LANDING_PAGE_TAB)=> void;
}

interface IState {}

export default PageHeader;
