import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {IAnswer, IPost} from "../../../components/src/SinglePost";
import { ITab } from "../../../components/src/TabMenu";
import { IStory } from "../../../components/src/Stories";
import { INotificationItem } from "../../../components/src/NotificationsWidget.web";
import { IStudentGoalItem } from "../../../components/src/OtherStudentGoalsWidget.web";
import { IContactWidgetItem } from "../../../components/src/ContactsWidget.web";
import { IEventWidgetItem } from "../../../components/src/EventsWidget.web";
import { IComment } from "../../../components/src/CommentsDialog";
import { IProps as IDiscussionFilterByProps } from "../../../components/src/DiscussionFilterBy";
import { ACTIVE_LANDING_PAGE_TAB, ACTIVE_LANDING_PAGE_BOXED_VIEW, IUser, IProfileWidgetData, TProfileType } from "../../../components/src/types";
import { INewsData } from "../../../components/src/NewsWidget";
import { IChatWidgetItem } from "../../../components/src/ChatWidget";
import {INotificationMenuListItem} from "../../../components/src/NotificationMenuList";
import { getProfileType, getProfileWidgetData, getToken } from "../../../components/src/utilities/StorageHelper";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab: ACTIVE_LANDING_PAGE_TAB;
  isOpenFileModalOpen: boolean;
  isPortfolioDialogOpen: boolean;
  tabs: ITab[];
  stories: IStory[];
  posts: IPost[];
  studentGoals: IPost[];
  videos: IPost[];
  files: IPost[];
  comments: IComment[];
  studentBadges: IPost[];
  questions: IPost[];
  studentAnswers: IAnswer[] | undefined;
  news:INewsData[];
  blogs:INewsData[];
  chatData:IChatWidgetItem[];
  profileNotifications: INotificationItem[];
  profileStudentGoals: IStudentGoalItem[];
  profileContactUsers: IContactWidgetItem[];
  profileEventItems: IEventWidgetItem[];
  user: IUser;
  profileWidgetData: IProfileWidgetData;
  userType: "TEACHER" | "STUDENT";
  profileType: TProfileType,
  profileOtherUserPictures: string[];
  boxedView: ACTIVE_LANDING_PAGE_BOXED_VIEW;
  isAnswerView: boolean;
  isStoryCreateModalOpen: boolean;
  anchorElFilter: IDiscussionFilterByProps['anchorElFilter'];
  sortedBy: IDiscussionFilterByProps['sortedBy'];
  anchorElWidgetMenu: null | HTMLElement;
  subjects: string[];
  uploadedFiles: File[];
  showFiles: boolean;
  widgets:string[];
  activeWidgets:string[];
  checkedWidgets:string[];
  notificationMenuList:INotificationMenuListItem[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.removeAllNotificationItems = this.removeAllNotificationItems.bind(
      this
    );
    this.removeNotificationItem = this.removeNotificationItem.bind(this);
    this.removeEventItem = this.removeEventItem.bind(this);
    this.toggleStoryCreateModalVisibility = this.toggleStoryCreateModalVisibility.bind(
      this
    );

    this.subScribedMessages = [
      getName(MessageEnum.ProfileWidgetData),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      activeTab: 'TIMELINE',
      isOpenFileModalOpen: false,
      isPortfolioDialogOpen: false,
      tabs: configJSON.tabs,
      stories: configJSON.stories,
      posts: configJSON.posts,
      studentGoals: configJSON.studentGoals,
      videos: configJSON.videos,
      files: configJSON.files,
      comments: configJSON.comments,
      studentBadges: configJSON.studentBadges,
      questions: configJSON.questions,
      studentAnswers: [],
      user: {} as S['user'],
      profileWidgetData: {} as S['profileWidgetData'],
      profileType: 'myProfile',
      news:configJSON.news,
      blogs:configJSON.news,
      chatData:configJSON.chatData,
      profileNotifications: configJSON.profileNotifications,
      profileStudentGoals: configJSON.profileStudentGoals,
      profileContactUsers: configJSON.profileContactUsers,
      profileEventItems: configJSON.profileEventItems,
      userType: configJSON.userType,
      profileOtherUserPictures: configJSON.profileOtherUserPictures,
      boxedView: 'NONE',
      isAnswerView: false,
      isStoryCreateModalOpen: false,
      anchorElFilter: null,
      anchorElWidgetMenu: null,
      sortedBy: "",
      subjects: [],
      uploadedFiles: [],
      showFiles: false,
      widgets:configJSON.widgets,
      activeWidgets:["Profile","Goals","Events","Notifications","Contacts","Other(Students)","Other(Teachers)"],
      checkedWidgets:["Profile","Goals","Events","Notifications","Contacts","Other(Students)","Other(Teachers)"],
      notificationMenuList:configJSON.notificationMenuList
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.receiveUserResponse(from,message);
    this.receivePostsResponse(from,message);
    // Customizable Area End
  }

  // Customizable Area Start

  //couldnt find customizable area top of the block
  apiProfileWidgetUniqueCallId:any;
  apiUserPostsUniqueCallId:any;

  async componentDidMount() {
    super.componentDidMount();

    setTimeout(() => {      
      if (!this.state.user.fullName) { this.fetchUser(); }
      this.getPosts();

      if(typeof window !== 'undefined'){
        const state: any = {};

        const profileWidgetData = getProfileWidgetData();
        const profileType = getProfileType();

        if (profileWidgetData && (this.state.profileWidgetData && !this.state.profileWidgetData.fullName)) {
          state.profileWidgetData = profileWidgetData
        }

        state.profileType = profileWidgetData ? profileType : 'myProfile';
        this.setState(state);
      }
    }, 0);
  }

  getData () {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    this.send(msg);
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  receiveUserResponse = (from: string, message: Message) => {
    const responseName = getName(MessageEnum.RestAPIResponceMessage)
    const responseDataMessage = getName(MessageEnum.RestAPIResponceDataMessage);
    const isRestApiCallback = responseName === message.id;
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    const isFetchUserResponse = (
      isRestApiCallback
      && this.apiProfileWidgetUniqueCallId != null
      && this.apiProfileWidgetUniqueCallId === message.getData(responseDataMessage)
    );

    if (isFetchUserResponse) {
      if (responseJson && responseJson.data) {
        this.setState({ user: responseJson.data });
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else {
        this.parseApiErrorResponse(responseJson);
      }
    } else if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const userData = message.getData(getName(MessageEnum.UserData));
      const profileWidgetData = message.getData(getName(MessageEnum.ProfileWidgetData));
      
      interface IDataType { user: S['user'], profileWidgetData: S['profileWidgetData'] }
      const data: IDataType = {} as IDataType;

      if (userData) {
        data.user = userData;

        if (!profileWidgetData && localStorage.getItem('profileType') === 'myProfile') {
          data.profileWidgetData = {
            id: '11', //data.user.id
            fullName: data.user.fullName,
            profilePicture: data.user.profilePicture,
            title: data.user.className,
            biography: data.user.biography,
            isVerifiedUser: data.user.isVerified,
            postCount: 0, //data.user.postCount
            filesCount: data.user.filesCount,
            imageCount: data.user.imagesCount,
            goalsCount: data.user.goalsCount,
            videosCount: data.user.videosCount
          }
        }
      }
      if (profileWidgetData) { data.profileWidgetData = profileWidgetData; }

      this.setState(data);
    }
  }

  fetchUser = async () => {
    const { user } = this.state;
    const token = await getToken();

    // If tokens exist and firstname not exist
    if (token && !user.fullName) {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.apiProfileWidgetUniqueCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getUserDataApi.baseEndpoint}/${configJSON.getUserDataApi.endpointMethod}?token=${token}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(configJSON.getUserDataApi.header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getUserDataApi.apiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  receivePostsResponse = (from: string, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      let isResponseProper:boolean = responseJson != null && !responseJson.errors

      if (apiRequestCallId === this.apiUserPostsUniqueCallId && isResponseProper) {
        this.setState({
          posts:responseJson.data.attributes
        });
        console.log(responseJson.data + "cevap")
        this.parseApiCatchErrorResponse(errorReponse);
      }
      else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

  getPosts = () => {
    const header = {
      "Content-Type": configJSON.contentType,
      "Access-Control-Allow-Origin" : "*",
      "Access-Control-Allow-Credentials": true
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiUserPostsUniqueCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userPostsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCreatePost = (files:any,images:string[],text:string) => {
    //console.log(files,images,text)
  }

  handleChangeShortcutsView = () => {
    // this.setState({
    //   activeTab: 'SHORTCUTS',
    //   isAnswerView: false,
    //   questions: configJSON.questions
    // })
  }

  handleTabChange = (newValue: S['activeTab']) => {
    this.setState({
      activeTab: newValue,
      isAnswerView: false,
      questions: configJSON.questions
    });

    if (newValue === 'FILES') {
      this.setState({ boxedView: 'BOX' });
      return;
    } else if (newValue === 'BADGES') {
      this.setState({ boxedView: 'BADGE' });
      return;
    } else if (newValue === 'DISCUSSION') {
      this.setState({ boxedView: 'QUESTION' });
      return;
    }
    this.setState({ boxedView: 'NONE' });
  };

  handleBoxViewChange = (value: S['boxedView']) => {
    this.setState({ boxedView: value });
  };

  handleHidePost = (postId: number) => {
    if (this.state.activeTab === 'TIMELINE') {
      this.setState(state => ({
        posts: state.posts.filter(item => item.id !== postId)
      }));
    }
    if (this.state.activeTab === 'GOALS') {
      this.setState(state => ({
        studentGoals: state.studentGoals.filter(item => item.id !== postId)
      }));
    }
    if (this.state.activeTab === 'VIDEOS') {
      this.setState(state => ({
        videos: state.videos.filter(item => item.id !== postId)
      }));
    }
    if (this.state.activeTab === 'FILES') {
      this.setState(state => ({
        files: state.files.filter(item => item.id !== postId)
      }));
    }
    if (this.state.activeTab === 'BADGES') {
      this.setState(state => ({
        studentBadges: state.studentBadges.filter(item => item.id !== postId)
      }));
    }
    if (this.state.activeTab === 'DISCUSSION') {
      this.setState(state => ({
        questions: state.questions.filter(item => item.id !== postId)
      }));
    }
  };

  handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorElFilter: event.currentTarget });
  };

  handleCloseFilter = () => {
    this.setState({ anchorElFilter: null });
  };

  handleClearFilter = () => {
    this.setState({ sortedBy: "" });
    this.setState({ subjects: [] });
  };

  handleSortedByChange = (val: unknown) => {
    this.setState({ sortedBy: val as string });
  };

  handleSubjectsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    //this.setState({ subjects: event.target.value as string[] });
  };

  handleCancelFilter = () => {
    this.handleClearFilter();
    this.setState({ anchorElFilter: null });
  };

  handleOpenWidgetMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorElWidgetMenu:event.currentTarget})
  }

  handleCloseWidgetMenu = () => {
    this.setState({anchorElWidgetMenu:null})
  }

  handleCheckWidgets = (text:string, check:boolean) => {
    if(this.state.checkedWidgets.indexOf(text) > -1){
        this.setState({checkedWidgets:this.state.checkedWidgets.filter((item:string)=> item !== text)})
    }
    else
    {
        this.setState({checkedWidgets: [...this.state.checkedWidgets,text]})
    }
  }

  handleSaveWidgetSettings = () => {
    this.setState({activeWidgets: this.state.checkedWidgets});
    this.handleCloseWidgetMenu();
  }

  handleCancelWidgetSettings = () => {
    this.setState({checkedWidgets: this.state.activeWidgets});
    this.handleCloseWidgetMenu();
  }

  handleCloseAddFileDialog = () => {
    this.setState({ isOpenFileModalOpen: false });
  };

  handleOpenAddFileDialog = () => {
    this.setState({ isOpenFileModalOpen: true });
  };

  handleOpenPortfolioDialog = () => {
    this.setState({ isPortfolioDialogOpen: true });
  };

  handleClosePortfolioDialog = () => {
    this.setState({ isPortfolioDialogOpen: false });
  };

  handleOpenAnswerView = (question: IPost) => {
    this.setState({
      isAnswerView: true,
      questions: [question],
      studentAnswers: question.interactions.answers
    });
  };

  handleCloseAnswerView = () => {
    this.setState({
      isAnswerView: false,
      questions: configJSON.questions,
      studentAnswers: []
    });
  };

  handleUploadFiles = (files: File[]) => {
    this.setState({ showFiles: true, isOpenFileModalOpen: false, uploadedFiles: files });
  };

  removeNotificationItem(notification: INotificationItem) {
    this.setState(prevState => {
      const notifications = prevState.profileNotifications.filter(
        item => item.id !== notification.id
      );
      return { ...prevState, profileNotifications: notifications };
    });
  }

  removeAllNotificationItems() {
    this.setState({ profileNotifications: [] });
  }

  removeEventItem(event: IEventWidgetItem) {
    this.setState(prevState => {
      const events = prevState.profileEventItems.filter(
        item => item.id !== event.id
      );
      return { ...prevState, profileEventItems: events };
    });
  }

  toggleStoryCreateModalVisibility() {
    this.setState(prevState => ({
      ...prevState,
      isStoryCreateModalOpen: !prevState.isStoryCreateModalOpen
    }));
  }
  // Customizable Area End
}
