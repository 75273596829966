import React from 'react';

const LogoutIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 28, height = 28, color } = props;

    return(
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox={`0 0 500 500`}
            width={width}
            height={height}
            xmlSpace="preserve"
        >
            <g>
                <path style={{ fill: color }} d="M212.08,459.15l-132.42,0c-5.69,0-11.03-1.14-15.92-3.21c-7.32-3.09-13.6-8.3-18.01-14.84
                    c-2.21-3.27-3.95-6.86-5.14-10.69c-1.19-3.83-1.84-7.9-1.84-12.17V81.76c0-5.69,1.14-11.03,3.21-15.92
                    c3.09-7.32,8.3-13.6,14.84-18.01c3.27-2.21,6.86-3.95,10.69-5.14c3.83-1.19,7.9-1.84,12.17-1.84h132.42
                    c9.32,0,16.88-7.56,16.88-16.88c0-9.32-7.56-16.88-16.88-16.88H79.66c-10.26,0-20.13,2.09-29.07,5.87
                    C37.17,18.65,25.81,28.09,17.76,40c-4.02,5.95-7.22,12.53-9.4,19.56C6.17,66.59,5,74.06,5,81.76v336.49
                    c0,10.26,2.09,20.13,5.87,29.07c5.68,13.42,15.12,24.79,27.03,32.83c5.95,4.02,12.53,7.22,19.56,9.4c7.03,2.19,14.5,3.36,22.2,3.36
                    h132.42c9.32,0,16.88-7.56,16.88-16.88C228.96,466.7,221.4,459.15,212.08,459.15z"/>
                <path style={{ fill: color }} d="M490.28,232.8L378.21,116.3c-6.46-6.72-17.15-6.92-23.87-0.46c-6.72,6.46-6.92,17.15-0.46,23.87h0l84.06,87.37
                    h-313.7c-9.62,0-17.42,7.8-17.42,17.42c0,9.62,7.8,17.42,17.42,17.42h313.28l-83.56,85.59c-6.51,6.67-6.38,17.36,0.29,23.87
                    c6.67,6.51,17.36,6.38,23.87-0.29l112.07-114.8C496.57,249.77,496.6,239.37,490.28,232.8z"/>
            </g>
        </svg>
    )
}

export default LogoutIcon;
