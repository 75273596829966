import React, {Component} from 'react';
import {Button} from "@material-ui/core";
import "./assets/styles/addFileDialog.css"
import DialogWrapper from "./DialogWrapper";
import Dropzone from "react-dropzone";
import FileListItem, { IFile } from './FileListItem';

type MyProps = {
    isOpen?:boolean,
    handleCloseModal?: () => void;
    useWrapper?: boolean;
    handleUploadFiles: (files: File[])=>void
}

type MyState = {
    files: IFile[]
}

class AddFileDialog extends Component<MyProps, MyState> {
    private intervalRef: any;
    constructor(props: MyProps) {
        super(props);

        this.onFilesUpload = this.onFilesUpload.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);

        this.state = { files: [] }
    }

    componentWillUnmount() {
        clearInterval(this.intervalRef);
    }

    onFilesUpload(files: File[]) {
        this.setState({
            files: files.map((file): IFile => {
                // @ts-ignore
                const fileItem: IFile = file;
                fileItem.uploadedAt = Date.now();
                return fileItem;
            })
        }, () => {
            clearInterval(this.intervalRef);

            this.intervalRef = setInterval(() => {
                this.forceUpdate()
            }, 61000)
        })
    }

    handleUpload() {
        const files = this.state.files as File[];
        this.props.handleUploadFiles(files);
    }

    handleDeleteFile(index: number, callback?: () => void) {
        const files = [...this.state.files];
        files.splice(index, 1);
        this.setState({ files }, callback);
    }
    
    render() {
        const { isOpen = false, handleCloseModal, useWrapper = true} = this.props

        const ContentDOM = (
            <div className="add-file-dialog-body">
                <h3 className="add-file-dialog-title">Upload File</h3>
                <Dropzone onDrop={this.onFilesUpload}>
                    {({getRootProps, getInputProps}:any) => (
                        <div {...getRootProps({className: 'dropzone add-file-dropzone-container'})}>
                            <input {...getInputProps()} />
                            <Button className="add-file-button" variant="contained" color="primary">Add File</Button>
                            <p>Drag and drop files here or browse them from your computer.</p>
                        </div>
                    )}
                </Dropzone>
                {this.state.files.length > 0 && (
                    <div className="file-list">
                        {this.state.files.map((file,index:number) => (
                            <FileListItem
                                key={`${index}_${file.name}`}
                                index={index}
                                file={file}
                                handleDeleteFile={this.handleDeleteFile}
                            />
                        ))}
                    </div>
                )}
                <div className="add-file-upload-buttons">
                    <Button data-test-id="upload-button" onClick={this.handleUpload} className="upload-file-button" variant="contained" color="primary">Upload</Button>
                    <Button onClick={handleCloseModal} className="cancel-file-button" color="primary" variant="outlined">Cancel</Button>
                </div>
            </div>
        );

        if (useWrapper === true) {
            return (
                <DialogWrapper
                    isOpen={isOpen}
                    handleCloseModal={handleCloseModal!}
                >
                    {ContentDOM}
                </DialogWrapper>
            )
        }

        return ContentDOM;
    }
}

export default AddFileDialog;
