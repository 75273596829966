import React, {Component} from 'react';
import './assets/styles/postInteractions.css'
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import {Comment, Edit} from "./assets/images";
import {Grid, IconButton, isWidthDown, Menu, MenuItem, withWidth} from "@material-ui/core";
import { withTheme, Theme } from "@material-ui/core/styles";
import ShareOutlined from "@material-ui/icons/ShareOutlined";
import {IInteractions, IPost} from "./SinglePost";
import CommentsDialog from "./CommentsDialog";
import AddAnswerDialog from "./AddAnswerDialog";
import SendInChatDialog from "./SendInChatDialog";
import { ACTIVE_LANDING_PAGE_TAB, ACTIVE_LANDING_PAGE_BOXED_VIEW } from "./types"
import CreateContentWidget from './CreateContentWidget.web';

type MyProps = {
    item:IPost,
    isFavorite: boolean,
    interactions: IInteractions,
    boxedView?: ACTIVE_LANDING_PAGE_BOXED_VIEW,
    answerAction?: "expand" | "dialog",
    question?:string,
    activeTab: ACTIVE_LANDING_PAGE_TAB,
    handleToggleShowComments: () => void
    handleToggleShowReplies: () => void,
    theme: Theme,
    width: Parameters<Theme['breakpoints']['only']>[0],
}
type MyState = {
    isFavorite:boolean,
    isCommentsDialogOpen: boolean,
    isAddAnswerDialogOpen: boolean,
    isSendInChatDialogOpen: boolean,
    isShareToFeedDialogOpen: boolean,
    anchorElShare: null | HTMLElement
}

class PostInteractions extends Component<MyProps,MyState> {
    private createContentWidgetRef = React.createRef<CreateContentWidget>();

    constructor(props:any){
        super(props);
        this.state = {
            isFavorite:props.isFavorite,
            isCommentsDialogOpen: false,
            isAddAnswerDialogOpen: false,
            isSendInChatDialogOpen: false,
            isShareToFeedDialogOpen: false,
            anchorElShare: null
        }
    }

    handleFavorite = () => {
        this.setState((state) => ({isFavorite : !state.isFavorite}))
    }

    handleOpenShareOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState(({anchorElShare:event.currentTarget}))
    };

    handleCloseShareOptions = (callback?: () => void) => {
        this.setState({anchorElShare:null}, () => {
            if (typeof callback === 'function') { callback(); }
        })
    };

    handleOpenCommentsDialog = () => {
        const { interactions } = this.props;

        if(interactions && interactions.comments && interactions.comments.length > 0){
            this.setState(({isCommentsDialogOpen:true}))
        }
    };

    handleCloseCommentsDialog = () => {
        this.setState(({isCommentsDialogOpen:false}))
    };

    handleOpenAddAnswerDialog = () => {
        this.setState(({isAddAnswerDialogOpen:true}))
    };

    handleCloseAddAnswerDialog = () => {
        this.setState(({isAddAnswerDialogOpen:false}))
    };

    handleOpenSendInChatDialog = () => {
        this.setState(({isSendInChatDialogOpen:true}))
        this.handleCloseShareOptions()
    };

    handleCloseSendInChatDialog = () => {
        this.setState(({isSendInChatDialogOpen:false}))
    };

    handleOpenShareToFeedDialog = () => {
        this.handleCloseShareOptions(() => {
            this.createContentWidgetRef.current?.openDialog('CREATE_CONTENT');
        });
    };

    handleCloseShareToFeedDialog = () => {
        this.setState(({isShareToFeedDialogOpen:false}))
    };

    handleOpenComments = () => {
        if(this.props.answerAction === "dialog"){
            this.handleOpenAddAnswerDialog()
        }
        else{
            if((['FILES','BADGES'].includes(this.props.activeTab))){
                this.handleOpenCommentsDialog()
            }
            else{
                if(this.props.activeTab === "DISCUSSION"){
                    this.props.handleToggleShowReplies()
                }
                else{
                    this.props.handleToggleShowComments()
                }
            }
        }
    }

    render(){
        const isMobileView = isWidthDown('sm', this.props.width, true)
        const { isFavorite, isCommentsDialogOpen, isAddAnswerDialogOpen, isSendInChatDialogOpen, isShareToFeedDialogOpen, anchorElShare } = this.state
        const { interactions, boxedView, answerAction, question, item, activeTab, handleToggleShowComments, handleToggleShowReplies, theme } = this.props

        const showText = boxedView !== 'BOX' || isMobileView

        return (
            <Grid className="post-interactions" container item direction="row" wrap="nowrap">
                <div onClick={this.handleFavorite}>
                    {
                        isFavorite ? <Favorite htmlColor={theme.palette.primary.main}/> :
                        <FavoriteBorder htmlColor="#3f526d"/>
                    }
                    <span style={{color:`${isFavorite ? theme.palette.primary.main : "#3f526d"}`}}>{interactions.likes} {showText && "Likes"}</span>
                </div>
                <div onClick={this.handleOpenComments}>
                    <img src={Comment} alt=""/>
                    <span>{interactions?.comments?.length} {showText && (answerAction ? "Answer" : "Comments")}</span>
                </div>
                <IconButton className="share-button" disableRipple onClick={this.handleOpenShareOptions}>
                    <ShareOutlined htmlColor="#3f526d"/>
                    <span>{interactions.shares} {showText && "Share"}</span>
                </IconButton>
                <Menu
                    id="share-options"
                    anchorEl={anchorElShare}
                    keepMounted
                    open={Boolean(anchorElShare)}
                    onClose={() => this.handleCloseShareOptions()}
                    className="menu-list"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 90,
                        horizontal: boxedView === 'BOX' ? 40 : 80,
                    }}

                >
                    <MenuItem onClick={this.handleOpenSendInChatDialog}>
                        <img src={Comment} alt=""/> Send in Chat
                    </MenuItem>
                    <MenuItem onClick={this.handleOpenShareToFeedDialog}>
                        <img src={Edit} alt=""/> Share to Feed
                    </MenuItem>
                </Menu>
                <CommentsDialog isOpen={isCommentsDialogOpen} handleCloseModal={this.handleCloseCommentsDialog}/>
                <AddAnswerDialog question={question} isOpen={isAddAnswerDialogOpen} handleCloseModal={this.handleCloseAddAnswerDialog}/>
                <SendInChatDialog item={item} isOpen={isSendInChatDialogOpen} handleCloseModal={this.handleCloseSendInChatDialog} />
                <CreateContentWidget
                    ref={this.createContentWidgetRef}
                    title="Write Post"
                    actionButtonTitle="Post"
                    dialogTitle="Write Post"
                    inputPlaceholder="What's new, Albert?"
                    isMultipleImageSelection
                    displayPostSection={false}
                    sharedPostData={{
                        files: [],
                        images: (item.images || []).map((item) => item.image),
                        description: item.description!
                    }}
                    handleCreatePost={()=>{}}
                />
                {/* <ShareToFeedDialog item={item} isOpen={isShareToFeedDialogOpen} handleCloseModal={this.handleCloseShareToFeedDialog}/>*/}
            </Grid>
        );
    }
}

export default withTheme(withWidth()(PostInteractions));
