import React, {Component, ReactElement} from "react";
import "./assets/styles/bottomMenu.css"
import {
    MenuBadgesWhiteIcon,
    MenuPostIcon,
} from "./assets/images";
import {Menu, MenuItem} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import { withTheme, Theme } from "@material-ui/core/styles";
import {ACTIVE_LANDING_PAGE_TAB} from "./types";
import {
    VideosIcon,
    FilesIcon,
    BadgesIcon,
    StoriesIcon,
    ShortCutsIcon,
} from "./assets/svgs";
import AddFileDialog from "./AddFileDialog";
import {ISharedPostData} from "./CreateContentWidget.web";
import BottomMenuDialogs from "./BottomMenuDialogs";
import MenuList from "./MenuList";

const moreList:IMoreList[] = [
    {
        id:1,
        tab:"VIDEOS",
        text:"Videos",
        icon:<VideosIcon height={26} width={26} color="currentColor" />
    },
    {
        id:2,
        tab:"FILES",
        text:"Files",
        icon:<FilesIcon height={26} width={26} color="currentColor" />
    },
    {
        id:3,
        tab:"BADGES",
        text:"Badges",
        icon:<BadgesIcon height={26} width={26} color="currentColor" />
    },
    {
        id:4,
        tab:"STORIES",
        text:"Stories",
        icon:<StoriesIcon height={26} width={26} color="currentColor" />,
    },
    {
        id:5,
        tab:"SHORTCUTS",
        text:"Shortcuts",
        icon:<ShortCutsIcon height={26} width={26} color="currentColor" />
    }
]

type IView = 'CREATE_CONTENT' | 'IMAGE_OPTIONS' | 'UPLOAD_FILE' | 'ADD_STORY'

interface IMoreList{
    id:number,tab:ACTIVE_LANDING_PAGE_TAB | "STORIES" | "SHORTCUTS",icon:ReactElement,text:string
}

type MyProps = {
    theme:Theme;
    activeTab?: ACTIVE_LANDING_PAGE_TAB;
    navigation:any;
    handleTabChange?:(newValue:ACTIVE_LANDING_PAGE_TAB)=>void;
    handleCreatePost?:(files:any,images:string[],text:string)=>void;
    sharedPostData?: ISharedPostData;
    actionButtonTitle?: string;
    handleChangeShortcutsView?:()=>void;
}
type MyState = {
    anchorElMenu:null | HTMLElement;
    menuType:string | null;
    isDialogOpen: boolean;
    isInnerDialog?: boolean;
    view?: IView;
    isFileUploadDialogOpen: boolean;
    postFiles: File[],
    badgeFiles: File[],
    showFiles: boolean;
    postImages: string[];
    badgeImages: string[];
    postDescription:string;
    title: string;
    isStoryImageSelected: boolean;
    storyImages: string[];
}
class BottomMenu extends Component<MyProps, MyState>{
    private addFileDialogRef = React.createRef<AddFileDialog>();
    constructor(props:any) {
        super(props);

        this.onImagesSelected = this.onImagesSelected.bind(this);
        this.handleFilesUpload = this.handleFilesUpload.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.onFileButtonClicked = this.onFileButtonClicked.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.onImagesSelected = this.onImagesSelected.bind(this);

        this.state = {
            anchorElMenu:null,
            menuType:null,
            isDialogOpen: false,
            isFileUploadDialogOpen: false,
            postFiles: [],
            badgeFiles:[],
            showFiles: false,
            postImages: [],
            badgeImages: [],
            isInnerDialog: false,
            postDescription: "",
            title:"",
            isStoryImageSelected: false,
            storyImages: []
        }
    }
    handleCloseMenu = () => {
        this.setState({anchorElMenu:null,menuType:null})
    }
    handleOpenMenu = (text:string,event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({anchorElMenu:event.currentTarget,menuType:text})
    }
    handleClickMoreList = (value?:ACTIVE_LANDING_PAGE_TAB | "SHORTCUTS" | "STORIES") => {
        if(value !== "SHORTCUTS" && value !== "STORIES" && value && this.props.handleTabChange){
            this.props.handleTabChange(value)
        }
        if(value === "STORIES"){
            this.props.navigation.navigate("UserStatus")
        }
        if(value === "SHORTCUTS" && this.props.handleTabChange){
            this.props.handleTabChange('SHORTCUTS')
        }
        this.handleCloseMenu()
    }

    openDialog(view?: IView, isInnerDialog?: boolean,title:string = this.state.title) {
        this.setState({
            view,
            isDialogOpen: true,
            isInnerDialog,
            title
        });
        this.handleCloseMenu()
    }

    closeDialog() {
        this.setState({
            isDialogOpen: false,
            isInnerDialog: false
        })
    }

    onImagesSelected(images: string[]) {
        if(this.state.title === "Add Story"){
            this.setState((prevState) => ({
                ...prevState,
                view: 'ADD_STORY',
                isStoryImageSelected: true,
                storyImages: [...prevState.storyImages, ...images]
            }));
        }
        if(this.state.title === "Create Post"){
            this.setState((prevState) => ({
                ...prevState,
                view: 'CREATE_CONTENT',
                postImages: [...prevState.postImages, ...images],
            }));
        }
        if(this.state.title === "Add Badge"){
            this.setState((prevState) => ({
                ...prevState,
                view: 'CREATE_CONTENT',
                badgeImages: [...prevState.badgeImages, ...images],
            }));
        }

    }

    handleFilesUpload(files: File[]) {
        if(this.state.title === "Create Post"){
            this.setState({
                view: 'CREATE_CONTENT',
                showFiles: true,
                postFiles:files
            });
        }
        if(this.state.title === "Add Badge"){
            this.setState({
                view: 'CREATE_CONTENT',
                showFiles: true,
                badgeFiles:files
            });
        }
    }

    onFileButtonClicked() {
        this.setState({
            isFileUploadDialogOpen: true,
            view: 'UPLOAD_FILE'
        });
    }

    handleDeleteFile(index: number) {
        this.addFileDialogRef.current?.handleDeleteFile(index, this.addFileDialogRef.current.handleUpload);
    }

    handleCloseModal() {
        this.setState((prevState) => {
            if (prevState.isInnerDialog) {
                return { ...prevState, view: 'CREATE_CONTENT' }
            }

            return { ...prevState, isDialogOpen: false, isInnerDialog: false }
        })
    }

    onRemoveImage(idx: number) {
        const storyImages = this.state.storyImages;
        storyImages.splice(idx, 1);

        this.setState({ storyImages });
    }
    render(){
        const {anchorElMenu,menuType,postDescription,postImages,badgeImages,postFiles,badgeFiles,title,isStoryImageSelected,storyImages,isDialogOpen,view} = this.state
        const {activeTab,theme,handleCreatePost} = this.props
        const isMenuTypePlus = menuType === "plus"
        return(
            <div className="bottom-menu-container">
                <MenuList
                    isMenuTypePlus={isMenuTypePlus}
                    handleCloseMenu={this.handleCloseMenu}
                    handleOpenMenu={this.handleOpenMenu}
                    handleClickMoreList={this.handleClickMoreList}
                />
                <Menu
                    id="menu"
                    anchorEl={anchorElMenu}
                    keepMounted
                    open={Boolean(anchorElMenu)}
                    onClose={this.handleCloseMenu}
                    className={!isMenuTypePlus ? "more-list" : "plus-list"}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    getContentAnchorEl={null}
                    transformOrigin={{
                        vertical: isMenuTypePlus ? 130 : -150,
                        horizontal: isMenuTypePlus ? 140 : 160,
                    }}
                >
                    {
                        !isMenuTypePlus &&
                            <>
                                {
                                    moreList.map((item)=>(
                                        <MenuItem key={item.id} style={{color:activeTab === item.tab ? theme.palette.primary.main : "#3f526d"}} onClick={()=>this.handleClickMoreList(item.tab)}>
                                            {item.icon} {item.text}
                                        </MenuItem>
                                    ))
                                }
                            </>
                    }
                    {
                        isMenuTypePlus &&
                            <div className="plus-list-container" style={{backgroundColor:this.props.theme.palette.primary.main}}>
                                <MenuItem onClick={()=>this.openDialog('ADD_STORY',false,"Add Story")}>
                                    <Add htmlColor="white" /> Story
                                </MenuItem>
                                <MenuItem onClick={()=>this.openDialog('CREATE_CONTENT',false,"Create Post")}>
                                    <img src={MenuPostIcon} alt=""/> Post
                                </MenuItem>
                                <MenuItem onClick={()=>this.openDialog('CREATE_CONTENT',false,"Add Badge")}>
                                    <img src={MenuBadgesWhiteIcon} alt=""/> Badges
                                </MenuItem>
                            </div>
                    }
                </Menu>
                <BottomMenuDialogs
                    view={view}
                    isDialogOpen={isDialogOpen}
                    postFiles={postFiles}
                    badgeFiles={badgeFiles}
                    postImages={postImages}
                    badgeImages={badgeImages}
                    postDescription={postDescription}
                    title={title}
                    isStoryImageSelected={isStoryImageSelected}
                    storyImages={storyImages}
                    onImagesSelected={this.onImagesSelected}
                    handleFilesUpload={this.handleFilesUpload}
                    handleDeleteFile={this.handleDeleteFile}
                    openDialog={this.openDialog}
                    closeDialog={this.closeDialog}
                    handleCloseModal={this.handleCloseModal}
                    onRemoveImage={this.onRemoveImage}
                    handleCreatePost={handleCreatePost}
                />
            </div>
        )
    }
}

export default withTheme(BottomMenu)
