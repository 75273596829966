import React,{Component} from "react";
import DialogWrapper from "./DialogWrapper";
import SinglePost, {IAnswer, IPost} from "./SinglePost";
import {ACTIVE_LANDING_PAGE_TAB} from "./types";

type MyProps = {
    isOpen:boolean;
    handleCloseModal:()=>void;
    studentAnswers:IAnswer[];
    handleHidePost:(index:number)=>void;
    activeTab:ACTIVE_LANDING_PAGE_TAB;
    questions:IPost[];
    isRepliesOpen:boolean;
}
type MyState = {}
class AnswersDialog extends Component<MyProps, MyState>{
    render(){
        const {handleCloseModal,studentAnswers,isOpen,handleHidePost,activeTab,questions,isRepliesOpen} = this.props
        return(
            <DialogWrapper isOpen={isOpen} handleCloseModal={handleCloseModal}>
                <div className="answers-dialog-question-container">
                    {questions.map((item: IPost) => (
                        <SinglePost
                            hidePost={this.props.handleHidePost}
                            activeTab={this.props.activeTab}
                            answerAction="dialog"
                            boxedView="QUESTION"
                            key={item.id}
                            item={item}
                        />
                    ))}
                </div>
                <div className="answers-dialog-container">
                    {
                        studentAnswers?.map((item:any)=>(
                            <SinglePost
                                createPost={isRepliesOpen}
                                hidePost={handleHidePost}
                                activeTab={activeTab}
                                answerAction="expand"
                                boxedView="QUESTION"
                                key={item.id}
                                item={item}
                            />
                        ))
                    }
                </div>
            </DialogWrapper>
        )
    }
}

export default AnswersDialog;
