import React, {Component} from 'react';
import { withTheme, Theme } from '@material-ui/core/styles';
import { MenuOutlined } from '@material-ui/icons';
import "./assets/styles/boxViewToggle.css"
import { BoxViewListIcon } from './assets/svgs'
import { ACTIVE_LANDING_PAGE_BOXED_VIEW } from "./types";

type MyProps = {
    handleBoxViewChange:(value:ACTIVE_LANDING_PAGE_BOXED_VIEW)=>void;
    boxedView:ACTIVE_LANDING_PAGE_BOXED_VIEW;
    theme: Theme
}
type MyState = {
    activeBox:number
}

class BoxViewToggle extends Component<MyProps, MyState> {
    constructor(props:any) {
        super(props);
    }
    render() {
        const { handleBoxViewChange, boxedView, theme } = this.props
        return (
            <div className="box-controller-container">
                <div
                    data-test-id="box-view-box-button"
                    onClick={()=>handleBoxViewChange('BOX')}
                    className="box-controller-item"
                    style={{ backgroundColor: boxedView === 'BOX' ? theme.palette.primary.main : 'unset' }}
                >
                    <BoxViewListIcon color={boxedView === 'BOX' ? '#fff' : theme.palette.primary.main} />
                </div>

                <div
                    data-test-id="box-view-list-button"
                    onClick={()=>handleBoxViewChange('LIST')}
                    className="box-controller-item"
                    style={{ backgroundColor: boxedView === 'LIST' ? theme.palette.primary.main : 'unset' }}
                >
                    <MenuOutlined style={{ color: boxedView === 'LIST' ? '#fff' : theme.palette.primary.main }} />
                </div>
            </div>
        )
    }
}

export default withTheme(BoxViewToggle);
