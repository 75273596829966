import React from 'react';
import { Grid, withTheme, Theme } from "@material-ui/core";
import { CoverBackground, Logo, CircleBackground } from "./assets";

class SocialMediaAccountLoginLeftScreen extends React.Component<IProps> {
    render() {
        const theme = this.props.theme;

        return (
            <Grid
                item
                xs={12}
                md={6}
                className="left-side"
                style={{
                    backgroundImage: `url(${CircleBackground})`,
                    backgroundColor: theme.palette.primary.main
                }}
            >
                <div className="left-side-wrapper">
                    <img src={Logo} width={164} height={64} alt="" />
                    <h1>Let's Learn Together</h1>
                </div>

                <img className="left-side-cover" src={CoverBackground} alt="" />
            </Grid>
        )
    }
}

interface IProps {
    theme: Theme
}

export default withTheme(SocialMediaAccountLoginLeftScreen);