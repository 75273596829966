import React from 'react';
import { Grid, withTheme, Theme, withWidth, isWidthDown, TextField, Button, InputAdornment, Typography, CircularProgress } from "@material-ui/core";
import { LockOutlined, PersonOutlineOutlined } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";

import { CoverBackground } from "./assets";
import { HeyCampusAiLogo } from '../../../components/src/assets/svgs';
import OtpInputs from '../../../components/src/OtpInputs';

class SocialMediaAccountLoginRightScreen extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.state = { otpCode: '', email: '', password: '' }
    }

    onFormSubmit(values: { email: string, password: string }) {
        this.setState(values, () => {
            this.props.submitEmailForm(values);
        })
    }

    render() {
        const { view = 'FORM' } = this.props;

        const isMobileView = isWidthDown('sm', this.props.width, true)
        const theme = this.props.theme;

        return (
            <Grid
                item
                xs={12}
                md={6}
                className="right-side"
            >
                <div
                    className="right-side-wrapper"
                    style={{
                        backgroundImage: isMobileView ? `url(${CoverBackground})` : '',
                    }}
                >
                    {isMobileView && (
                        <div className="right-side-header">
                            <HeyCampusAiLogo width={160} height={63} color={theme.palette.primary.main} />
                            <Typography variant="subtitle1" component="h5" color="primary">Let's Learn Together</Typography>
                        </div>
                    )}


                    {view === 'FORM' && (
                        <>
                            <div className="title">
                                <strong>Welcome to 21K School</strong>
                                <span>Follow the instructions to make it easier to register and you will be able to explore inside.</span>
                            </div>

                            <div className="login-form-wrapper">
                                <Formik
                                    initialValues={{ email: '', password: '' }}
                                    onSubmit={this.onFormSubmit}
                                >
                                    <Form translate="yes" className="login-form">
                                        <Field
                                            className="email-field"
                                            name="email"
                                            placeholder="Enter Email Id / Phone number"
                                            variant="filled"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PersonOutlineOutlined />
                                                    </InputAdornment>
                                                )
                                            }}
                                            as={(props: any) => (
                                                <TextField {...props} fullWidth />
                                            )}
                                        />

                                        <Field
                                            className="password-field"
                                            name="password"
                                            placeholder="Password"
                                            variant="filled"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockOutlined />
                                                    </InputAdornment>
                                                )
                                            }}
                                            as={(props: any) => (
                                                <TextField {...props} fullWidth type="password" />
                                            )}
                                        />

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            className="login-button"
                                            disabled={this.props.loading === 'LOGIN'}
                                        >
                                            {this.props.loading === 'LOGIN' ? (
                                                <div className="progress-icon">
                                                    <CircularProgress style={{ color: '#fff' }} size={20} />
                                                </div>
                                            ) : 'Log In'}
                                        </Button>
                                    </Form>
                                </Formik>
                            </div>
                        </>
                    )}

                    {view === 'OTP' && (
                        <>
                            <div className="back-action">
                                Do you have account?

                                <Button variant="text" onClick={this.props.changeViewToForm}>
                                    <b>Login</b>
                                </Button>
                            </div>

                            <div className="title">
                                <strong>Verify your account</strong>
                                <span>Enter the verification code we just sent you on your email or mobile number.</span>
                            </div>

                            <div className="login-form-wrapper">
                                <OtpInputs onChange={(otpCode) => this.setState({ otpCode })} />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    className="login-button"
                                    disabled={this.props.loading === 'OTP'}
                                    onClick={() => this.props.submitOtpForm(this.state.otpCode)}
                                >
                                    {this.props.loading === 'OTP' ? (
                                        <div className="progress-icon">
                                            <CircularProgress style={{ color: '#fff' }} size={20} />
                                        </div>
                                    ) : 'Verify'}
                                </Button>

                                <div className="resend-otp" style={{ textAlign: 'right' }}>
                                    Didn't Received OTP?
                                    <Button
                                        type="button"
                                        variant="text"
                                        color="primary"
                                        className="resend-otp-button"
                                        disabled={this.props.loading === 'RESEND_OTP'}
                                        style={{ textDecoration: 'unset' }}
                                        onClick={() => this.props.submitEmailForm({ email: this.state.email, password: this.state.password }, true)}
                                    >
                                        {this.props.loading === 'RESEND_OTP' ? (
                                            <div className="progress-icon">
                                                <CircularProgress size={20} />
                                            </div>
                                        ) : 'Resend'}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Grid>
        )
    }
}

interface IProps {
    theme: Theme,
    width: Parameters<Theme['breakpoints']['only']>[0],
    view?: 'OTP' | 'FORM',
    loading?: 'LOGIN' | 'OTP' | 'RESEND_OTP',
    submitEmailForm: (values: { email: string, password: string }, isResubmit?:boolean) => void,
    submitOtpForm: (otpCode: string) => void,
    changeViewToForm: () => void,
}

interface IState {
    otpCode: string,
    email: string,
    password: string
}

export default withWidth()(withTheme(SocialMediaAccountLoginRightScreen));