import React, { Component } from "react";
import {IAnswer, IPost } from "./SinglePost";
import {
    isWidthDown,
    Link, withWidth
} from "@material-ui/core";
import AnswersDialog from "./AnswersDialog";
import {Theme} from "@material-ui/core/styles";
import {ACTIVE_LANDING_PAGE_BOXED_VIEW, ACTIVE_LANDING_PAGE_TAB} from "./types";
import PostImages from "./PostImages";
import SinglePostContentFiles from "./SinglePostContentFiles";

type MyProps = {
    item:IPost;
    handleOpenAnswerView?:(item:IPost)=>void;
    width: Parameters<Theme['breakpoints']['only']>[0];
    handleHidePost:(index:number)=>void;
    activeTab:ACTIVE_LANDING_PAGE_TAB;
    boxedView?: ACTIVE_LANDING_PAGE_BOXED_VIEW,
    isRepliesOpen:boolean;
    color:string;
}
type MyState = {
    isAnswersDialogOpen:boolean;
    studentAnswers:IAnswer[];
}

class SinglePostContentDescription extends Component<MyProps,MyState>{
    constructor(props:any){
        super(props)
        this.state = {
            isAnswersDialogOpen:false,
            studentAnswers:[]
        }
    }
    handleOpenAnswersDialog = () => {
        this.setState(({isAnswersDialogOpen:true}))
    };

    handleCloseAnswersDialog = () => {
        this.setState(({isAnswersDialogOpen:false}))
    };
    handleOpenAnswers = (item:IPost,isMobileView:boolean) => {
        if(this.props.handleOpenAnswerView && !isMobileView){
            this.props.handleOpenAnswerView(item)
        }
        else{
            if(item?.interactions?.answers){
                this.setState({studentAnswers:item?.interactions?.answers})
            }
            this.handleOpenAnswersDialog()
        }
    }
    render(){
      const isMobileView = isWidthDown('sm', this.props.width, true)
      const {item,activeTab,handleHidePost,isRepliesOpen,boxedView,color} = this.props
      const {isAnswersDialogOpen,studentAnswers} = this.state
      return(
        <div className="post-description">
            {
                item?.question &&
                <div className="question-wrapper">
                    <div><span>Subject</span><span>{item?.question?.subject}</span></div>
                    <div><span>Title Topic</span><span>{item?.question?.title}</span></div>
                    <p>{item?.question?.question}</p>
                </div>
            }
            <p className={`${item?.goalText && "goal-text"} ${item?.video && "video-text"} box-view-description`}>
                {item?.description}
                {item?.goalText}
            </p>
            {
                item?.percentage &&
                <div className="percentage-bar-wrapper">
                    <div className="percentage-bar">
                        <div style={{backgroundColor:color,width:item?.percentage}} className="percentage-progress"></div>
                    </div>
                    <p className="percentage-text">{item?.percentage !== "100%" ? item?.percentage : "Done!"}</p>
                </div>
            }
            {item.images && item.images.length > 0 && item.answers && (
                <PostImages
                    images={item.images.map(item=>item.image)}
                />
            )}
            {
                item.question &&
                <SinglePostContentFiles
                    file={item.file}
                    answers={item.answers}
                    boxedView={boxedView}
                    question={item.question}
                />
            }
            {
                item?.answers &&
                <p
                    data-test-id="open-answer-view"
                    onClick={()=>this.handleOpenAnswers(item,isMobileView)}
                    className="answer-text"
                >
                    {item?.answers > 0 ? item?.answers + " answers" : "No answers yet"}
                </p>
            }
            <div>
                {
                    (item.video || item.link) &&
                    <Link
                        className="post-link"
                        component="button"
                        variant="body2"
                        onClick={() => window.open(`${item?.video?.link || item?.link?.text}`, '_blank')}
                    >
                        {item?.video?.link || item?.link?.text}
                    </Link>
                }
            </div>
            <AnswersDialog
                isRepliesOpen={isRepliesOpen}
                questions={[{...item}]}
                activeTab={activeTab}
                handleHidePost={handleHidePost}
                studentAnswers={studentAnswers}
                isOpen={isAnswersDialogOpen}
                handleCloseModal={this.handleCloseAnswersDialog}
            />
        </div>
      )
    }
}

export default withWidth()(SinglePostContentDescription)
