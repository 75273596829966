import React,{Component} from "react";
import {ACTIVE_LANDING_PAGE_TAB} from "./types";
import OtherStudentGoalsWidget, {IStudentGoalItem} from "./OtherStudentGoalsWidget.web";
import NotificationsWidget, {INotificationItem} from "./NotificationsWidget.web";
import EventsWidget, {IEventWidgetItem} from "./EventsWidget.web";
import ContactsWidget, {IContactWidgetItem} from "./ContactsWidget.web";
import NewsWidget, {INewsData} from "./NewsWidget";
import ChatWidget, {IChatWidgetItem} from "./ChatWidget";
import OtherMembers from "./OtherMembers";
import CalendarWidget from "./CalendarWidget";
import "./assets/styles/shortCutsTab.css"

type MyProps = {
    activeTab:ACTIVE_LANDING_PAGE_TAB;
    profileStudentGoals:IStudentGoalItem[];
    profileNotifications:INotificationItem[];
    removeNotificationItem:(notification:INotificationItem)=>void;
    removeAllNotificationItems:()=>void;
    profileEventItems:IEventWidgetItem[];
    removeEventItem:(event: IEventWidgetItem)=>void;
    profileContactUsers:IContactWidgetItem[];
    blogs:INewsData[];
    profileOtherUserPictures:string[];
    news:INewsData[];
    chatData:IChatWidgetItem[];
    navigation:any;
}
type MyState = {}
class ShortCutsTab extends Component<MyProps, MyState>{
    render(){
        const {navigation,profileOtherUserPictures,news,chatData,profileNotifications,removeNotificationItem,removeAllNotificationItems,removeEventItem,profileEventItems,profileContactUsers,blogs,profileStudentGoals} = this.props
        if(this.props.activeTab === 'SHORTCUTS') {
            return (
                <div className="shortcuts-container">

                    <div className="shortcuts-left-column">
                        <EventsWidget
                            events={profileEventItems}
                            onEventItemRemove={removeEventItem}
                        />
                        <ContactsWidget
                            contacts={profileContactUsers}
                        />
                        <NewsWidget
                            data={news}
                            title="News"
                        />
                        <NotificationsWidget
                            navigation={navigation}
                            notifications={profileNotifications}
                            onNotificationRemove={removeNotificationItem}
                            onAllNotificationsRemove={removeAllNotificationItems}
                        />
                        <CalendarWidget/>
                    </div>



                    <div className="shortcuts-right-column">
                        <OtherStudentGoalsWidget
                            studentGoals={profileStudentGoals}
                        />
                        <NewsWidget
                            data={blogs}
                            title="Blogs"
                        />
                        <OtherMembers
                            navigation={navigation}
                            otherUserPictures={profileOtherUserPictures}
                            title="Other Teachers"
                        />
                        <OtherMembers
                            navigation={navigation}
                            otherUserPictures={profileOtherUserPictures}
                            title="Other Students"
                        />
                        <ChatWidget
                            data={chatData}
                        />
                    </div>

                </div>
            )
        }
        else return(<></>)
    }
}

export default ShortCutsTab
