import React from 'react';
import {
  Typography
} from '@material-ui/core';
import GenericCard from './GenericCard.web';
import { IProfileWidgetData } from './types';
import ProfileSummaryView from './ProfileSummaryView.web';
import './assets/styles/ProfileWidget.css';

class ProfileWidget extends React.Component<IProps, IState> {
  render() {
    const { userInfo } = this.props;

    return (
      <GenericCard id="profile-widget">
        <ProfileSummaryView
          {...userInfo}
          className="profile-widget-item"
        />

        <div className="about-me profile-widget-item">
          <Typography component="h6" className="section-title">
            About Me
          </Typography>

          <Typography variant="caption" component="p" className="section-text">
            {userInfo.biography}
          </Typography>
        </div>
      </GenericCard>
    );
  }
}

interface IProps {
  userInfo: IProfileWidgetData
}

interface IState {}

export default ProfileWidget;
