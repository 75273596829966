import React from "react";

// Customizable Area Start
import moment from "moment";
import {
  CssBaseline,
  Container,
  Typography,
  Grid,
  Button,
  IconButton,
  Avatar as MuiAvatar,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import {
  ArrowBackIosOutlined,
  FavoriteOutlined,
  CloseOutlined,
  KeyboardBackspace,
  SearchOutlined
} from "@material-ui/icons";
import {Form, Formik, FormikValues} from "formik";
import {EmojiClickData} from 'emoji-picker-react';
import MainContainer from '../../../components/src/containers/MainContainer.web';
import PageHeader from '../../../components/src/PageHeader.web';
import GenericCard from "../../../components/src/GenericCard.web";
import Avatar from "../../../components/src/BorderedAvatar.web";
import BorderColoredTextfield from "../../../components/src/BorderColoredTextfield";
import EmojiPicker from "../../../components/src/EmojiPicker";
import { PlaceholderImage } from './assets';
import Slider from 'react-slick';
import { classNames } from "../../../components/src/utilities/Utils";
import "../../../components/src/assets/styles/normalize.css";
import "./main.css";
import '../../../components/src/assets/styles/SlickSlider.css';

// Customizable Area End

import UserStatusController, {
  Props,
  configJSON,
} from "./UserStatusController";

export default class UserStatus extends UserStatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    //console.log(configJSON);
    this.handleClickEmoji = this.handleClickEmoji.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  handleClickEmoji(emojiData: EmojiClickData,values:FormikValues,setFieldValue:any) {
    setFieldValue("comment",values.comment + emojiData.emoji)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <CssBaseline />

        <MainContainer
            className={classNames({
                "all-stories-page-main-container": true,
                '__user-selected': !!this.state.activeUser?.id,
                '__has-stories': this.state.usersOfStories.length > 0
            })}
            pageHeader={(
              <PageHeader
                navigation={this.props.navigation}
                pageTitle={configJSON.pageTitle}
                isGoBack={true}
              />
            )}
          >
          <Grid container id="all-stories-page" alignItems="stretch">
            {this.state.usersOfStories.length === 0 ? (
              <div className="stories-page-placeholder">
                <img src={PlaceholderImage} alt="" width={424} height={278} />
                <span>No Story Found!</span>
              </div>
            ) : (
              <>              
                <Grid item className="all-stories-sider" ref={this.storiesSiderRef}>
                  <Formik
                      data-test-id="formik"
                      initialValues={{search:""}}
                      onSubmit={(values, actions) => {
                          actions.setSubmitting(false);
                          actions.resetForm()
                      }}
                  >
                    <Form className="search-form" translate="yes">
                      <div className="border-colored-text-field-container">
                        <BorderColoredTextfield
                          className="text-field-search-input"
                          name="search"
                          placeholder="Search"
                          icon={<SearchOutlined className="search-icon input-icon" />}
                        />
                      </div>
                    </Form>
                  </Formik>

                  <GenericCard
                    title={(
                      <Button
                        variant="text"
                        onClick={this.props.navigation.goBack}
                        startIcon={<KeyboardBackspace className="go-back-icon" />}
                      >
                        {configJSON.pageTitle}
                      </Button>
                    )}
                  >
                    {this.state.usersOfStories.map((user, idx) => (
                      <div className="story-item-wrapper">
                        <Button
                          key={idx}
                          variant="text"
                          color={
                            this.state.activeUser?.id === user.id
                              ? "primary"
                              : "default"
                          }
                          className={classNames({
                            'stories-item': true,
                            '__active': this.state.activeUser?.id === user.id
                          })}
                          onClick={() => this.setActiveUser(user, idx)}
                        >
                          <Avatar
                            src={user.profilePicture}
                            width={54}
                            height={54}
                          />

                          <div className="texts">
                            <Typography component="strong">
                              {user.fullname}
                            </Typography>
                            <Typography component="small">
                              {moment
                                .duration(moment(new Date()).diff(user.createdAt))
                                .asHours()
                                .toFixed(0)}
                              h
                            </Typography>
                          </div>
                        </Button>
                      </div>
                    ))}
                  </GenericCard>

                  <div className="mobile-stories">
                    {this.state.usersOfStories.map((user, idx) => (
                      <div className="mobile-story-item-wrapper" key={idx}>
                        <Button
                          variant="text"
                          className="stories-item"
                          style={{ backgroundImage: `url(${user.stories[0].source})` }}
                          onClick={() => this.setActiveUser(user, idx)}
                        >
                          <Avatar
                            src={user.profilePicture}
                            width={46}
                            height={46}
                          />
                        </Button>
                      </div>
                    ))}
                  </div>
                </Grid>

                <Grid
                  item
                  className={classNames({
                    "all-stories-content": true,
                    '__user-selected': !!this.state.activeUser?.id,
                    '__has-one-story': this.state.activeUser?.stories?.length === 1
                  })}
                >
                  {this.state.activeUser && (
                    <GenericCard className="active-stories-content-wrapper">
                      <Grid
                        container
                        direction="column"
                        className="story-viewer-container"
                      >
                        <Grid item className="story-viewer-wrapper">
                          <GenericCard className="story-viewer">
                            <div className="story-wrapper">
                              <div className="story-head">
                                <div className="story-owner">
                                  <MuiAvatar
                                    src={this.state.activeUser.profilePicture}
                                    className="avatar"
                                  />
                                  <Typography component="h6">
                                    {this.state.activeUser.fullname}
                                  </Typography>

                                  <IconButton
                                    className="close-button"
                                    onClick={this.closeStoryViewer}
                                    hidden
                                  >
                                    <CloseOutlined />
                                  </IconButton>
                                </div>
                              </div>

                              <Slider
                                key={this.state.activeUserIndex}
                                ref={this.sliderRef}
                                accessibility={false}
                                dots={true}
                                infinite={false}
                                slidesToShow={1}
                                slidesToScroll={1}
                                nextArrow={(
                                  <IconButton className="next-button">
                                    <ArrowBackIosOutlined className="next-icon" />
                                  </IconButton>
                                )}
                                prevArrow={(
                                  <IconButton className="back-button">
                                    <ArrowBackIosOutlined />
                                  </IconButton>
                                )}
                                className="slider-wrapper"
                              >
                                {this.state.activeUser.stories.map((item, idx) => (
                                  <div key={`${item.id}_${idx}`}>
                                    <div
                                      className="story-content"
                                      style={{
                                        backgroundImage: `url(${
                                          item.source
                                        })`,
                                      }}
                                    />
                                  </div>
                                ))}
                              </Slider>
                              <IconButton
                                className="favorite-btn"
                                color="primary"
                              >
                                <FavoriteOutlined />
                              </IconButton>
                            </div>
                          </GenericCard>
                        </Grid>

                        <Grid item className="reply-wrapper">
                          <Formik
                                  data-test-id="reply-formik-wrapper"
                                  initialValues={{comment:""}}
                                  onSubmit={(values, actions) => {
                                      this.handleAddComment(values);
                                      actions.setSubmitting(false);
                                      actions.resetForm()
                                  }}
                              >
                                {({ values, setFieldValue }) => (
                                  <Form className="emoji-textarea-container" translate="yes">
                                    <div className="border-colored-text-field-container">
                                      <BorderColoredTextfield
                                        className={`textarea`}
                                        name="comment"
                                        placeholder="Reply..."
                                        icon={
                                          <>                       
                                            <IconButton
                                              className="favorite-btn"
                                              color="primary"
                                            >
                                              <FavoriteOutlined />
                                            </IconButton>

                                            <EmojiPicker
                                              handleClickEmoji={this.handleClickEmoji}
                                              values={values}
                                              setFieldValue={setFieldValue}
                                            />
                                          </>
                                        }
                                      />
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                        </Grid>
                      </Grid>
                    </GenericCard>
                  )}

                  {!this.state.activeUser && (
                    <GenericCard>Select a user</GenericCard>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </MainContainer>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
