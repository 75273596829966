import React,{ Component } from "react";
import {
    Grid,
} from "@material-ui/core";

type MyProps = {
    fullName:string,
    picture:string,
    postedTime:string,
    allowedTo:string,
    isUserInformationActive?: boolean,
    displayAllowInfo?: boolean
}
type MyState = {}

class SinglePostContentHeader extends Component<MyProps,MyState>{
    render(){
        const {fullName,picture,postedTime,allowedTo,isUserInformationActive=false, displayAllowInfo = true} = this.props
        if(isUserInformationActive){
            return(
                <Grid container item direction="row" wrap="nowrap" alignItems="center">
                    <img className="post-image" src={picture} alt=""/>
                    <Grid container direction="column">
                        <h4>{fullName}</h4>
                        <Grid container direction="row" alignItems="center">
                            <span>{postedTime}</span>
                            {displayAllowInfo && (
                                <>
                                    <div className="single-dot"></div>
                                    <span>{allowedTo}</span>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        else return <></>
    }
}

export default SinglePostContentHeader