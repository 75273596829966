import React from 'react';

const ReportsIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 28, height = 28, color } = props;

    return(
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.167 16.333A5.84 5.84 0 0 1 28 22.167 5.84 5.84 0 0 1 22.167 28a5.84 5.84 0 0 1-5.834-5.833 5.84 5.84 0 0 1 5.834-5.834zm.583 1.203v4.63a.583.583 0 0 1-.17.413l-3.272 3.273c.79.615 1.782.981 2.859.981a4.672 4.672 0 0 0 4.666-4.666 4.674 4.674 0 0 0-4.083-4.63zM16.917 0c.322 0 .583.261.583.583v4.084h1.75c.323 0 .583.26.583.583v8.167a.583.583 0 1 1-1.166 0V5.833H2.917a2.902 2.902 0 0 1-1.751-.585V22.75c0 .965.786 1.75 1.75 1.75h10.5a.583.583 0 1 1 0 1.167h-10.5A2.92 2.92 0 0 1 0 22.75V2.917A2.92 2.92 0 0 1 2.917 0h14zm4.666 17.536a4.674 4.674 0 0 0-4.083 4.63c0 1.077.367 2.07.982 2.86l3.101-3.101v-4.389zm-5.25-16.37H2.917c-.965 0-1.75.786-1.75 1.75 0 .965.785 1.75 1.75 1.75h13.416v-3.5z"
                fill={color}
                fill-rule="evenodd"
            />
        </svg>
    )
}

export default ReportsIcon;
