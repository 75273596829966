import React from 'react';

const MoreIcon:React.FC<{color:string,height?:number,width?:number}> = (props) => {
    const { width = 24, height = 24, color } = props;
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} x="0px" y="0px"
            viewBox="0 0 500 500" xmlSpace="preserve">
            <g>
                <path fill={color} d="M141.1,115.6H482c7.2,0,13-5.8,13-13c0-7.2-5.8-13-13-13H141.1c-7.2,0-13,5.8-13,13
                C128,109.8,133.9,115.6,141.1,115.6"/>
                <path fill={color} d="M18,264.5H482c7.2,0,13-5.8,13-13c0-7.2-5.8-13-13-13H18c-7.2,0-13,5.8-13,13C5,258.6,10.8,264.5,18,264.5"/>
                <path fill={color} d="M239.7,410.4H482c7.2,0,13-5.8,13-13c0-7.2-5.8-13-13-13H239.7c-7.2,0-13,5.8-13,13
                C226.6,404.6,232.5,410.4,239.7,410.4"/>
            </g>
        </svg>
    );
}

export default MoreIcon;
