import React from 'react';

const DiscussionIcon:React.FC<{color:string,height?:number,width?:number}> = (props) => {
    const { width = 24, height = 24, color } = props;
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={width}
            height={height}
            x="0px"
            y="0px"
            viewBox="0 0 500 500"
            xmlSpace="preserve"
        >
            <g>
                <path fill={color} d="M485.9,112.8C477.1,92,462.5,74.4,444,61.9c-18.4-12.5-40.8-19.8-64.7-19.8H120.7c-15.9,0-31.2,3.2-45,9.1
                C54.9,60,37.2,74.7,24.8,93.1C12.3,111.6,5,133.9,5,157.8v120.7c0,15.9,3.2,31.2,9.1,45c8.8,20.8,23.4,38.4,41.9,50.9
                c18.4,12.5,40.8,19.8,64.7,19.8h59.9l48.8,53.5c2.8,3.1,6.2,5.5,9.9,7.1c3.7,1.6,7.5,2.4,11.4,2.4c3.9,0,7.7-0.8,11.4-2.3
                c3.6-1.6,7-3.9,9.9-7l49.1-53.6h58.4c15.9,0,31.2-3.2,45-9.1c20.8-8.8,38.4-23.4,50.9-41.9c12.5-18.4,19.8-40.8,19.8-64.7V157.8
                C495,141.9,491.8,126.6,485.9,112.8z M467.7,278.5c0,12.2-2.5,23.8-6.9,34.4c-6.7,15.8-17.9,29.4-32,38.9
                c-14.1,9.5-31.1,15.1-49.4,15.1h-70.4l-57.2,62.5c-0.2,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.6-0.1
                c-0.2-0.1-0.3-0.2-0.5-0.4l-56.9-62.4h-71.9c-12.2,0-23.8-2.5-34.4-6.9c-15.8-6.7-29.4-17.9-38.9-32c-9.5-14.1-15.1-31.1-15.1-49.4
                V157.8c0-12.2,2.5-23.8,6.9-34.4c6.7-15.8,17.9-29.4,32-38.9c14.1-9.5,31.1-15.1,49.4-15.1h258.7c12.2,0,23.8,2.5,34.4,6.9
                c15.8,6.7,29.4,17.9,38.9,32c9.5,14.1,15.1,31.1,15.1,49.4V278.5z"/>
                <circle fill={color} cx="157.5" cy="219.5" r="22"/>
                <circle fill={color} cx="250.2" cy="219.5" r="22"/>
                <circle fill={color} cx="342.5" cy="219.5" r="22"/>
            </g>
        </svg>
    );
}

export default DiscussionIcon;
