import React,{ Component } from "react";
import { IVideo } from "./SinglePost";
import {CustomVideoIcon} from './assets/images';

type MyProps = {
    video?:IVideo,
    displayPlayIcon:boolean,
    handleStartVideo:()=>void
}
type MyState = {}

class SinglePostContentVideo extends Component<MyProps,MyState>{
    render(){
        const {video,displayPlayIcon,handleStartVideo} = this.props
        if(video?.image){
            return(
                <div className="video-picture-container">
                    {
                        video.embed ?
                        <iframe src={video.source}
                                className="video-picture"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                        >
                        </iframe>
                            :
                        <video id="myVideo" className="video-picture" controls controlsList="nodownload nopictureinpicture noplaybackrate foobar" >
                            <source src="https://jsoncompare.org/LearningContainer/SampleFiles/Video/MP4/Sample-MP4-Video-File-for-Testing.mp4" type="video/mp4"/>
                        </video>
                    }
                    {
                        displayPlayIcon && !video.embed &&
                        <img onClick={handleStartVideo} className="video-icon" src={CustomVideoIcon} alt=""/>
                    }
                </div>
            )
        }
        else return <></>
    }
}

export default SinglePostContentVideo