import React from 'react';

const SocialMediaIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 28, height = 28, color } = props;

    return(
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill={color} fill-rule="evenodd">
                <path d="M9.332 14.003a4.672 4.672 0 0 1-4.666-4.668 4.672 4.672 0 0 1 4.666-4.667 4.672 4.672 0 0 1 4.666 4.667 4.672 4.672 0 0 1-4.666 4.668zm0-8.168c-1.93 0-3.5 1.57-3.5 3.5 0 1.931 1.57 3.501 3.5 3.501s3.5-1.57 3.5-3.5-1.57-3.501-3.5-3.501zM21.787 28c-1.136 0-2.451-.36-3.964-1.077-1.687-.8-2.499-.773-3.037-.747-.433.014-.884.035-1.33-.41-.156-.157-.866-1.01.53-2.405.696-.697 1.652-.897 2.482-.91l-4.836-4.837a1.679 1.679 0 0 1-.497-1.202c.001-.454.179-.88.5-1.202.322-.321.748-.499 1.202-.5h.002c.453 0 .879.177 1.2.498l2.008 2.01c.081-.16.188-.309.319-.439a1.697 1.697 0 0 1 1.705-.422 1.7 1.7 0 0 1 2.712-.721 1.7 1.7 0 0 1 2.722-.447l3.15 3.15c1.977 1.978 1.756 4.844-.539 7.14l-.015.015-1.302 1.303c-.815.802-1.813 1.203-3.012 1.203zM15.1 25.002c.638 0 1.616.104 3.222.866 3.482 1.653 4.894.85 5.654.1l1.314-1.314.012-.011c1.39-1.408 2.344-3.66.536-5.47l-3.159-3.16a.535.535 0 0 0-.758.756l.002.001.787.788a.582.582 0 0 1 0 .825.583.583 0 0 1-.825 0l-1.837-1.838a.532.532 0 0 0-.753.003.533.533 0 0 0-.003.753l1.312 1.313a.583.583 0 0 1-.825.825l-1.836-1.838a.533.533 0 0 0-.911.383c0 .141.054.275.154.374l1.312 1.312a.581.581 0 0 1 0 .826.583.583 0 0 1-.824 0l-4.462-4.463a.528.528 0 0 0-.374-.156.539.539 0 0 0-.382.911l6.036 6.04a.583.583 0 0 1-.577.972c-.022-.007-2.111-.606-3.105.386-.452.452-.519.73-.506.792.05.034.06.048.436.032.106-.004.226-.008.36-.008zm6.415-9.396h.012-.012z"/>
                <path d="M9.332 18.671C4.186 18.671 0 14.483 0 9.335 0 4.188 4.186 0 9.332 0c5.146 0 9.332 4.188 9.332 9.335v1.75a2.92 2.92 0 0 1-2.916 2.918 2.92 2.92 0 0 1-2.917-2.917V5.25a.583.583 0 1 1 1.167 0v5.835c0 .965.785 1.75 1.75 1.75s1.75-.785 1.75-1.75v-1.75c0-4.504-3.664-8.169-8.166-8.169-4.503 0-8.165 3.665-8.165 8.168 0 4.504 3.662 8.17 8.165 8.17a.583.583 0 1 1 0 1.166z"/>
            </g>
        </svg>
    )
}

export default SocialMediaIcon;
