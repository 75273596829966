import React from 'react';
import Picker, { EmojiClickData } from 'emoji-picker-react';
import {
    IconButton,
    Menu
} from "@material-ui/core";
import { SentimentSatisfiedAlt } from '@material-ui/icons'
import { SentimentSatisfied } from './assets/images';
import { FormikValues } from 'formik';

class EmojiPicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            pickerVisibility: false,
            anchorElEmoji: null
        }

        this.togglePickerVisibility = this.togglePickerVisibility.bind(this);
    }

    togglePickerVisibility(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState((prevState) => ({
            ...prevState,
            pickerVisibility: !prevState.pickerVisibility,
            anchorElEmoji: prevState.pickerVisibility === true ? null : event?.currentTarget
        }));
    }

    handleCloseEmojiDialog = (emojiData: EmojiClickData,values:FormikValues,setFieldValue:any) => {
        this.setState({anchorElEmoji:null})
        this.props.handleClickEmoji(emojiData,values,setFieldValue)
    }

    render() {
        return (
            <>
                <IconButton
                    onClick={this.togglePickerVisibility}
                    disableRipple
                    data-test-id="emojiIconButton"
                    className="create-post-box-button"
                >
                    <SentimentSatisfiedAlt style={{ color: '#dcdcdc' }} />
                </IconButton>

                <Menu
                    id="emoji"
                    keepMounted
                    anchorEl={this.state.anchorElEmoji}
                    open={this.state.pickerVisibility}
                    onClose={this.togglePickerVisibility}
                    className="filter-menu"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 220,
                        horizontal: 700
                    }}
                >
                    <Picker 
                        width={250} 
                        height={400} 
                        onEmojiClick={(value) => this.handleCloseEmojiDialog(value,this.props.values,this.props.setFieldValue)}
                        data-test-id="emoji-picker" 
                    />
                </Menu>
            </>
        )
    }
}

interface IProps {
    handleClickEmoji: (value: EmojiClickData, values:FormikValues, setFieldValue: any) => void,
    values:FormikValues,
    setFieldValue:any
}

interface IState {
    pickerVisibility: boolean;
    anchorElEmoji: null | HTMLElement
}

export default EmojiPicker;