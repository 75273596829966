import React from 'react';

const HomeIcon: React.FC<{ width?: number, height?: number, color: string }> = (props) => {
    const { width = 28, height = 28, color } = props;

    return(
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.792 18.667h-8.75A2.044 2.044 0 0 0 0 20.708v5.25C0 27.084.916 28 2.042 28h8.75a2.044 2.044 0 0 0 2.041-2.042v-5.25a2.044 2.044 0 0 0-2.041-2.041zm-8.75 7.583a.292.292 0 0 1-.292-.292v-5.25c0-.16.13-.291.292-.291h8.75c.16 0 .291.13.291.291v5.25c0 .161-.13.292-.291.292h-8.75zM10.792 0h-8.75A2.044 2.044 0 0 0 0 2.042v12.25c0 1.125.916 2.041 2.042 2.041h8.75a2.044 2.044 0 0 0 2.041-2.041V2.042A2.044 2.044 0 0 0 10.792 0zm-8.75 14.583a.292.292 0 0 1-.292-.291V2.042c0-.161.13-.292.292-.292h8.75c.16 0 .291.13.291.292v12.25c0 .16-.13.291-.291.291h-8.75zM25.958 0h-8.75a2.044 2.044 0 0 0-2.041 2.042v5.25c0 1.125.916 2.041 2.041 2.041h8.75A2.044 2.044 0 0 0 28 7.292v-5.25A2.044 2.044 0 0 0 25.958 0zm-8.75 7.583a.292.292 0 0 1-.291-.291v-5.25c0-.161.13-.292.291-.292h8.75c.161 0 .292.13.292.292v5.25c0 .16-.13.291-.292.291h-8.75zm8.75 4.084h-8.75a2.044 2.044 0 0 0-2.041 2.041v12.25c0 1.126.916 2.042 2.041 2.042h8.75A2.044 2.044 0 0 0 28 25.958v-1.386h-1.75v1.386c0 .161-.13.292-.292.292h-8.75a.292.292 0 0 1-.291-.292v-12.25c0-.16.13-.291.291-.291h8.75c.161 0 .292.13.292.291v8.531H28v-8.53a2.044 2.044 0 0 0-2.042-2.042z"
                fill={color}
                fill-rule="nonzero"
            />
        </svg>
    )
}

export default HomeIcon;
