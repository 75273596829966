import React, {Component, ReactElement} from "react";
import "./assets/styles/bottomMenu.css"
import {
    MenuPlusIcon,
    MenuCloseIcon
} from "./assets/images";
import {Button, IconButton} from "@material-ui/core";
import { withTheme, Theme } from "@material-ui/core/styles";
import {ACTIVE_LANDING_PAGE_TAB} from "./types";
import {
    TimelineIcon,
    GoalsIcon,
    DiscussionIcon, MoreIcon
} from "./assets/svgs";

const menuList:IMenuList[] = [
    {
        id:1,
        tab:"TIMELINE",
        text:"Timeline",
        icon:<TimelineIcon color="currentColor" width={26} height={26} />
    },
    {
        id:2,
        tab:"GOALS",
        text:"Goals",
        icon:<GoalsIcon color="currentColor" width={26} height={26} />
    },
    {
        id:3,
        text:"Plus"
    },
    {
        id:4,
        tab:"DISCUSSION",
        text:"Discussion",
        icon:<DiscussionIcon color="currentColor" width={28} height={28} />
    },
    {
        id:5,
        text:"More",
        icon:<MoreIcon color="currentColor" width={24} height={24} />
    }
]

interface IMenuList{
    id:number,
    tab?:ACTIVE_LANDING_PAGE_TAB,
    text:string,
    icon?:ReactElement
}

type IView = 'CREATE_CONTENT' | 'IMAGE_OPTIONS' | 'UPLOAD_FILE' | 'ADD_STORY'

interface IMoreList{
    id:number,tab:ACTIVE_LANDING_PAGE_TAB | "STORIES" | "SHORTCUTS",icon:ReactElement,text:string
}

type MyProps = {
    theme:Theme;
    isMenuTypePlus:boolean;
    handleCloseMenu:()=>void;
    handleOpenMenu:(text:string,event: React.MouseEvent<HTMLButtonElement>)=>void;
    handleClickMoreList:(value?:ACTIVE_LANDING_PAGE_TAB | "SHORTCUTS" | "STORIES")=>void;
}
type MyState = {}
class MenuList extends Component<MyProps, MyState>{
    render(){
        const {handleOpenMenu,handleClickMoreList,isMenuTypePlus,theme} = this.props
        return(
            <>
                {
                    menuList.map((item)=> {
                        if(item.id !== 3){
                            return(
                                <Button key={item.id} className="bottom-menu-button" startIcon={item.icon} onClick={(e)=> item.id === 5 ? handleOpenMenu("more",e) : handleClickMoreList(item.tab)} variant="text">
                                    {item.text}
                                </Button>
                            )
                        }
                        else {
                            return(
                                <IconButton key={item.id} style={{backgroundColor:theme.palette.primary.main}} onClick={(e)=>handleOpenMenu("plus",e)} className="menu-plus-button">
                                    {
                                        isMenuTypePlus ?  <img src={MenuCloseIcon} alt=""/> : <img src={MenuPlusIcon} alt=""/>
                                    }
                                </IconButton>
                            )
                        }
                    })
                }
            </>
        )
    }
}

export default withTheme(MenuList)
